import Type from "../../entities/Yetki";

const PageConfig = {
  title: "Yetkiler",
  ekranKodu: "Yetki_Res",
  type: Type,
  listRoute: "Yetki/YetkiGrubuList",
  getByIdRoute: "Yetki/GetById",
  insertRoute: "Yetki/Insert",
  updateRoute: "Yetki/Update",
  deleteRoute: "Yetki/Delete",
};

export default PageConfig;
