import { useMemo } from "react";
import { HttpAuthorized } from "../common/common";
import { printToast } from "../common/constant-functions/printToast";
import { apiUrl } from "../common/constants";
import { loadingVisible } from "../common/constant-functions/loadingVisible";

function useHandleDelete(
  deleteRoute,
  setRowData,
  handleClose,
  listGridRef,
  deleteCheck
) {
  async function handleDelete(id) {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${deleteRoute}`,
      data: { id: id },
    })
      .then((res) => {
        let checked = false;
        if (deleteCheck) {
          checked = deleteCheck(res);
        }
        if (!checked) {
          printToast(res.data);
          if (res.data.success) {
            setRowData && setRowData({});
            listGridRef?.current?.instance?.refresh();
            handleClose && handleClose();
          }
        }
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  return useMemo(() => handleDelete, []);
}

export default useHandleDelete;
