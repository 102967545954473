const PAKET = {
  BASLANGIC_PAKET: { Value: 1, Text: "Başlangıç Paket" },
  STANDART_PAKET: { Value: 2, Text: "Standart Paket" },
  ORTA_PAKET: { Value: 3, Text: "Orta Paket" },
  ILERI_PAKET: { Value: 4, Text: "İleri Paket" },
};

const BASLANGIC_PAKET = [
  { Value: 1, Text: "Satınalma Yönetimi" },
  { Value: 2, Text: "Stok/Depo Yönetimi" },
  { Value: 3, Text: "Temel Satış Modülü" },
  {
    Value: 4,
    Text: "Maliyetlendirme-Standart-BAO",
  },
  { Value: 5, Text: "Muhasebe-Finans Yönetimi" },
  { Value: 6, Text: "E-Fatura" },
  { Value: 7, Text: "E-Arşiv" },
  { Value: 8, Text: "E-İrsaliye" },
];

const STANDART_PAKET = [
  { Value: 1, Text: "Satınalma Yönetimi" },
  { Value: 2, Text: "Stok/Depo Yönetimi" },
  { Value: 3, Text: "Temel Satış Modülü" },
  {
    Value: 4,
    Text: "Maliyetlendirme-Standart-BAO",
  },
  { Value: 5, Text: "Muhasebe-Finans Yönetimi" },
  { Value: 6, Text: "E-Fatura" },
  { Value: 7, Text: "E-Arşiv" },
  { Value: 8, Text: "E-İrsaliye" },
  { Value: 9, Text: "Fason Yönetimi" },
  { Value: 10, Text: "Temel Üretim Modülü" },
];

const ORTA_PAKET = [
  { Value: 1, Text: "Satınalma Yönetimi" },
  { Value: 2, Text: "Stok/Depo Yönetimi" },
  { Value: 3, Text: "Temel Satış Modülü" },
  {
    Value: 4,
    Text: "Maliyetlendirme-Standart-BAO",
  },
  { Value: 5, Text: "Muhasebe-Finans Yönetimi" },
  { Value: 6, Text: "E-Fatura" },
  { Value: 7, Text: "E-Arşiv" },
  { Value: 8, Text: "E-İrsaliye" },
  { Value: 9, Text: "Fason Yönetimi" },
  { Value: 10, Text: "Temel Üretim Modülü" },
  {
    Value: 11,
    Text: "CRM (Müşteri hizmetleri, servis yönetimi, iş takip, görüşme takip)",
  },
  { Value: 12, Text: "Kalite Yönetimi" },
  { Value: 13, Text: "Barkodlu İşlemler modülü" },
  { Value: 14, Text: "Mail Uyarı Sistemi" },
  {
    Value: 15,
    Text: "Zamanlama Tanım (MRP, Maliyet Hesabı Otomatik Çalışma)",
  },
  { Value: 16, Text: "Sabit Kıymet Demirbaş" },
];

const ILERI_PAKET = [
  { Value: 1, Text: "Satınalma Yönetimi" },
  { Value: 2, Text: "Stok/Depo Yönetimi" },
  { Value: 3, Text: "Temel Satış Modülü" },
  {
    Value: 4,
    Text: "Maliyetlendirme-Standart-BAO",
  },
  { Value: 5, Text: "Muhasebe-Finans Yönetimi" },
  { Value: 6, Text: "E-Fatura" },
  { Value: 7, Text: "E-Arşiv" },
  { Value: 8, Text: "E-İrsaliye" },
  { Value: 9, Text: "Fason Yönetimi" },
  { Value: 10, Text: "Temel Üretim Modülü" },
  {
    Value: 11,
    Text: "CRM (Müşteri hizmetleri, servis yönetimi, iş takip, görüşme takip)",
  },
  { Value: 12, Text: "Kalite Yönetimi" },
  { Value: 13, Text: "Barkodlu İşlemler modülü" },
  { Value: 14, Text: "Mail Uyarı Sistemi" },
  {
    Value: 15,
    Text: "Zamanlama Tanım (MRP, Maliyet Hesabı Otomatik Çalışma)",
  },
  { Value: 16, Text: "Sabit Kıymet Demirbaş" },
  { Value: 17, Text: "Sipariş Ödeme Eşleştirme" },
  {
    Value: 18,
    Text: "Maliyetlendirme-Dağıtım Anahtarlı",
  },
  { Value: 19, Text: "Bakım Onarım" },
  { Value: 20, Text: "Harmony Asistan" },
  { Value: 21, Text: "Nakit Akış Raporu" },
];

const EK_PAKET = [
  { Value: 1, Text: "Konfigürasyon Yönetimi" },
  {
    Value: 2,
    Text: "İleri Üretim Planlama Çizelgeleme (APS)",
  },
  { Value: 3, Text: "El Terminali Modülü" },
  {
    Value: 4,
    Text: "Bağlantılı Satış Takip Sistemi",
  },
  { Value: 5, Text: "T-Soft Entegrasyonu" },
  { Value: 6, Text: "Bayi/Servis Yönetimi (WEB)" },
  { Value: 7, Text: "MES Entegrasyonu (Cormind)" },
  { Value: 8, Text: "MES Entegrasyonu (EMEA)" },
  { Value: 9, Text: "River Entegrasyonu" },
  { Value: 10, Text: "Sigorta Poliçe" },
  { Value: 11, Text: "Leasing İşlemleri" },
  { Value: 12, Text: "Bütçe Planlama" },
  {
    Value: 13,
    Text: "Entegra Fatura Entegrasyonu",
  },
  {
    Value: 14,
    Text: "Net Ekstre Entegrasyonu (Banka Ekstreleri)",
  },
  {
    Value: 15,
    Text: "Net Tahsilat Entegrasyonu (POS Entegrasyonu)",
  },
  { Value: 16, Text: "Mutabakat (BA/BS)" },
  {
    Value: 17,
    Text: "Tedarikçi Teklif/Sipariş Mutabakat İletişim Portalı",
  },
  { Value: 18, Text: "Logo Entegrasyonu" },
  { Value: 19, Text: "Ziyaretçi Takip" },
  { Value: 20, Text: "Arşiv Odası Takip" },
  { Value: 21, Text: "Evrak Takip" },
  { Value: 22, Text: "Mesai Talep" },
  {
    Value: 23,
    Text: "Toplantı Salonu Rezervasyonu",
  },
  {
    Value: 24,
    Text: "Üretim Personel İzleme Raporu",
  },
  { Value: 25, Text: "Öneri Takip" },
  { Value: 26, Text: "Bilgi İşlem" },
];

export {
  PAKET,
  EK_PAKET,
  BASLANGIC_PAKET,
  STANDART_PAKET,
  ORTA_PAKET,
  ILERI_PAKET,
};
