import {
  AY,
  DATE_FORMAT,
  DESTEK_ISLEM_TURU,
  TUTAR_FORMAT,
} from "../common/constants";
import dayjs from "dayjs";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { DATE_WIDTH, ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "desc",
    width: ID_WIDTH,
  },
  {
    name: "tarih",
    label: "Destek Tarihi",
    dataType: "date",
    dataField: "tarih",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "aciklama",
    label: "Detay",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "destekSuresi",
    label: "Süre (Dakika)",
    dataType: "text",
    dataField: "destekSuresi",
    visible: ["grid"],
  },
  {
    name: "destekVerenIdStr",
    label: "Hizmet Sağlayan",
    dataType: "text",
    dataField: "destekVerenIdStr",
    visible: ["grid"],
  },
  {
    name: "destekAlanIdStr",
    label: "Hizmet Alan",
    dataType: "text",
    dataField: "destekAlanIdStr",
    visible: ["grid"],
  },
  {
    name: "islemTuru",
    label: "İşlem Türü",
    dataType: "select",
    dataField: "islemTuru",
    visible: ["grid"],
    selectItems: Object.values(DESTEK_ISLEM_TURU),
  },
  {
    name: "yolUcreti",
    label: "Yol Ücreti",
    dataType: "number",
    dataField: "yolUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "yemekUcreti",
    label: "Yemek Ücreti",
    dataType: "number",
    dataField: "yemekUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "konaklamaUcreti",
    label: "Konaklama Ücreti",
    dataType: "number",
    dataField: "konaklamaUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "km",
    label: "Km",
    dataType: "number",
    dataField: "km",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  //todo------------- FİLTRE---------------------------
  {
    name: "ay",
    label: "Ay",
    dataType: "select",
    dataField: "ay",
    visible: ["filter"],
    items: AY,
    defaultValue: dayjs().month() + 1,
  },
];

export default entity;

//destek geçmişi : genel destek
