import * as React from "react";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={4}
    />
  );
});

export default function NumberInputForm({ input, meta, label, ...rest }) {
  return (
    <TextField
      {...input}
      sx={{ width: "100%" }}
      onChange={(event) => {
        input.onChange(
          event.target.value === "" ? undefined : +event.target.value
        );
      }}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      variant="standard"
      autoComplete="off"
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      inputProps={{ readOnly: rest.readOnly }}
      {...rest}
    />
  );
}
