import axios from "axios";
import { apiUrl } from "../common/constants";

var Http = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  timeout: 40000,
  /* transformRequest: [function (data) {
           return querystring.stringify(data);
       }],*/
  withCredentials: false,
});

class AuthService {
  login(username, password) {
    return axios({
      method: "post",
      url: apiUrl + "Auth/Login",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        kullaniciAdi: username,
        sifre: password,
      }),
    }).then((response) => {
      if (response?.data?.success) {
        localStorage.setItem(
          "user",
          JSON.stringify({ token: response.data.data.token })
        );
      }

      return response.data;
    });
  }

  logout(context) {
    localStorage.removeItem("user");
    context.userParamsRef.current = null;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

const authService = new AuthService();

export default authService;
