import { Grid } from "@mui/material";
import React from "react";

export default function DialogContentWrapper({ children, sx }) {
  return (
    <Grid
      item
      sx={{
        overflow: "auto",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      id="dialog-content-wrapper"
    >
      {children}
    </Grid>
  );
}
