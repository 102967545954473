import { Button } from "@mui/material";
import React from "react";

export default function ButtonWrapper({ children, sx, ...rest }) {
  return (
    <Button
      variant="contained"
      size="small"
      color="success"
      sx={{ ...sx, mr: 0.2 }}
      {...rest}
    >
      {children}
    </Button>
  );
}
