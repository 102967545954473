import React, { useRef, useState, useContext } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./ProjeSurecTakipConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import { AppContext } from "../../common/AppContext";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";

const ProjeSurecTakipDetayRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, masterRef, detailGridRef, editGridRef }, ref) => {
    const context = React.useContext(AppContext);

    const initialState = {};

    const customFetchData = (id) => {
      let rows = editGridRef.current.instance.getVisibleRows();
      let currentRow = rows.find((x) => x.key == id);
      setRowData(currentRow.data);
      setModalLoading(false);
    };

    const customInsertOrUpdate = (values) => {
      var tempChanges = editGridRef.current.instance
        .option("editing.changes")
        .slice();
      if (recId) {
        let change = tempChanges.find((x) => x.key == recId);
        if (!change) {
          tempChanges.push({
            key: recId,
            data: {},
            type: "update",
          });
          change = tempChanges.find((x) => x.key == recId);
        }

        let rows = editGridRef.current.instance.getVisibleRows();
        let currentRow = rows.find((x) => x.key == recId);
        Object.keys(currentRow.data).map((key) => {
          if (values[key] != currentRow.data[key]) {
            change.data[key] = values[key];
          }
        });
      } else {
        tempChanges.push({
          key: crypto.randomUUID(),
          data: values,
          type: "insert",
        });
      }

      editGridRef.current.instance.option("editing.changes", tempChanges);
      handleClose();
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData,
      setModalLoading,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      detailGridRef,
      false,
      customFetchData,
      customInsertOrUpdate
    );

    const validationSchema = yup.object().shape({
      planTarihi: yup.date().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Proje Süreç Takibi"
          maxWidth="xs"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  {/* <DialogContentTop> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Teklif Detay No"
                      modalLoading={modalLoading}
                      rowData={rowData}
                    >
                      <Field
                        disabled
                        name="teklifTeklifDetayid"
                        component={TextInputForm}
                        rowData={rowData}
                      />
                    </FieldWrapper>

                    <FieldWrapper label="Ürün Adı" modalLoading={modalLoading}>
                      <Field
                        disabled
                        name="urunAdi"
                        component={TextInputForm}
                        rowData={rowData}
                      />
                    </FieldWrapper>

                    <FieldWrapper
                      label="Plan Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="planTarihi"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Gerçekleşen Tarih"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gerceklesenTarih"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Gerçekleşti"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gerceklesti"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </FieldWrapper>

                    <Field
                      label="Açıklama"
                      name="aciklama"
                      component={MultilineTextInputForm}
                      type="text"
                      rowData={rowData}
                      modalLoading={modalLoading}
                      minRows={5}
                    />
                  </Grid>
                  {/* </DialogContentTop> */}
                  <DialogActionsWrapper>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={() => {
                        handleDelete({ id: rowData.teklifTeklifDetayid });
                      }}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                </DialogContentWrapper>
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default ProjeSurecTakipDetayRecord;
