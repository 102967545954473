import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

function PotansiyelMusteriInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  ...rest
}) {
  return (
    <AutocomplateInputForm
      ref={inputRef}
      readOnly={readOnly}
      rowData={rowData}
      remoteDataAdaptor={{
        fetchRoute: "PotansiyelMusteri/ListForSelect",
        fildsForText: ["musteriAdi"],
        fieldForValue: "id",
        reqData: {},
        fieldLabel: "potFirmaidStr",
      }}
      input={input}
      {...rest}
    />
  );
}
export default PotansiyelMusteriInputForm;
