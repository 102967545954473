import React from "react";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import { PaketBilgileriConfig } from "./SirketConfig";
import SaveButton from "../../components/buttons/custom/SaveButton";
import { Grid, Paper, Skeleton } from "@mui/material";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import useSubmit from "../../hooks/use-submit";
import {
  BASLANGIC_PAKET,
  EK_PAKET,
  ILERI_PAKET,
  ORTA_PAKET,
  PAKET,
  STANDART_PAKET,
} from "../../common/paketconstants";
import IndeterminateCheckbox from "../../components/inputs/form-inputs/IndeterminateCheckbox";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";

const PaketBilgileriDialog = React.forwardRef(({ yetki }, ref) => {
  const { open, closeRecord, modalLoading, rowData, setRowData } =
    useFetchDialog(ref, PaketBilgileriConfig.getByIdRoute);

  const handleSubmit = useSubmit(
    PaketBilgileriConfig,
    setRowData
    // afterSubmitFnc
  );

  const clearOtherPackets = (fieldName, form) => {
    // Diğer paketleri kaldır
    ["baslangicPaket", "standartPaket", "ortaPaket", "ileriPaket"]
      .filter((name) => name !== fieldName)
      .forEach((name) => {
        setTimeout(() => {
          form.change(name, []);
        }, 0);
      });
  };

  const PaketWrapper = ({ children, sx }) => {
    return modalLoading ? (
      <Skeleton width={"100%"}></Skeleton>
    ) : (
      <Paper
        square
        elevation={3}
        sx={{ padding: 2, marginX: "1px", marginY: 1, ...sx }}
      >
        {children}
      </Paper>
    );
  };

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="ERP PAKET İÇERİKLERİ"
        maxWidth="lg"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper sx={{ padding: 1.5 }}>
                <Grid
                  container
                  direction="row"
                  columnSpacing={2}
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Kullanıcı Sayısı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kullaniciSayisi"
                        component={TextInputForm}
                        readOnly={true}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Ek 1" modalLoading={modalLoading}>
                      <Field
                        name="ek1"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Ek 2" modalLoading={modalLoading}>
                      <Field
                        name="ek2"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Ek 3" modalLoading={modalLoading}>
                      <Field
                        name="ek3"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Ek 4" modalLoading={modalLoading}>
                      <Field
                        name="ek4"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Ek 5" modalLoading={modalLoading}>
                      <Field
                        name="ek5"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>

                    <PaketWrapper sx={{ marginTop: 2.2 }}>
                      <Field
                        name="baslangicPaket"
                        component={IndeterminateCheckbox}
                        items={BASLANGIC_PAKET}
                        label={PAKET.BASLANGIC_PAKET.Text}
                        onChangeExtra={(newValue, name) => {
                          if (newValue) {
                            clearOtherPackets(name, form);
                          }
                        }}
                      />
                    </PaketWrapper>
                    <PaketWrapper>
                      <Field
                        name="ortaPaket"
                        component={IndeterminateCheckbox}
                        items={ORTA_PAKET}
                        label={PAKET.ORTA_PAKET.Text}
                        onChangeExtra={(newValue, name) => {
                          if (newValue) {
                            clearOtherPackets(name, form);
                          }
                        }}
                      />
                    </PaketWrapper>
                    <PaketWrapper sx={{ backgroundColor: "#F4F9F9" }}>
                      <Field
                        name="ekPaketler"
                        component={IndeterminateCheckbox}
                        items={EK_PAKET}
                        label="Ek Paketler"
                      />
                    </PaketWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Personel Sayısı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="personelSayisi"
                        component={TextInputForm}
                        readOnly={true}
                      />
                    </FieldWrapper>

                    {/* TzyguncellemeYapabilir=x.TzyguncellemeYapabilir,
                    FinansGuncellemeYapabilir=x.FinansGuncellemeYapabilir,
                    NetEkstreGuncellemeYapabilir=x.NetEkstreGuncellemeYapabilir,
                    VersiyonGuncellemeYapabilir=x.VersiyonGuncellemeYapabilir, */}
                    <FieldWrapper
                      label="Tzy Güncelleme Yapabilir"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="tzyguncellemeYapabilir"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>

                    <FieldWrapper
                      label="Finans Güncelleme Yapabilir"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="finansGuncellemeYapabilir"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Net Ekstre Güncelleme Yapabilir"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="netEkstreGuncellemeYapabilir"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Versiyon  Güncelleme Yapabilir"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="versiyonGuncellemeYapabilir"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <PaketWrapper>
                      <Field
                        name="standartPaket"
                        component={IndeterminateCheckbox}
                        items={STANDART_PAKET}
                        label={PAKET.STANDART_PAKET.Text}
                        onChangeExtra={(newValue, name) => {
                          if (newValue) {
                            clearOtherPackets(name, form);
                          }
                        }}
                      />
                    </PaketWrapper>
                    <PaketWrapper>
                      <Field
                        name="ileriPaket"
                        component={IndeterminateCheckbox}
                        items={ILERI_PAKET}
                        label={PAKET.ILERI_PAKET.Text}
                        onChangeExtra={(newValue, name) => {
                          if (newValue) {
                            clearOtherPackets(name, form);
                          }
                        }}
                      />
                    </PaketWrapper>
                  </Grid>
                </Grid>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {yetki?.kaydet && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit();
                    }}
                  />
                )}
                {
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                }
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    </>
  );
});

export default PaketBilgileriDialog;
