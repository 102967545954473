import { YETKI_GRUBU_TIPI } from "../common/constants";
import { ID_WIDTH } from "../common/entityconstants";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "id",
    label: "Kullanıcı No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "desc",
    width: ID_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  // {
  //   name: "sirket_Kod",
  //   label: "Şirket Kodu",
  //   dataType: "text",
  //   dataField: "sirket_Kod",
  //   visible: ["grid"],
  // },
  {
    name: "kullaniciAdi",
    label: "Kullanıcı Adı",
    dataType: "text",
    dataField: "kullaniciAdi",
    visible: ["grid"],
  },
  //TODO BUNU GÖSTERCEK MİYİZ?
  {
    name: "sifre",
    label: "Şifre",
    dataType: "text",
    dataField: "sifre",
    visible: ["grid"],
    visibleConditions: [
      {
        isHarmonyAdmin: true,
      },
    ],
  },
  {
    name: "eMail",
    label: "Email",
    dataType: "text",
    dataField: "eMail",
    visible: ["grid"],
  },
  {
    name: "telefonNo",
    label: "Telefon No",
    dataType: "text",
    dataField: "telefonNo",
    visible: ["grid"],
  },
  {
    name: "isim",
    label: "İsim",
    dataType: "text",
    dataField: "isim",
    visible: ["grid"],
  },
  {
    name: "soyisim",
    label: "Soyisim",
    dataType: "text",
    dataField: "soyisim",
    visible: ["grid"],
  },
  {
    name: "yetkiGrubuIdStr",
    label: "Yetki Grubu",
    dataType: "text",
    dataField: "yetkiGrubuIdStr",
    visible: ["grid"],
  },
  {
    name: "bayiidStr",
    label: "Bayi Kod",
    dataType: "text",
    dataField: "bayiidStr",
    visible: ["grid"],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },

  //todo filtre
  {
    name: "kullaniciAdi",
    label: "Kullanıcı Adı",
    dataType: "text",
    dataField: "kullaniciAdi",
    visible: ["filter"],
  },
  {
    name: "sirketId",
    label: "Şirket Kod",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
  {
    name: "bayiid",
    label: "Bayi Kod",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
