import React, { useRef } from "react";
import { PageConfig } from "./ProjeSurecTakipDetayConfig";
import EditGrid from "../../components/grid/EditGrid";
import ProjeSurecTakipDetayRecord from "./ProjeSurecTakipDetayRecord";

function ProjeSurecTakipDetay({
  ekranKodu,
  rowData,
  detailGridRef,
  editGridRef,
}) {
  const recordRef = useRef();
  const listGridRef = useRef();
  const ref = useRef();

  const openRecord = (id) => {
    ref.current.openRecord(id);
  };

  return (
    <>
      <EditGrid
        entities={PageConfig.type}
        openRecord={openRecord}
        height={"55vh"}
        editGridRef={editGridRef}
        dataSource={rowData.detay ?? []}
        ekleButtonGizli={true}
      ></EditGrid>
      <ProjeSurecTakipDetayRecord
        ref={ref}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        detailGridRef={detailGridRef}
        editGridRef={editGridRef}
      ></ProjeSurecTakipDetayRecord>
    </>
  );
}

export default ProjeSurecTakipDetay;
