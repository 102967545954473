import Type from "../../entities/FiyatListeMaster";

const PageConfig = {
  title: "FiyatListeMaster",
  ekranKodu: "FiyatListeMaster_Res",
  type: Type,
  listRoute: "FiyatListeMaster/List",
  getByIdRoute: "FiyatListeMaster/GetById",
  insertRoute: "FiyatListeMaster/Insert",
  updateRoute: "FiyatListeMaster/Update",
  deleteRoute: "FiyatListeMaster/Delete",
};

const ExcelImportConfig = {
  fileUploadRoute: "FiyatListeMaster/ExcelImport",
};

export { PageConfig, ExcelImportConfig };
