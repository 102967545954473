import { Close, Upload } from "@mui/icons-material";
import React, { useRef } from "react";
import { AppContext } from "../../common/AppContext";
import { HttpAuthorized } from "../../common/common";
import { YETKI_GRUBU_TIPI, apiUrl } from "../../common/constants";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PdfDialog from "../../components/pdf/PdfDialog";
import { PageConfig } from "./MusteriTalepConfig";
import MusteriTalepRecord from "./MusteriTalepRecord";
import TeklifOnayDialog from "./TeklifOnayDialog";
import TeklifRedDialog from "./TeklifRedDialog";
import Yorum from "./Yorum";
import sozlesmeTarihiBitmisMi from "../../common/constant-functions/sozlesmeTarihiBitmisMi";
import GridTeklifPdfButton from "../../components/buttons/custom/GridTeklifPdfButton";
import GridIsSonucuButton from "../../components/buttons/custom/GridIsSonucuButton";
import IsSonucuDialog from "../Home/IsSonucuDialog";
import AlertDialog from "../../components/dialogs/AlertDialog";
import compareWithMonths from "../../common/constant-functions/compareWithMonths";

export default function MusteriTalep({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const yorumRef = useRef();
  const pdfDialogRef = useRef();
  const teklifRedDialogRef = useRef();
  const dosyaYukleRef = useRef();
  const isSonucuDialogRef = useRef();
  const alertDialogRef = useRef();

  const context = React.useContext(AppContext);
  const CustomColumnButton = [
    GridTeklifPdfButton(pdfDialogRef, true, alertDialogRef),
    GridIsSonucuButton(isSonucuDialogRef),
  ];

  const onSelectionChanged = ({ currentSelectedRowKeys, selectedRowKeys }) => {
    if (selectedRowKeys && selectedRowKeys[0]) {
      let talepId = selectedRowKeys[0];
      HttpAuthorized.post(apiUrl + "Yorum/List", { talepId: talepId }).then(
        async (res) => {
          await yorumRef.current.setIsEdit(false);

          await yorumRef.current.setYorumData({
            talepId: talepId,
            yorumlar: res.data.data,
          });

          await listGridRef.current.instance.refresh();
        }
      );
    }
  };

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="MusteriTalep"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekleBtnDisabled={sozlesmeTarihiBitmisMi(context)}
        ekranKodu={ekranKodu}
        allowSingleSelection={true}
        onSelectionChanged={onSelectionChanged}
        height={"50vh"}
        CustomColumnButton={CustomColumnButton}
      />
      <MusteriTalepRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></MusteriTalepRecord>
      <Yorum ref={yorumRef} />
      <PdfDialog
        ref={pdfDialogRef}
        title="Teklif"
        url="Talep/TeklifPdf"
        actionButtons={
          <>
            <ButtonWrapper
              color="error"
              startIcon={<Close />}
              onClick={() => {
                teklifRedDialogRef?.current?.openRecord(
                  pdfDialogRef?.current?.id
                );
              }}
            >
              Teklifi Reddet
            </ButtonWrapper>
            <ButtonWrapper
              color="success"
              startIcon={<Upload />}
              onClick={() => {
                if (
                  context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value
                ) {
                  const isTwoMonthsBefore = compareWithMonths(
                    pdfDialogRef?.current?.data,
                    -2,
                    "<"
                  );

                  if (isTwoMonthsBefore) {
                    alertDialogRef?.current?.openRecord();
                    return;
                  }
                }

                dosyaYukleRef?.current?.openRecord(pdfDialogRef?.current?.id);
              }}
            >
              Teklifi Onayla ve İmzalı Belgeyi Yükle
            </ButtonWrapper>
          </>
        }
      ></PdfDialog>
      <TeklifRedDialog
        ref={teklifRedDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        pdfDialogRef={pdfDialogRef}
      ></TeklifRedDialog>
      <TeklifOnayDialog
        ref={dosyaYukleRef}
        listGridRef={listGridRef}
        pdfDialogRef={pdfDialogRef}
      ></TeklifOnayDialog>
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog>
      <AlertDialog
        ref={alertDialogRef}
        dialogTitle="Teklif Zaman Aşımına Uğradı"
        contentText="Teklif zaman aşımına uğradı lütfen tekrar teklif isteyiniz."
        dialogActionsVisible={false}
      ></AlertDialog>
    </>
  );
}
