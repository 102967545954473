import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import PotansiyelMusteriRecord from "../../../pages/PotansiyelMusteri/PotansiyelMusteriRecord";

function PotansiyelMusteriEklemeliInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  ...rest
}) {
  const potMusteriEklemeDialogRef = useRef();
  return (
    <>
      <AutocomplateInputForm
        ref={inputRef}
        readOnly={readOnly}
        rowData={rowData}
        insertButtonRef={potMusteriEklemeDialogRef}
        remoteDataAdaptor={{
          fetchRoute: "PotansiyelMusteri/ListForSelect",
          fildsForText: ["musteriAdi"],
          fieldForValue: "id",
          reqData: {},
          fieldLabel: "potFirmaidStr",
        }}
        input={input}
        {...rest}
      />
      <PotansiyelMusteriRecord
        ref={potMusteriEklemeDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={inputRef}
        gorusmeRef={rowData}
      ></PotansiyelMusteriRecord>
    </>
  );
}
export default PotansiyelMusteriEklemeliInputForm;
