const fillYearForSelect = () => {
  const YIL = [];
  const currentYear = new Date().getFullYear();

  for (let year = 1998; year <= currentYear; year++) {
    YIL.push({ Value: year, Text: year.toString() });
  }
  return YIL.reverse();
};

export default fillYearForSelect;
