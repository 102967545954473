import React from "react";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/system";
import useBasicDialog from "../../hooks/use-basic-dialog";
import { Alert, AlertTitle } from "@mui/material";

const TalepDurumInfoDilog = React.forwardRef(({}, ref) => {
  const { open, closeRecord } = useBasicDialog(ref);

  const CustomAlert = ({ title, description }) => {
    return (
      <Alert variant="outlined" severity="info">
        <AlertTitle>{title} </AlertTitle>
        {[description]}
      </Alert>
    );
  };

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Talep Durumları Neyi İfade Eder?"
      maxWidth="lg"
    >
      <DialogContentWrapper>
        <Stack direction="column" spacing={1}>
          <CustomAlert
            title="Yeni Talep:"
            description=" Yeni talep edilen iş talebi olduğuna ve Harmony analiz ekibi
            tarafından herhangi çalışmaya başlanmadığını ifade eder."
          />
          <CustomAlert
            title="Analiz Ediliyor: "
            description="Harmony Yazılım ekibi tarafından analiz aşamasında olduğuna kısa süre içinde iş talebine bağlı olarak yorum yapılacağına (soru sormak analizi netleştirmek amaçlı) veya analiz ile alakalı, analiz sonucu ekranına analiz sonucu yapılacak geliştirmeler girilerek talep analizi yapılacağını ifade eder."
          />

          <CustomAlert
            title="Cevap Bekleniyor:"
            description="İş talebi ile alakalı yorum yazıldığı ve Analiz ekibinin iş talebi ile alakalı karşı taraftan yani talebi giren firmadan talep ile alakalı yazılı veya dokuman olarak geri dönüş beklediğini ifade etmektedir. Geri dönüş sonrası analize devam edilecektir."
          />
          <CustomAlert
            title="Teklif Verildi: "
            description="Firma ile yapılan destek anlaşması kapsamı dışında kalan talepler ile alakalı analiz sonucuna göre yapılacak geliştirmeler sonrasında ücret teklifi verildiğini ifade eder. (Uzun yazılımsal süreci olmayan veya genel geliştirme kapsamı olarak kabul edilen işler destek kapsamında yapılmayacak, tekliflendirme yapılmayacaktır. )"
          />
          <CustomAlert
            title="Teklif Reddedildi: "
            description="Harmony Tarafında verilen teklifin firma tarafından uygun görülmemesi ve iş talebinden vazgeçilmesi sonrasında firma tarafında seçilen talep durumudur."
          />
          <CustomAlert
            title="Teklif Onaylandı: "
            description="Harmony Tarafında verilen teklifin firma tarafından uygun görülmesi ve analiz sonucundaki analize göre yazılımsal operasyona geçilmesinin onaylanması demektir. Teklif onaylama işleminde teklif çıktısının firmadaki yetkili birim tarafından imzalanarak sisteme yüklenmesi gerekmektedir."
          />
          <CustomAlert
            title="Yazılım Sürecine Alındı: "
            description="İş talebinin yazılım sürecine alındığı ve kısa süre içinde programa ekleneceği anlamına gelmektedir."
          />
          <CustomAlert
            title="Yapılması Uygun Görülmedi:"
            description="İş talebinin Harmony Analiz ekibi tarafından iş yorumuna yazılan sebeplerden dolayı herhangi geliştirme yapılmayacağını ifade eder."
          />
          <CustomAlert
            title="Güncelleme Bekliyor:"
            description="İş talebi için gerekli geliştirmenin yapıldığı ve güncelleme takvimi geldiğinde firmadaki harmony programı guncellenrek programa ekleneceğini ifade etmektedir."
          />
          <CustomAlert
            title="Programa Eklendi:"
            description="İş talebi ile alakalı güncellenme yapıldı ve programa geliştirmenin eklendiğini ifade etmektedir. Yazılımsal geliştirme dışındaki taleplerdede talebin tamamlandığını ifade etmektedir."
          />
        </Stack>
      </DialogContentWrapper>
    </ScrollDialog>
  );
});
export default TalepDurumInfoDilog;
