import { Avatar } from "@mui/material";
import React from "react";
import { AppContext } from "../../common/AppContext";

const AppBarAvatar = () => {
  const context = React.useContext(AppContext);

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        cursor: "pointer",
        border: "1px solid #25D366",
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  return (
    <Avatar
      alt="Logo"
      sx={{
        cursor: "pointer",
        backgroundColor: "white",
        border: "1px solid #25D366",
      }}
      {...(context.user.logo
        ? { src: `data:image/jpeg;base64,${context.user.logo}` }
        : {
            ...stringAvatar(context.user.sirketAdi ?? "K"),
            // cursor: "pointer",
          })}
    />
  );
};

export default AppBarAvatar;
