import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig, FileConfig } from "./TeklifMasterConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import TeklifDetay from "./TeklifDetay";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import FiyatListesiInputForm from "../../components/inputs/custom-form-inputs/FiyatListesiInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { CARI_TIPI, TEKLIF_DURUMU } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import MusteriInputForm from "../../components/inputs/custom-form-inputs/MusteriInputForm";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import PotansiyelMusteriInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriInputForm";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import PdfDialog from "../../components/pdf/PdfDialog";
import { HttpAuthorized } from "../../common/common";
import TopluIskontoDialog from "./TopluIskontoDialog";
import PotansiyelMusteriEklemeliInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriEklemeliInputForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import SatisTeklifiOnayliPdfDosyasiButton from "../../components/buttons/custom/SatisTeklifiOnayliPdfDosyasiButton";

const TeklifMasterRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, detailGridRef, gorusmeRef }, ref) => {
    const context = React.useContext(AppContext);

    const cariTipiInputRef = useRef();
    const pdfDialogRef = useRef();
    const topluIskontoDialogRef = useRef();

    var initialState = {
      // aktif: true,
      teklifTarihi: dayjs(),
      kayitTarihi: dayjs(),
      degisiklikTarihi: dayjs(),
      degisiklikYapan: context?.user?.id,
      kayitEden: context?.user?.id,
      cariTipi: 1,
      teklifDurumu: 1,
      fiyatListeid: context?.user?.fiyatListesiid,
      fiyatListeidStr: context?.user?.fiyatListesiidStr,
    };

    if (gorusmeRef && gorusmeRef.potFirmaid > 0) {
      initialState = {
        ...initialState,
        cariTipi: 2,
        potFirmaid: gorusmeRef.potFirmaid,
        gorusmeid: gorusmeRef.id,
        potFirmaidStr: gorusmeRef.potFirmaidStr,
      };
    }

    const editGridRef = useRef();

    // !Emirhan
    const handleChangeAllItems = (iskontoOrani) => {
      const dataGrid = editGridRef.current.instance;
      let rows = dataGrid.getVisibleRows();
      rows.map((row) => {
        dataGrid.cellValue(row.rowIndex, "iskontoOrani", iskontoOrani);
      });
    };

    const handleSubmitPrepareData = (values) => {
      let aa = {
        ...values,
        detay: editGridRef.current.instance.getVisibleRows().map((row) => {
          if (row.data.masterid == "undefined" || row.data.masterid == "") {
            row.data.masterid = 0;
          }
          return row.data;
        }),
        dipToplam: [],
      };

      return aa;
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      () => {
        editGridRef.current?.instance?.cancelEditData();
      },
      null,
      null,
      null,
      handleSubmitPrepareData
    );

    const validationSchema = yup.object().shape({
      //   listeKodu: yup.string().required(),
      //   listeAdi: yup.string().required(),
      //   baslangicTarihi: yup.date().required(),
      //   bitisTarihi: yup.date().required(),
    });

    const validate = validateFormValues(validationSchema);

    const cariTipiSirketli = () => {
      return cariTipiInputRef?.current?.value === CARI_TIPI.MUSTERI.Value;
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Satış Teklifi Ver"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Teklif No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Teklif Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="teklifTarihi"
                          component={DateInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={4}>
                          <FieldWrapper
                            label="Firma"
                            modalLoading={modalLoading}
                          >
                            <Field
                              items={Object.values(CARI_TIPI)}
                              rowData={rowData}
                              name="cariTipi"
                              component={AutocomplateInputForm}
                              ref={cariTipiInputRef}
                            />
                          </FieldWrapper>
                        </Grid>
                        <Grid item xs={8}>
                          {cariTipiInputRef && cariTipiSirketli() ? (
                            <Field
                              name="firmaid"
                              rowData={rowData}
                              component={SirketInputForm}
                              readOnly={!yetki.kaydet}
                            />
                          ) : cariTipiInputRef ? (
                            <Field
                              name="potFirmaid"
                              rowData={rowData}
                              component={PotansiyelMusteriEklemeliInputForm}
                              readOnly={!yetki.kaydet}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      <FieldWrapper
                        label="Liste Kodu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="fiyatListeid"
                          rowData={rowData}
                          component={FiyatListesiInputForm}
                          readOnly={
                            !yetki.kaydet || context?.user?.bayiKullanicisi
                          }
                        />
                      </FieldWrapper>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={10}>
                          <FieldWrapper
                            label="Teklif Durumu"
                            modalLoading={modalLoading}
                          >
                            <Field
                              items={Object.values(TEKLIF_DURUMU)}
                              name="teklifDurumu"
                              component={AutocomplateInputForm}
                              readOnly={true}
                            />
                          </FieldWrapper>
                        </Grid>
                        <Grid item xs={2}>
                          <SatisTeklifiOnayliPdfDosyasiButton
                            routes={FileConfig}
                            reqData={{
                              id: rowData.id,
                            }}
                            ekranKodu={ekranKodu}
                            parentRowData={rowData}
                            disabled={!rowData?.id}
                          />
                          {/* <Field
                            // items={Object.values(TEKLIF_DURUMU)}
                            name="teklifDurumux"
                            component={SatisTeklifiOnayliPdfDosyasiButton}
                          /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Geçerlilik Günü"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="gecerlilikGunu"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kullanıcı Sayısı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kullaniciSayisi"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Açıklama"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aciklama"
                          type="text"
                          minRows={3}
                          maxRows={4}
                          component={MultilineTextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      {/* <FieldWrapper label="Aktif" modalLoading={modalLoading}>
                        <Field
                          name="aktif"
                          component={CheckboxForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper> */}
                    </Grid>
                  </DialogContentTop>
                  <DialogActionsWrapper
                    sx={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <ButtonWrapper
                        color="primary"
                        onClick={(e) => {
                          topluIskontoDialogRef?.current?.openRecord();
                          // handleChangeAllItems();
                        }}
                        disabled={!rowData.id}
                      >
                        Toplu İskonto
                      </ButtonWrapper>
                      <ButtonWrapper
                        color="primary"
                        onClick={(e) => {
                          pdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                        disabled={!rowData.id}
                      >
                        Yazdır
                      </ButtonWrapper>
                    </Grid>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                  <DialogContentWrapper>
                    <TeklifDetay
                      ekranKodu={ekranKodu}
                      rowData={rowData}
                      editGridRef={editGridRef}
                      listGridRef={editGridRef}
                      detailGridRef={editGridRef}
                    ></TeklifDetay>
                  </DialogContentWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <PdfDialog
          ref={pdfDialogRef}
          title="SatisTeklifi"
          url="TeklifMaster/SatisTeklifiPdf"
        ></PdfDialog>
        <TopluIskontoDialog
          customHandleSubmit={handleChangeAllItems}
          ref={topluIskontoDialogRef}
        />
      </>
    );
  }
);
export default TeklifMasterRecord;
