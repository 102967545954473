import Type from "../../entities/PotansiyelMusteri";

const PageConfig = {
  title: "PotansiyelMusteri",
  ekranKodu: "PotansiyelMusteri_Res",
  type: Type,
  listRoute: "PotansiyelMusteri/List",
  getByIdRoute: "PotansiyelMusteri/GetById",
  insertRoute: "PotansiyelMusteri/Insert",
  updateRoute: "PotansiyelMusteri/Update",
  deleteRoute: "PotansiyelMusteri/Delete",
};

export { PageConfig };
