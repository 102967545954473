import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function KullaniciInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  sirketInputRef,
  reqData,
  ...rest
}) {
  return (
    <AutocomplateInputForm
      readOnly={readOnly}
      rowData={rowData}
      remoteDataAdaptor={{
        fetchRoute: "User/KullaniciListForSelect",
        fildsForText: ["isimSoyisim"],
        fieldForValue: "id",
        reqData: reqData ?? {
          ...(sirketInputRef?.current
            ? {
                sirketId:
                  (sirketInputRef?.current?.value > 0 &&
                    sirketInputRef?.current?.value) === false
                    ? rowData.sirketId
                    : sirketInputRef?.current?.value,
              }
            : { getSirket_KodFromSession: true }),
        },
      }}
      ref={inputRef}
      input={input}
      {...rest}
    />
  );
}
