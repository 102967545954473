import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { HttpAuthorized } from "../../common/common";
import { apiUrl } from "../../common/constants";
import YorumInputForm from "../../components/inputs/custom-form-inputs/yorum/YorumInputForm";
import { ctheme } from "../../theme/theme";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import "../../css/YorumInputForm.css";
import { toast } from "react-toastify";
import { printToast } from "../../common/constant-functions/printToast";
import { AppContext } from "../../common/AppContext";

const Yorum = React.forwardRef(({ inDialog }, ref) => {
  const context = useContext(AppContext);

  const [yorumData, setYorumData] = useState({ talepId: null, yorumlar: [] });

  const [isEdit, setIsEdit] = useState(false);

  useImperativeHandle(ref, () => ({
    setYorumData: setYorumData,
    setIsEdit: setIsEdit,
  }));

  const inputRef = useRef();
  const scrollRef = useRef(null);

  const editInputRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let yorum = e.target[0].value;

    if (yorumData.talepId && yorum) {
      HttpAuthorized.post(apiUrl + "Yorum/Insert", {
        yorum: yorum,
        talepId: yorumData.talepId,
      })
        .then((res) => {
          setYorumData({
            talepId: yorumData.talepId,
            yorumlar: [...yorumData.yorumlar, res.data.data],
          });
          inputRef.current.value = "";
        })
        .catch((error) => printToast(error.response.data));
    } else {
      if (yorum) {
        toast.warn("Yorum yapılacak talebi seçiniz.", {
          autoClose: 5000,
          theme: "colored",
        });
      } else
        toast.warn("Yorum yazınız.", {
          autoClose: 5000,
          theme: "colored",
        });
    }
  };

  const handleUpdate = (yorum) => {
    let newYorum = editInputRef.current.value;
    if (yorumData.talepId && yorum) {
      HttpAuthorized.post(apiUrl + "Yorum/Update", {
        ...yorum,
        yorum: newYorum,
      })
        .then((res) => {
          setYorumData({
            talepId: yorumData.talepId,
            yorumlar: [
              ...yorumData.yorumlar.filter((x) => x.id !== res.data.data.id),
              res.data.data,
            ],

            // yorumlar: [...res.data.data],
          });
        })
        .catch((error) => printToast(error.response.data));
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [yorumData.yorumlar]);

  const handleKeyDown = (event, yorum) => {
    if (event.key === "Enter") {
      handleUpdate(yorum);
      setIsEdit((prevState) => !prevState);
    }
  };

  return (
    <>
      {!inDialog && (
        <>
          <Paper
            sx={{
              width: "100%",
              bgcolor: ctheme.colors.lightYellowTransparent,
              pl: "10px",
            }}
          >
            <Typography variant="subtitle1" align="center">
              YORUMLAR
            </Typography>
          </Paper>
          <Divider sx={{ borderColor: "#c3dfbc" }} />
        </>
      )}

      <Grid
        item
        xs={12}
        sx={{
          overflow: "auto",
          height: inDialog ? "50vh" : `calc(100vh - 85px )`,
          bgcolor: ctheme.colors.lightYellowTransparent,
        }}
        ref={scrollRef}
      >
        <Grid
          sx={{
            pl: "5px",
          }}
        >
          {yorumData.yorumlar.map((yorum, index) => {
            return (
              <Paper
                key={index}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  m: "5px 1px",
                }}
              >
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  sx={{ p: "0px 10px" }}
                >
                  <Grid item>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "success.main",
                      }}
                    >
                      {yorum.yorumYapanKullaniciIdStr}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "success.main",
                      }}
                    >
                      {dayjs(yorum.kayitTarihi).format("HH:mm / DD.MM.YYYY ")}
                    </Typography>
                    {index === yorumData.yorumlar.length - 1 &&
                      yorum.yorumYapanKullaniciId === context?.user?.id && (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            setIsEdit((prevState) => !prevState);
                            if (isEdit) {
                              handleUpdate(yorum);
                            }
                          }}
                        >
                          {isEdit ? (
                            <DoneIcon fontSize="inherit"></DoneIcon>
                          ) : (
                            <EditIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  xs={12}
                  sx={{
                    wordWrap: "break-word",
                    p: "0px 10px",
                    ...(index === yorumData.yorumlar.length - 1 &&
                    yorum.yorumYapanKullaniciId === context?.user?.id &&
                    isEdit
                      ? { bgcolor: "#EAFFD0" }
                      : {}),
                  }}
                >
                  {index === yorumData.yorumlar.length - 1 &&
                  yorum.yorumYapanKullaniciId === context?.user?.id &&
                  isEdit ? (
                    <input
                      autoComplete="off"
                      type="text"
                      className="edit-input"
                      defaultValue={yorum.yorum}
                      ref={editInputRef}
                      autoFocus={true}
                      onKeyDown={(e) => handleKeyDown(e, yorum)}
                    />
                  ) : (
                    <Typography variant="caption" sx={{ color: "InfoText" }}>
                      {yorum.yorum}
                    </Typography>
                  )}
                </Grid>
              </Paper>
            );
          })}
        </Grid>
      </Grid>

      <Paper
        sx={{
          bottom: 0,
          width: "100%",
          height: "60px",
        }}
      >
        <YorumInputForm handleSubmit={handleSubmit} inputRef={inputRef} />
      </Paper>
    </>
  );
});

export default Yorum;
