import Type from "../../entities/Toplanti";

const PageConfig = {
  title: "Toplantılar",
  ekranKodu: "Toplanti_Res",
  type: Type,
  listRoute: "Toplanti/List",
  getByIdRoute: "Toplanti/GetById",
  insertRoute: "Toplanti/Insert",
  updateRoute: "Toplanti/Update",
  deleteRoute: "Toplanti/Delete",
};

export default PageConfig;
