import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Close } from "@mui/icons-material";
import { TALEP_DURUMU } from "../../../common/constants";

export default function IsKapatmaBtn({ isKapatmaDialogRef, rowData, yetki }) {
  return (
    <ButtonWrapper
      color="error"
      startIcon={<Close />}
      onClick={() => {
        isKapatmaDialogRef?.current?.openRecord(rowData?.id);
      }}
      disabled={!(yetki?.sil && rowData.id > 0)}
    >
      {rowData.talepDurumu !== TALEP_DURUMU.FIRMA_TARAFINDAN_KAPATILDI.Value &&
      rowData.talepDurumu !== TALEP_DURUMU.YAPILMASI_UYGUN_GORULMEDI.Value
        ? "İşi Kapat"
        : "İş Kapatma Açıklaması"}
    </ButtonWrapper>
  );
}
