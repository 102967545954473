import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Form, Field } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import PageConfig from "./NotConfig";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import dayjs from "dayjs";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";

const NotRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const initialState = {
    kayitTarihi: dayjs(),
  };

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const validationSchema = yup.object().shape({});

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle={rowData.id ? "Not Düzenleme" : "Yeni Not"}
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Kayıt Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kayitTarihi"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Şirket" modalLoading={modalLoading}>
                      <Field
                        name="sirketId"
                        rowData={rowData}
                        component={SirketInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Tamamlandı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="tamamlandi"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="İlgili Kişiler"
                      modalLoading={modalLoading}
                      width="75%"
                      textWidth="25%"
                    >
                      <Field
                        name="ilgiliKisiler"
                        rowData={rowData}
                        remoteDataAdaptor={{
                          fetchRoute: "User/KullaniciListForSelect",
                          fildsForText: ["isimSoyisim"],
                          fieldForValue: "id",
                          reqData: {
                            getSirket_KodFromSession: true,
                          },
                        }}
                        component={MultipleAutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                </DialogContentTop>
                <Field
                  name="aciklama"
                  component={RichTextEditor}
                  readOnly={!yetki.kaydet}
                  label={"Açıklama"}
                  style={{ margin: "10px 1px" }}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <Grid>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={() => {
                      handleDelete({ id: rowData.id });
                    }}
                    submitting={submitting}
                    pristine={pristine}
                    submitExtra={() => {
                      handleClose();
                    }}
                    modalLoading={modalLoading}
                  />
                </Grid>
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    </>
  );
});

export default NotRecord;
