import { Grid, Skeleton, Typography } from "@mui/material";
import useFieldWidth from "../../../hooks/use-field-with";

function FieldWrapper({
  modalLoading,
  children,
  label,
  columnDirection,
  width,
  textWidth,
  labelcolor,
  ...rest
}) {
  const { fieldCompWidth, fieldTextWidth } = useFieldWidth();

  return modalLoading ? (
    <Skeleton width={width ?? fieldCompWidth}></Skeleton>
  ) : (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      {...rest}
    >
      {label ? (
        <Typography
          variant="subtitle2"
          sx={{ width: textWidth ?? fieldTextWidth }}
          color={labelcolor}
        >
          {label}
        </Typography>
      ) : null}
      <Grid
        sx={{
          width: width ?? fieldCompWidth,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default FieldWrapper;
