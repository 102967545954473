import React from "react";
import "../../../../css/YorumInputForm.css";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";

export default function YorumInputForm({ handleSubmit, inputRef }) {
  return (
    <div className="chat_footer">
      <form onSubmit={handleSubmit} autoComplete="off">
        <input
          type="text"
          placeholder="Yorum yazınız..."
          name="yorum-input"
          ref={inputRef}
          maxLength={500}
        />
        <IconButton type="submit">
          <SendIcon fontSize="medium" color="primary" />
        </IconButton>
      </form>
    </div>
  );
}
