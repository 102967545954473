import { Button } from "devextreme-react/data-grid";
import React from "react";

const GridIsSonucuButton = (isSonucuDialogRef) => {
  return (
    <Button
      key="isSonucu"
      hint="İş Sonucu"
      icon="fieldchooser"
      visible={(e) => {
        return e.row.data.isSonucu != null;
      }}
      onClick={(e) => {
        isSonucuDialogRef?.current?.openRecord(e.row.data.id); //bu talepId
      }}
    />
  );
};

export default GridIsSonucuButton;
