import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { ctheme } from "../../../theme/theme";

const ColorList = {
  grape: {
    bgcolor: ctheme.colors.grape,
    "&:hover": {
      bgcolor: ctheme.colors.darkGrape,
    },
  },
  teal: {
    bgcolor: ctheme.colors.teal,
    "&:hover": {
      bgcolor: ctheme.colors.darkTeal,
    },
  },
  aiburn: {
    bgcolor: ctheme.colors.aiburn,
    "&:hover": {
      bgcolor: ctheme.colors.lightAiburn,
    },
  },
  pink: {
    bgcolor: ctheme.colors.lightPink,
    "&:hover": {
      bgcolor: ctheme.colors.pink,
    },
  },
  brown: {
    bgcolor: ctheme.colors.lightBrown,
    "&:hover": {
      bgcolor: ctheme.colors.brown,
    },
  },
  purple: {
    bgcolor: ctheme.colors.lightPurple,
    "&:hover": {
      bgcolor: ctheme.colors.purple,
    },
  },
  red: {
    bgcolor: ctheme.colors.lightRed,
    "&:hover": {
      bgcolor: ctheme.colors.red,
    },
  },
  green: {
    bgcolor: ctheme.colors.lightGreen,
    "&:hover": {
      bgcolor: ctheme.colors.green,
    },
  },
};

export default function CustomColoredButton({
  startIcon,
  onClick,
  color,
  disabled,
  children,
  sx,
  ...rest
}) {
  const buttonColor = ColorList[color] || {};

  return (
    <ButtonWrapper
      startIcon={startIcon}
      onClick={onClick}
      sx={{
        ...buttonColor,
        ...sx,
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </ButtonWrapper>
  );
}
