import { Comment } from "@mui/icons-material";
import React from "react";
import CustomColoredButton from "./CustomColoredButton";

export default function AnalizSonucuButton({ disabled, onClick }) {
  return (
    <CustomColoredButton
      startIcon={<Comment />}
      onClick={onClick}
      color="grape"
      disabled={disabled}
    >
      Analiz Sonucu
    </CustomColoredButton>
  );
}
