import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import DestekAsimTahsilatConfig from "./DestekAsimTahsilatConfig";
import EditablePageGridWithList from "../../components/grid/EditablePageGridWithList";
import DestekAsimTahsilatTakipPrintButton from "../../components/buttons/custom/DestekAsimTahsilatTakipPrintButton";
import PdfDialog from "../../components/pdf/PdfDialogWithData";

export default function DestekAsimTahsilatTakip({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const pdfDialogRef = useRef();

  const CustomHeaderButtons = [
    // DestekAsimTahsilatTakipPrintButton(filterRef={filterRef}, pdfDialogRef={pdfDialogRef})
    <DestekAsimTahsilatTakipPrintButton filterRef={filterRef} pdfDialogRef={pdfDialogRef}/>,
  ];

  return (
    <>
      <PageFilter
        PageConfig={DestekAsimTahsilatConfig}
        ref={filterRef}
        listGridRef={listGridRef}
        
      />
      <PdfDialog
        ref={pdfDialogRef}
        title="Destek Aşım"
        url="DestekAsimTahsilatTakip/DestekAsimPdf"
      ></PdfDialog>
      <EditablePageGridWithList
        gridId="DestekAsimTahsilatTakip"
        PageConfig={DestekAsimTahsilatConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        columnBtnVisible={false}
        hasTab={true}
        CustomHeaderButtons={CustomHeaderButtons}
      />
    </>
  );
}
