import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

function MusteriInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  cariTipiSirketli,
  ...rest
}) {
  return (
    <>
      {cariTipiSirketli ? (
        <AutocomplateInputForm
          ref={inputRef}
          readOnly={readOnly}
          rowData={rowData}
          remoteDataAdaptor={{
            fetchRoute: "Sirket/ListForSelect",
            fildsForText: ["sirket_Kod"],
            fieldForValue: "id",
            reqData: {},
            fieldLabel: "sirket_Kod",
          }}
          input={input}
          {...rest}
        />
      ) : (
        <AutocomplateInputForm
          ref={inputRef}
          readOnly={readOnly}
          rowData={rowData}
          remoteDataAdaptor={{
            fetchRoute: "PotansiyelMusteri/ListForSelect",
            fildsForText: ["sirket_Kod"],
            fieldForValue: "id",
            reqData: {},
            fieldLabel: "sirket_Kod",
          }}
          input={input}
          {...rest}
        />
      )}
    </>
  );
}
export default MusteriInputForm;
