import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import CustomDropZone from "../../components/file/CustomDropZone";
import { AppContext } from "../../common/AppContext";

export default function LogoGuncelleme() {
  const context = useContext(AppContext);

  const setLogo = (res) => {
    if (res.data.success) context.setLogo(res.data.data);
  };

  return (
    <Paper
      sx={{
        m: 1,
      }}
    >
      <Typography align="center">LOGO GÜNCELLEME</Typography>
      <Divider />
      <Grid sx={{ p: 2 }}>
        <CustomDropZone
          dropZoneText={
            <>
              <span>Logonuzu buraya tıklayarak</span>
              <span>veya sürükleyerek seçebilirsiniz.</span>
            </>
          }
          sx={{ width: "285px", height: "298px" }}
          route="Sirket/UploadLogo"
          afterUpload={setLogo}
          defaultImg={`data:image/jpeg;base64,${context.user.logo}`}
        />
      </Grid>
    </Paper>
  );
}
