import { ID_WIDTH, BASLIK_WIDTH } from "../common/entityconstants";
import KullaniciInputForm from "../components/inputs/custom-form-inputs/KullaniciInputForm";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import FiyatListesiInputForm from "../components/inputs/custom-form-inputs/FiyatListesiInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "bayiKod",
    label: "Bayi Kodu",
    dataType: "text",
    dataField: "bayiKod",
    visible: ["grid"],
  },
  {
    name: "bayiAdi",
    label: "Bayi Adı",
    dataType: "text",
    dataField: "bayiAdi",
    visible: ["grid"],
  },
  {
    name: "bayiAnaKullaniciStr",
    label: "Bayi Ana Kullanıcı",
    dataType: "custom",
    dataField: "bayiAnaKullaniciStr",
    visible: ["grid"],
    customComponent: KullaniciInputForm,
  },
  {
    name: "bayiAnaSirketStr",
    label: "Bayi Ana Şirket",
    dataType: "custom",
    dataField: "bayiAnaSirketStr",
    visible: ["grid"],
    customComponent: SirketInputForm,
  },
  {
    name: "maxIskontoOrani",
    label: "Max. İskonto Oranı",
    dataType: "number",
    dataField: "maxIskontoOrani",
    visible: ["grid"],
  },
  {
    name: "fiyatListesiidStr",
    label: "Fiyat Listesi",
    dataType: "custom",
    dataField: "fiyatListesiidStr",
    visible: ["grid"],
    customComponent: FiyatListesiInputForm,
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
    width: BASLIK_WIDTH,
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "bayiKod",
    label: "Bayi Kodu",
    dataType: "text",
    dataField: "bayiKod",
    visible: ["filter"],
  },
  {
    name: "bayiAdi",
    label: "Bayi Adı",
    dataType: "text",
    dataField: "bayiAdi",
    visible: ["filter"],
  },
  {
    name: "bayiAnaKullanici",
    label: "Bayi Ana Kullanıcı",
    dataType: "custom",
    dataField: "bayiAnaKullanici",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
  },
  {
    name: "bayiAnaSirket",
    label: "Bayi Ana Şirket",
    dataType: "custom",
    dataField: "bayiAnaSirket",
    visible: ["filter"],
    customComponent: SirketInputForm,
  },
];

export default entity;
