import { Button } from "devextreme-react/data-grid";
import { TALEP_DURUMU, TALEP_TEKLIF_DURUMU } from "../../../common/constants";
import { AppContext } from "../../../common/AppContext";

import React from "react";

export default function GridTeklifPdfButtonBayi(
  pdfDialogRef,
  goruntule = true,
  alertDialogRef
) {
  const context = React.useContext(AppContext);
  return (
    <Button
      key="teklifDosyasiBayi"
      hint="Teklif Dosyası"
      cssClass={"dx-icon-pdffileblue"}
      icon="pdffile"
      visible={(e) => {
        return (
          e.row.data.teklifVerilisTarihi != null &&
          goruntule &&
          context.user?.bayiKullanicisi
        );
      }}
      onClick={(e) => {
        pdfDialogRef?.current?.openRecord(
          e.row.data.id,
          e.row.data.teklifDurumu ===
            TALEP_TEKLIF_DURUMU.MUSTERIYE_TEKLIF_VERILDI.Value,
          e.row.data.teklifVerilisTarihi
        );
      }}
    />
  );
}

// export default GridTeklifPdfButton;
