import { TextField } from "@mui/material";
import React from "react";

export default function MultilineTextInputForm({
  input,
  meta,
  label,
  maxRows,
  minRows,
  ...rest
}) {
  return (
    <TextField
      {...input}
      sx={{ width: "100%" }}
      label={label}
      multiline
      maxRows={maxRows}
      minRows={minRows ? minRows : 2}
      variant="standard"
      onChange={(e) => input.onChange(e.target.value)}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      inputProps={{ readOnly: rest.readOnly, maxLength: rest.maxLength }}
      {...rest}
    />
  );
}
