import { Stack } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import * as yup from "yup";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import useYetkiGrubuRecord from "../../hooks/use-yetki-grubu-record";
import RecordButton from "../../components/buttons/custom/RecordButton";

const YetkiGrubuRecord = React.forwardRef(({ ekranKodu, inputRef }, ref) => {
  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    validateFormValues,
    yetki,
    handleDelete,
  } = useYetkiGrubuRecord(ref, inputRef, ekranKodu);

  const validationSchema = yup.object().shape({
    yetkiGrubuTipi: yup.number().required(),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <ScrollDialog
      open={open}
      handleClose={handleClose}
      modalTitle="Yetki Grubu Düzenleme"
      maxWidth="xs"
    >
      {open && (
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <FieldWrapper label="Yetki No" modalLoading={modalLoading}>
                    <Field disabled name="id" component={TextInputForm} />
                  </FieldWrapper>

                  <FieldWrapper
                    label="Yetki Grubu Adı"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="yetkiGrubuAdi"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    label="Yetki Grubu Tipi"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="yetkiGrubuTipi"
                      items={Object.values(YETKI_GRUBU_TIPI)}
                      rowData={rowData}
                      component={AutocomplateInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </FieldWrapper>
                </Stack>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </form>
          )}
        />
      )}
    </ScrollDialog>
  );
});
export default YetkiGrubuRecord;
