import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function FiyatListesiInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  ...rest
}) {
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "FiyatListeMaster/FiyatListForSelect",
          fildsForText: ["listeKodu"],
          fieldForValue: "id",
          reqData: {},
          fieldLabel: "fiyatListeidStr",
        }}
        ref={inputRef}
        input={input}
        {...rest}
      />
    </>
  );
}
