import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import SektorRecord from "../../../pages/Sektor/SektorRecord";

export default function SektorInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  sirketInputRef,
  reqData,
  ...rest
}) {
  const sektorDialogRef = useRef();
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        insertButtonRef={sektorDialogRef}
        remoteDataAdaptor={{
          fetchRoute: "Sektor/SektorListForSelect",
          fildsForText: ["sektor"],
          fieldForValue: "id",
          reqData: {},
        }}
        ref={inputRef}
        input={input}
        {...rest}
      />
      <SektorRecord
        ref={sektorDialogRef}
        ekranKodu={ekranKodu}
        sektorInputRef={inputRef}
      ></SektorRecord>
    </>
  );
}
