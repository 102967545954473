import { Stack } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePersonelRecord from "../../hooks/use-personel-record";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";

const PersonelRecord = React.forwardRef(
  ({ ekranKodu, personelInputRef, sirketInputRef }, ref) => {
    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      validateFormValues,
      yetki,
      handleDelete,
    } = usePersonelRecord(ref, personelInputRef, ekranKodu, sirketInputRef);

    const validationSchema = yup.object().shape({
      adSoyad: yup.string().required(),
      eMail: yup.string().email().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Talep Eden Personel Tanımla"
        maxWidth="xs"
      >
        {open && (
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    <FieldWrapper label="Ad Soyad" modalLoading={modalLoading}>
                      <Field
                        name="adSoyad"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>

                    <FieldWrapper label="Ünvan" modalLoading={modalLoading}>
                      <Field
                        name="unvan"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>

                    <FieldWrapper label="Görev" modalLoading={modalLoading}>
                      <Field
                        name="gorev"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>

                    <FieldWrapper label="Email" modalLoading={modalLoading}>
                      <Field
                        type="email"
                        name="eMail"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
              </form>
            )}
          />
        )}
      </ScrollDialog>
    );
  }
);
export default PersonelRecord;
