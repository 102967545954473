import React from "react";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import dayjs from "dayjs";
import CustomColoredButton from "./custom/CustomColoredButton";

export default function ExportButton({ pageConfig, gridRef }) {
  const onExporting = async (gridRef) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sayfa 1");

    let dateString = dayjs(Date.now()).format("DD.MM.YYYY");

    const ExcelFileName = pageConfig?.title
      ? pageConfig?.title + " " + dateString
      : dateString;
    exportDataGrid({
      component: gridRef.current.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
      customizeCell: function (options) {
        options.excelCell.font = { name: "Arial", size: 12 };
        options.excelCell.alignment = { horizontal: "left" };
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${ExcelFileName}.xlsx`
        );
      });
    });
    gridRef.current.cancel = true;
  };

  return (
    <CustomColoredButton
      sx={{
        p: "1px 7px",
        fontSize: "10pt",
        textTransform: "unset",
      }}
      color="teal"
      startIcon={<i className="dx-icon dx-icon-xlsxfile"></i>}
      onClick={() => {
        onExporting(gridRef);
      }}
    >
      Excel'e aktar
    </CustomColoredButton>
  );
}
