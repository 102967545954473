import { Grid } from "@mui/material";
import React, { useContext, useRef } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import { PageConfig } from "./GorusmelerConfig";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import useBasicDialog from "../../hooks/use-basic-dialog";

const GorusmeHatirlatmaDialog = React.forwardRef(
  ({ gorusmeRef, listGridRef }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    const afterSubmitFnc = () => {
      closeRecord();
    };

    if (gorusmeRef && gorusmeRef.id > 0) {
      var initialState = {
        gorusmeid: gorusmeRef.id,
      };
    }

    const handleSubmit = useSubmit(
      {
        insertRoute: PageConfig.hatirlatmaEkleRoute,
      },
      null,
      listGridRef
    );

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Görüşme Hatırlatma"
          maxWidth="sm"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={initialState}
            //validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12}>
                      <FieldWrapper label="Hatırlatma Tarihi">
                        <Field
                          name="hatirlatmaTarihi"
                          component={DateInputForm}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Açıklama">
                        <Field
                          name="aciklama"
                          component={MultilineTextInputForm}
                        />
                      </FieldWrapper>
                    </Grid>
                  </DialogContentTop>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <Grid>
                    <SaveButton
                      onClick={(e) => {
                        form.submit().then(() => {
                          closeRecord();
                        });
                      }}
                    />
                    <CancelButton
                      onClick={() => {
                        form.reset();
                      }}
                      disabled={submitting || pristine}
                    />
                  </Grid>
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);

export default GorusmeHatirlatmaDialog;
