// export function parseHtmlString(htmlString) {
//   const replacedHtmlString = htmlString
//     ?.replace(/&quot;/g, '"')
//     .replace(/&#39;/g, "'");
//   return replacedHtmlString?.replace(/(<([^>]+)>)/gi, " ");
// }

export function parseHtmlString(htmlString) {
  if (htmlString == null) {
    return null;
  }
  const replacedHtmlString = htmlString
    ?.replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/<p>/g, "\n")
    .replace(/<\/p>/g, "")
    .replace(/<br>/g, "\n")
    .replace(/<h[1-6][^>]*>/g, "\n");
  const finalString = replacedHtmlString?.replace(/(<([^>]+)>)/gi, "");
  return finalString.trim();
}
