import { Grid } from "@mui/material";
import React from "react";
import { ctheme } from "../../theme/theme";

export default function DialogActionsWrapper({ children, sx, ...rest }) {
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        paddingX: "2px",
        paddingY: "5px",
        bgcolor: ctheme.colors.lynxWhite,
        flexDirection: "row",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Grid>
  );
}
