import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import { ID_WIDTH, LONG_DATE_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "talepId",
    label: "Talep No",
    dataType: "number",
    dataField: "talepId",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
    firstSort: "desc",
  },
  {
    name: "sirketAdi",
    label: "Talep Eden Firma",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "temsilciIdStr",
    label: "Firma Temsilcisi",
    dataType: "text",
    dataField: "temsilciIdStr",
    visible: ["grid"],
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "tamamlanmaTarihi",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "tamamlanmaTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },

  {
    name: "kontrolEdenler",
    label: "Kontrol Edenler",
    dataType: "text",
    dataField: "kontrolEdenler",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
    allowSorting: false,
  },

  //filtre--------------------------------------------------------------------------------------------------

  {
    name: "talepId",
    label: "Talep No",
    dataType: "number",
    dataField: "talepId",
    visible: ["filter"],
  },
];

export default entity;
