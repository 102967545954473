import React from "react";
import ProgressBar from "../../forms/ProgressBar";

export default function ProgressBarInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  ...rest
}) {
  return ProgressBar(rowData.ilerlemeDurumu);
}
