import React, { useRef } from "react";
import {
  Comment,
  Save,
  Preview,
  Task,
  Redo,
  Cancel,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { Form, Field } from "react-final-form";
import {
  BAYI_TALEP_DURUMU,
  ILGILI_MODUL,
  ONAY_RED_DURUMU,
  ONCELIK_DURUMU,
  RAPOR_KATEGORI,
  TALEP_DURUMU,
  TALEP_TIPI,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import PageConfig from "./HomeConfig";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import dayjs from "dayjs";
import PersonelInputForm from "../../components/inputs/custom-form-inputs/PersonelInputForm";
import {
  getTalepDurumuArr,
  getTeklifDurumuArr,
} from "../../common/constant-functions/getTalepDurumuArr";
import AlertDialog from "../../components/dialogs/AlertDialog";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import KullaniciInputForm from "../../components/inputs/custom-form-inputs/KullaniciInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import PopoverButton from "../../components/buttons/PopoverButton";
import AnalizSonucuButton from "../../components/buttons/custom/AnalizSonucuButton";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import IsKapatmaBtn from "../../components/buttons/custom/IsKapatmaBtn";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import { talebeAitDosyaVar } from "../../common/apimessage";
import useHandleDelete from "../../hooks/use-handle-delete";
import HomeRecordDialogs from "./HomeRecordDialogs";
import { FileConfig } from "../Musteri-talep/MusteriTalepConfig";
import YorumlariGoruntuleButton from "../../components/buttons/custom/YorumlariGoruntuleButton";
import RecordButton from "../../components/buttons/custom/RecordButton";
import personelInputOnChangeExtra from "../../common/constant-functions/personelInputOnChangeExtra";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import BayiTalebiOnaylaBtn from "../../components/buttons/custom/BayiTalebiOnaylaBtn";

const HomeRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const personelInputRef = useRef();
  const teklifRecordRef = useRef();
  const bayiTeklifRecordRef = useRef();
  const alertDialogRef = useRef();
  const yorumDialogRef = useRef();
  const teklifPdfDialogRef = useRef();
  const onayliPdfDialogRef = useRef();
  const destekAsimPdfDialogRef = useRef();
  const bayidenMusteriyeDirekTeklifRecordRef = useRef();

  const sirketInputRef = useRef();
  const kullaniciInputRef = useRef();
  const onayRedKullaniciInputRef = useRef();
  const analizSonucuDialogRef = useRef();
  const talepYonlendirmeDialogRef = useRef();
  const temsilciAnaliziDialogRef = useRef();
  const isKapatmaDialogRef = useRef();
  const deleteDialogRef = useRef();
  const emailDialogRef = useRef();
  const isSonucuDialogRef = useRef();
  const analizSonucuAlertDialogRef = useRef();
  const teklifIptalAlertDialogRef = useRef();
  const bayiTalebiMerkezeGonderDialogRef = useRef();

  const deleteCheck = (res) => {
    if (res.data.message === talebeAitDosyaVar) {
      deleteDialogRef?.current?.openRecord(rowData.id);
      return true;
    } else return false;
  };

  const initialState = {
    kayitTarihi: dayjs(),
    raporKategori: RAPOR_KATEGORI.TALEP.Value,
    talepDurumu: TALEP_DURUMU.YENI_TALEP.Value,
    onayRed: ONAY_RED_DURUMU.BELIRTILMEDI.Value,
  };

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    setRowDataFnc,
    validateFormValues,
    yetki,
    context,
  } = usePageRecord(
    ref,
    PageConfig,
    listGridRef,
    ekranKodu,
    initialState,
    null,
    deleteCheck
  );

  const handleDeleteWithFiles = useHandleDelete(
    PageConfig.deleteWithFilesRoute,
    setRowDataFnc,
    handleClose,
    listGridRef
  );

  const validationSchema = yup.object().shape({
    sirketId: yup.number().required(),
    talepDurumu: yup.number().required(),
    talepBasligi: yup.string().required(),
    talepTipi: yup.number().required(),
    kayitEdenKullaniciId: yup.number().required(),
    oncelik: yup.number().required(),
    ilgiliModul: yup.number().required(),
  });

  const validate = validateFormValues(validationSchema);

  const teklifVisible = yetki?.teklifGoruntule && rowData.teklifTutari != null;

  const teklifVerButtonVisible =
    (rowData.talepDurumu === TALEP_DURUMU.YENI_TALEP.Value ||
      rowData.talepDurumu === TALEP_DURUMU.ANALIZ_EDILIYOR.Value ||
      // rowData.talepDurumu === TALEP_DURUMU.TEKLIF_VERILDI.Value ||
      rowData.talepDurumu === TALEP_DURUMU.TALEP_MERKEZE_ILETILDI.Value) &&
    // || rowData.talepDurumu === TALEP_DURUMU.BAYIYE_TEKLIF_VERILDI.Value
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value &&
    rowData.teklifTutari === null &&
    rowData.id > 0;

  const bayiTeklifVerButtonVisible =
    (rowData.talepDurumu === TALEP_DURUMU.YENI_TALEP.Value ||
      rowData.talepDurumu === TALEP_DURUMU.ANALIZ_EDILIYOR.Value) &&
    // ||  rowData.talepDurumu === TALEP_DURUMU.TEKLIF_VERILDI.Value ||
    // rowData.talepDurumu === TALEP_DURUMU.BAYIYE_TEKLIF_VERILDI.Value
    (context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI_DESTEK.Value) &&
    rowData.id > 0;

  const personelOnChangeExtra = (inputValue, newValue) => {
    personelInputOnChangeExtra(newValue.Value, emailDialogRef);
  };

  const AnalizSonucuGoruntule =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

  const onayRedSecenekleriniGoruntule =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;

  const talepyonlendirmeYapabilir = true;
  //   context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;

  const temsilciAnaliziYapabilir =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

  const isSonucuGirebilir =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

  const bayiTalebiOnaylayabilir = context.user.bayiKullanicisi == true;

  const talepDetayindaBayiTalepDetayiGozuksun =
    (context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value) &&
    rowData.bayiTalepDurumu == BAYI_TALEP_DURUMU.ONAYLANDI.Value &&
    rowData.bayiTalepDetayi !== "";
  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Talep"
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper label="Talep No" modalLoading={modalLoading}>
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Rapor Kategorisi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(RAPOR_KATEGORI)}
                        name="raporKategori"
                        component={AutocomplateInputForm}
                        readOnly={true}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Firma" modalLoading={modalLoading}>
                      <Field
                        name="sirketId"
                        rowData={rowData}
                        component={SirketInputForm}
                        readOnly={!yetki.kaydet}
                        inputRef={sirketInputRef}
                        personelInputRef={personelInputRef}
                        kullaniciInputRef={[
                          kullaniciInputRef,
                          onayRedKullaniciInputRef,
                        ]}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Talep Durumu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="talepDurumu"
                        items={getTalepDurumuArr()}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Teklif Durumu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifDurumu"
                        items={getTeklifDurumuArr()}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={true}
                        // disabled
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Talep Başlığı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="talepBasligi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Talep Eden Personel"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="talepEdenPersonelId"
                        rowData={rowData}
                        component={PersonelInputForm}
                        readOnly={
                          !yetki.kaydet || sirketInputRef?.current?.value === ""
                        }
                        inputRef={personelInputRef}
                        ekranKodu={ekranKodu}
                        sirketInputRef={sirketInputRef}
                        onChangeExtra={personelOnChangeExtra}
                      />
                    </FieldWrapper>

                    <FieldWrapper
                      label="Talep Tipi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="talepTipi"
                        items={TALEP_TIPI}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Öncelik Durumu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="oncelik"
                        items={ONCELIK_DURUMU}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    {teklifVisible && (
                      <FieldWrapper
                        label="Teklif Tutarı"
                        modalLoading={modalLoading}
                      >
                        <Grid container direction="row">
                          <Grid item xs={yetki?.sil ? 8 : 10}>
                            <Field
                              name="teklifTutari"
                              component={NumberInputForm}
                              readOnly={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Field
                              name="teklifBirimi"
                              component={TextInputForm}
                              readOnly={true}
                            />
                          </Grid>
                          {yetki?.sil && (
                            <Grid item xs={2}>
                              <Tooltip title="Teklifi İptal Et">
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  onClick={() => {
                                    teklifIptalAlertDialogRef?.current?.openRecord();
                                  }}
                                  sx={{
                                    color: (theme) => theme.palette.error.main,
                                    marginLeft: "15px",
                                  }}
                                  disabled={rowData.tahsilEdildi === true}
                                >
                                  <Cancel />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </FieldWrapper>
                    )}

                    <FieldWrapper label="Özellik 1" modalLoading={modalLoading}>
                      <Field
                        name="ozellik"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                        maxLength={15}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Kayıt Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kayitTarihi"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                        disabled={true}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Kayıt Eden Kullanıcı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kayitEdenKullaniciId"
                        rowData={rowData}
                        component={KullaniciInputForm}
                        readOnly={!yetki.kaydet}
                        sirketInputRef={sirketInputRef}
                        inputRef={kullaniciInputRef}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Teklif Veriliş Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifVerilisTarihi"
                        component={DateInputForm}
                        readOnly={true}
                        disabled={true}
                      />
                    </FieldWrapper>

                    <FieldWrapper
                      label="Teklif Onay/Red Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="onayRedTarihi"
                        component={DateInputForm}
                        readOnly={true}
                        disabled={true}
                      />
                    </FieldWrapper>
                    {onayRedSecenekleriniGoruntule && (
                      <>
                        <FieldWrapper
                          label="Onay/Red"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="onayRed"
                            items={Object.values(ONAY_RED_DURUMU)}
                            rowData={rowData}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet}
                            clearable={false}
                          />
                        </FieldWrapper>

                        <FieldWrapper
                          label="Onay/Red Kullanıcı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="onayRedKullaniciId"
                            rowData={rowData}
                            component={KullaniciInputForm}
                            readOnly={!yetki.kaydet}
                            sirketInputRef={sirketInputRef}
                            inputRef={onayRedKullaniciInputRef}
                            reqData={{
                              getSirket_KodFromSession: true,
                              sirketId2:
                                sirketInputRef?.current?.value > 0 &&
                                sirketInputRef?.current?.value,
                            }}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Onay/Red Açıklama"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="onayRedAciklama"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </FieldWrapper>
                      </>
                    )}
                    <FieldWrapper
                      label="İlgili Modül"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="ilgiliModul"
                        items={Object.values(ILGILI_MODUL)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    {/* <FieldWrapper
                      label="Bayi Talep Durumu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bayiTalepDurumu"
                        items={Object.values(BAYI_TALEP_DURUMU)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper> */}
                    {teklifVisible && (
                      <FieldWrapper
                        label="Kdv Oranı (%)"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="teklifKdvOrani"
                          component={TextInputForm}
                          readOnly={true}
                          numberInput={true}
                        />
                      </FieldWrapper>
                    )}
                  </Grid>
                </DialogContentTop>
                {talepDetayindaBayiTalepDetayiGozuksun ? (
                  <>
                    <Field
                      name="bayiTalepDetayi"
                      component={RichTextEditor}
                      minHeight="200px"
                      readOnly={!yetki.kaydet}
                      label={"Talep Detayı(Bayi)"}
                      modalLoading={modalLoading}
                    />
                    <Field
                      name="talepDetayi"
                      component={RichTextEditor}
                      minHeight="200px"
                      readOnly={!yetki.kaydet}
                      label={"Talep Detayı(Müşteri)"}
                      modalLoading={modalLoading}
                    />
                  </>
                ) : (
                  <Field
                    name="talepDetayi"
                    component={RichTextEditor}
                    readOnly={!yetki.kaydet}
                    label={"Talep Detayı"}
                    modalLoading={modalLoading}
                  />
                )}
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  {yetki?.teklifGoruntule && (
                    <CustomColoredButton
                      color="teal"
                      startIcon={<Preview />}
                      onClick={(e) => {
                        teklifPdfDialogRef?.current?.openRecord(rowData.id);
                      }}
                      disabled={rowData?.teklifVerilisTarihi == null}
                    >
                      Teklifi Görüntüle
                    </CustomColoredButton>
                  )}
                  {yetki?.teklifGoruntule &&
                    rowData.id > 0 &&
                    rowData.onayliTeklifDosyasiVarMi && (
                      <ButtonWrapper
                        color="secondary"
                        startIcon={<Task />}
                        onClick={(e) => {
                          onayliPdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                      >
                        Onaylı Teklifi Görüntüle
                      </ButtonWrapper>
                    )}
                  <YorumlariGoruntuleButton
                    onClick={(e) => {
                      yorumDialogRef?.current?.openRecord(rowData.id);
                    }}
                    disabled={!rowData.id}
                  />

                  <EkDosyaButton
                    reqData={{
                      id: rowData.id,
                    }}
                    routes={FileConfig}
                    ekranKodu={ekranKodu}
                    parentRowData={rowData}
                    disabled={!rowData?.id}
                  ></EkDosyaButton>

                  {AnalizSonucuGoruntule && (
                    <AnalizSonucuButton
                      onClick={(e) => {
                        if (!(submitting || pristine)) {
                          alertDialogRef?.current?.openRecord();
                        } else {
                          analizSonucuDialogRef?.current?.openRecord(
                            rowData?.id
                          );
                        }
                      }}
                      disabled={!rowData.id}
                    ></AnalizSonucuButton>
                  )}
                  <PopoverButton label="İşlemler">
                    <>
                      {teklifVerButtonVisible && (
                        <ButtonWrapper
                          color="warning"
                          startIcon={<Save />}
                          onClick={(e) => {
                            if (!(submitting || pristine)) {
                              alertDialogRef?.current?.openRecord();
                            } else if (rowData.analizSonucu == null) {
                              analizSonucuAlertDialogRef?.current?.openRecord();
                            } else {
                              teklifRecordRef?.current?.openRecord(
                                rowData.id,
                                null,
                                rowData
                              );
                            }
                          }}
                        >
                          {rowData.teklifVerilisTarihi == null &&
                          rowData.bayiTeklifVerilisTarihi == null
                            ? "Teklif Ver"
                            : "Teklifi Güncelle"}
                        </ButtonWrapper>
                      )}
                      {bayiTeklifVerButtonVisible && (
                        <ButtonWrapper
                          color="warning"
                          startIcon={<Save />}
                          onClick={(e) => {
                            if (!(submitting || pristine)) {
                              alertDialogRef?.current?.openRecord();
                            } else if (rowData.analizSonucu == null) {
                              bayidenMusteriyeDirekTeklifRecordRef?.current?.openRecord(
                                rowData.id,
                                null,
                                rowData
                              );
                            } else {
                              bayiTeklifRecordRef?.current?.openRecord(
                                rowData.id,
                                null,
                                rowData
                              );
                            }
                          }}
                        >
                          {rowData.analizSonucu == null &&
                          rowData.bayiAnalizSonucu == null
                            ? "MÜŞTERİYE TEKLİF VER"
                            : rowData.analizSonucu == null &&
                              rowData.bayiAnalizSonucu !== null
                            ? "MÜŞTERİYE VERİLEN TEKLİFİ GÖSTER"
                            : rowData.analizSonucu !== null &&
                              rowData.bayiAnalizSonucu !== null
                            ? "MÜŞTERİYE İLETİLEN TEKLİFİ GÖRÜNTÜLE"
                            : "TEKLİFİ MÜŞTERİYE İLET"}
                        </ButtonWrapper>
                      )}
                      {talepyonlendirmeYapabilir && (
                        <CustomColoredButton
                          startIcon={<Redo />}
                          onClick={(e) => {
                            if (!(submitting || pristine)) {
                              alertDialogRef?.current?.openRecord();
                            } else {
                              talepYonlendirmeDialogRef?.current?.setRowDataFnc(
                                (prevState) => ({
                                  ...prevState,
                                  talepId: rowData.id,
                                  talepDurumu: rowData.talepDurumu,
                                  analizSonucu: rowData.analizSonucu,
                                })
                              );

                              talepYonlendirmeDialogRef?.current?.openRecord(
                                rowData.id
                              );
                            }
                          }}
                          color="aiburn"
                          disabled={!rowData.id || !yetki.yonlendir}
                        >
                          Yönlendir
                        </CustomColoredButton>
                      )}
                      {temsilciAnaliziYapabilir && (
                        <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            if (!(submitting || pristine)) {
                              alertDialogRef?.current?.openRecord();
                            } else {
                              temsilciAnaliziDialogRef?.current?.openRecord(
                                rowData?.id
                              );
                            }
                          }}
                          disabled={!rowData.id}
                          color="grape"
                        >
                          Temsilci Analizi
                        </CustomColoredButton>
                      )}
                      {isSonucuGirebilir && (
                        <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            if (!(submitting || pristine)) {
                              alertDialogRef?.current?.openRecord();
                            } else {
                              isSonucuDialogRef?.current?.openRecord(
                                rowData?.id
                              );
                            }
                          }}
                          disabled={!rowData.id}
                          color="teal"
                        >
                          İş Sonucu
                        </CustomColoredButton>
                      )}

                      <IsKapatmaBtn
                        isKapatmaDialogRef={isKapatmaDialogRef}
                        rowData={rowData}
                        yetki={yetki}
                      ></IsKapatmaBtn>
                      {bayiTalebiOnaylayabilir && (
                        <BayiTalebiOnaylaBtn
                          bayiTalebiMerkezeGonderDialogRef={
                            bayiTalebiMerkezeGonderDialogRef
                          }
                          rowData={rowData}
                          yetki={yetki}
                        ></BayiTalebiOnaylaBtn>
                      )}
                    </>
                  </PopoverButton>
                </Grid>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>

              <AlertDialog
                ref={alertDialogRef}
                onayBtnClick={() => form.submit()}
              ></AlertDialog>
              {/* TODO */}
              <AlertDialog
                ref={deleteDialogRef}
                onayBtnClick={(id) => handleDeleteWithFiles(id)}
                dialogTitle="Talebe ait dosya var."
                contentText={talebeAitDosyaVar}
              ></AlertDialog>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <HomeRecordDialogs
        ekranKodu={ekranKodu}
        rowData={rowData}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        teklifRecordRef={teklifRecordRef}
        bayiTeklifRecordRef={bayiTeklifRecordRef}
        bayidenMusteriyeDirekTeklifRecordRef={
          bayidenMusteriyeDirekTeklifRecordRef
        }
        yorumDialogRef={yorumDialogRef}
        analizSonucuDialogRef={analizSonucuDialogRef}
        temsilciAnaliziDialogRef={temsilciAnaliziDialogRef}
        talepYonlendirmeDialogRef={talepYonlendirmeDialogRef}
        teklifPdfDialogRef={teklifPdfDialogRef}
        isKapatmaDialogRef={isKapatmaDialogRef}
        onayliPdfDialogRef={onayliPdfDialogRef}
        destekAsimPdfDialogRef={destekAsimPdfDialogRef}
        recordRef={ref}
        emailDialogRef={emailDialogRef}
        isSonucuDialogRef={isSonucuDialogRef}
        analizSonucuAlertDialogRef={analizSonucuAlertDialogRef}
        teklifIptalAlertDialogRef={teklifIptalAlertDialogRef}
        bayiTalebiMerkezeGonderDialogRef={bayiTalebiMerkezeGonderDialogRef}
      />
    </>
  );
});

export default HomeRecord;
