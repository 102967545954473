import React, { useRef, useState } from "react";

export const AppContext = React.createContext();

export function AppContextProvider({ children }) {
  const [user, setUser] = useState();
  const userParamsRef = useRef();

  return (
    <AppContext.Provider
      value={{
        user,
        setUser: (x) => setUser(x),
        setLogo: (x) => setUser({ ...user, logo: x }),
        setGuncellemeVersiyonuAdi: (x) =>
          setUser({ ...user, guncellemeVersiyonuAdi: x }),
        userParamsRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
