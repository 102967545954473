import { useContext } from "react";
import { HttpAuthorized } from "../common/common";
import { apiUrl } from "../common/constants";
// import isEqual from "lodash.isequal";
import { AppContext } from "../common/AppContext";

function useGridState(entity, listGridRef, id) {
  const context = useContext(AppContext);

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  const setUserParamsWithRef = (params) => {
    context.userParamsRef.current = params;
  };

  async function getGridStates() {
    if (context.userParamsRef?.current) {
      return context.userParamsRef?.current?.gridStates?.[id];
    } else {
      let res = await HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}User/GetUserParams`,
      });

      if (res.data.success) {
        if (res.data.data) {
          let params = JSON.parse(res.data.data);
          setUserParamsWithRef(params);
          return params?.gridStates?.[id];
        }
        return null;
      }
    }
  }

  async function setGridStates(state) {
    let updatedUserParams;
    if (context.userParamsRef?.current) {
      updatedUserParams = {
        ...context.userParamsRef?.current,
        gridStates: {
          ...context.userParamsRef?.current?.gridStates,
          [id]: state,
        },
      };
    } else {
      let res = await HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}User/GetUserParams`,
      });

      const params = res.data.data ? JSON.parse(res.data.data) : {};

      updatedUserParams = {
        ...params,
        gridStates: { ...params?.gridStates, [id]: state },
      };
    }
    setUserParamsWithRef(updatedUserParams);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}User/UpdateGridState`,
      data: {
        gridId: id,
        gridState: JSON.stringify(state),
      },
    })
      .then((res) => {})
      .catch((error) => {});
  }

  async function loadState() {
    const state = await getGridStates();
    if (!(state == null || state === undefined)) {
      let firstSortColumns = [];

      for (let i = 0; i < entity.length; i++) {
        const element = entity[i];
        if (element.firstSort)
          firstSortColumns.push({
            dataField: element.dataField,
            firstSort: element.firstSort,
          });
      }
      for (let i = 0; i < state.columns.length; i++) {
        let column = state.columns[i];
        let sortIndex;
        let fsColumn = firstSortColumns.find((v, i) => {
          sortIndex = i;
          return v.dataField === column.dataField;
        });
        if (fsColumn) {
          column.sortOrder = fsColumn.firstSort;
          column.sortIndex = sortIndex;
        } else {
          column.sortOrder = null;
          delete column.sortIndex;
        }

        if (column.name === "buttons" || column.type === "buttons") {
          column.width =
            listGridRef.current.instance.columnOption("buttons").width;
        }
      }
    }
    return state || null;
  }

  async function saveState() {
    let excludeSelection = true;
    const state = { ...listGridRef.current.instance.state() };

    const oldState = await loadState(entity, listGridRef, id);

    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        let column = state.columns[i];
        column.filterValue = null;
      }
      if (excludeSelection && Array.isArray(state?.selectedRowKeys))
        delete state.selectedRowKeys;

      state.pageIndex = 0;
    }

    // if (!isEqual(oldState, state)) {
    if (!deepEqual(oldState, state)) {
      let stateChanged = false;

      if (oldState == null) {
        stateChanged = true;
      } else {
        oldState.columns.forEach((oldCol) => {
          let currCol = state.columns.find((a) =>
            oldCol.dataField
              ? a.dataField === oldCol.dataField
              : a.name === oldCol.name
          );
          if (currCol === null || currCol === undefined) {
            stateChanged = true;
          }
        });
        state.columns.forEach((currCol) => {
          let oldCol = oldState.columns.find((a) =>
            currCol.dataField
              ? a.dataField === currCol.dataField
              : a.name === currCol.name
          );
          if (!(oldCol == null || oldCol === undefined)) {
            if (
              oldCol.visibleIndex !== currCol.visibleIndex ||
              oldCol.width !== currCol.width ||
              oldCol.visible !== currCol.visible
            ) {
              stateChanged = true;
            }
          } else {
            stateChanged = true;
          }
        });
      }
      if (stateChanged) setGridStates(state);
    }
  }

  return {
    saveState,
    loadState,
  };
}

export default useGridState;
