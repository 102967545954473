import ProgressBar from "../components/forms/ProgressBar";
import { ID_WIDTH } from "../common/entityconstants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "teklifid",
    label: "Teklif No",
    dataType: "number",
    dataField: "teklifid",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "sirket_Kod",
    label: "Şirket Kod",
    dataType: "text",
    dataField: "sirket_Kod",
    visible: ["grid"],
  },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "bayiAdi",
    label: "Bayi Adı",
    dataType: "text",
    dataField: "bayiAdi",
    visible: ["grid"],
  },
  {
    name: "ilerlemeDurumu",
    label: "Proje İlerleme Durumu",
    dataType: "custom",
    dataField: "ilerlemeDurumu",
    visible: ["grid"],
    cellRender: (e) => ProgressBar(e?.value),
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "teklifid",
    label: "Teklif No",
    dataType: "number",
    dataField: "teklifid",
    visible: ["filter"],
  },
  {
    name: "firmaid",
    label: "Şirket Kod",
    dataType: "custom",
    dataField: "firmaid",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
];

export default entity;
