import { useMemo } from "react";
import { HttpAuthorized } from "../common/common";
import { printToast } from "../common/constant-functions/printToast";
import { apiUrl } from "../common/constants";

function useFetch(fetchUrl, setRowData, setModalLoading) {
  async function fetchData(recId) {
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${fetchUrl}`,
      data: {
        id: recId,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData && setRowData(res.data.data || {});
        setModalLoading && setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }

  return useMemo(() => fetchData, []);
}

export default useFetch;
