import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import * as yup from "yup";
import usePageRecord from "../../hooks/use-page-record";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";

const PageConfig = {
  ekranKodu: "Musteri_Res",
  getByIdRoute: "MusteriTalep/IsSonucuGetById",
  updateRoute: "MusteriTalep/IsiKapat",
};

const IsKapatmaDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, setRowDataFnc, actionBtnVisible = true }, ref) => {
    const afterSubmitFnc = (res) => {
      if (res.data.data != null) {
        setRowDataFnc((prevState) => ({
          ...prevState,
          talepDurumu: res.data.data,
        }));
      }
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      yetki,
      validateFormValues,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      null,
      afterSubmitFnc
    );

    const validationSchema = yup.object().shape({
      isSonucu: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);
    return (
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="İş Kapatma"
        maxWidth="md"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper sx={{ padding: 0 }}>
                <Field
                  name="isSonucu"
                  component={RichTextEditor}
                  readOnly={!yetki.kaydet}
                  label={"Açıklama"}
                  style={{ margin: "10px" }}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              {actionBtnVisible && (
                <DialogActionsWrapper>
                  {yetki?.kaydet && (
                    <SaveButton
                      onClick={(e) => {
                        form.submit();
                        handleClose();
                      }}
                    />
                  )}
                  {yetki?.kaydet && (
                    <CancelButton
                      onClick={() => {
                        form.reset();
                      }}
                      disabled={submitting || pristine}
                    />
                  )}
                </DialogActionsWrapper>
              )}
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default IsKapatmaDialog;
