// import Type from "../../entities/Refe";

const PageConfig = {
  title: "Sektor",
  ekranKodu: "Sektor_Res",
  // type: Type,
  // listRoute: "Sektor/List",
  getByIdRoute: "Sektor/GetById",
  insertRoute: "Sektor/Insert",
  updateRoute: "Sektor/Update",
  deleteRoute: "Sektor/Delete",
};

export { PageConfig };
