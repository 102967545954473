import React, { useContext, useRef } from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useDialog from "../../hooks/use-dialog";
import * as yup from "yup";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/system";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import { Save } from "@mui/icons-material";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import useSubmit from "../../hooks/use-submit";
import { AppContext } from "../../common/AppContext";

const GuncellemeVersiyonuDialog = React.forwardRef(({}, ref) => {
  const { open, closeRecord, validateFormValues } = useDialog(ref);
  const context = useContext(AppContext);

  const afterSubmitFnc = (res) => {
    if (res.data.success)
      context.setGuncellemeVersiyonuAdi(res.data.data.guncellemeVersiyonuAdi);
  };

  const handleSubmit = useSubmit(
    { insertRoute: "Talep/GuncellemeVersiyonuUpdate" },
    null,
    null,
    afterSubmitFnc
  );

  const validationSchema = yup.object().shape({
    guncellemeVersiyonuAdi: yup.string().required(),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Güncelleme Versiyonu Adı"
        maxWidth="sm"
        canFullScreen={false}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{}}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <FieldWrapper label="Güncelleme versiyonu adı">
                    <Field
                      name="guncellemeVersiyonuAdi"
                      component={TextInputForm}
                      maxLength={30}
                    />
                  </FieldWrapper>
                </Stack>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <ButtonWrapper
                  color="success"
                  startIcon={<Save />}
                  onClick={(e) => {
                    form.submit();
                    closeRecord();
                  }}
                >
                  Kaydet
                </ButtonWrapper>
              </DialogActionsWrapper>
            </form>
          )}
        />
      </ScrollDialog>
    </>
  );
});
export default GuncellemeVersiyonuDialog;
