import React from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function GardiyanRoute({ children }) {
  let user = AuthService.getCurrentUser();
  if (user?.token) {
    return <Navigate to="/" replace />;
  }

  return children;
}
