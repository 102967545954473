import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AppContext } from "../../common/AppContext";
import { HttpAuthorized } from "../../common/common";
import { apiUrl, YETKI_GRUBU_TIPI } from "../../common/constants";
import routes from "../../common/routes";
import { routesArr } from "../../router/router";
import AuthService from "../../services/AuthService";
import PersistentDrawerLeft from "../drawer/Drawer";
import Spinner from "../loading/Spinner";

export default function Router() {
  const context = useContext(AppContext);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (window.location.pathname === "//") {
      window.location.assign(routes.home.path);
    }
    if (!context.user && AuthService.getCurrentUser()?.token) {
      HttpAuthorized.post(apiUrl + "User/KullaniciBilgileri")
        .then((res) => {
          if (res.data.success) {
            context.setUser(res.data.data);
            if (res?.data?.data?.params) {
              let params = JSON.parse(res.data.data.params);
              context.userParamsRef.current = params;
            }

            if (
              res.data.data.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value &&
              window.location.pathname === routes.home.path
            ) {
              window.location.assign(routes.musteriTalepleri.path);
            }
          } else {
            AuthService.logout(context);
            window.location.assign(routes.login.path);
          }
        })
        .catch((e) => {
          AuthService.logout(context);
          window.location.assign(routes.login.path);
        })
        .finally(() => setLoading(false));
    } else {
      if (window.location.pathname !== routes.login.path) {
        window.location.assign(routes.login.path);
      } else setLoading(false);
    }
  }, []);

  return loading ? (
    <Spinner staticLoading={true}></Spinner>
  ) : (
    <PersistentDrawerLeft>
      <Routes>
        {routesArr
          .filter(
            (x) =>
              x.yetkisizEkran ||
              context?.user?.yetkiler?.find(
                (y) => x.ekranKodu === y.ekranKodu && y.goruntule
              )
          )
          .map((i) => {
            return (
              <Route
                key={i.path}
                path={i.path}
                element={i.element}
                children={i.children
                  ?.filter(
                    (x) =>
                      x.yetkisizEkran ||
                      context?.user?.yetkiler?.find(
                        (y) => x.ekranKodu === y.ekranKodu && y.goruntule
                      )
                  )
                  .map((child) => {
                    return (
                      <Route
                        key={child.path}
                        path={child.path}
                        element={child.element}
                      ></Route>
                    );
                  })}
              />
            );
          })}
        <Route path="*" element={<div>404 not found.</div>} />
      </Routes>
    </PersistentDrawerLeft>
  );
}
