import React, { useContext, useRef } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig, FileConfig } from "./BayilerConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import KullaniciInputForm from "../../components/inputs/custom-form-inputs/KullaniciInputForm";
import CustomDropZone from "../../components/file/CustomDropZone";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import FiyatListesiInputForm from "../../components/inputs/custom-form-inputs/FiyatListesiInputForm";
import BayiAnlasmaBilgileriDialog from "./BayiAnlasmaBilgileriDialog";
import { EKRAN_KODU } from "../../common/constants";
import { AppContext } from "../../common/AppContext";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AnlasmaBilgileriDialog from "../Sirket/AnlasmaBilgileriDialog";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const BayilerRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const context = useContext(AppContext);
  const initialState = { aktif: true };

  const sirketInputRef = useRef();
  const kullaniciInputRef = useRef();

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
    setRowData,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const bayiAnlasmaBilgileriDialogRef = useRef();

  const setLogo = (res) => {
    if (res.data.success)
      setRowData((prevData) => ({ ...prevData, logo: res.data.data }));
  };

  const validationSchema = yup.object().shape({
    bayiKod: yup.string().required(),
    bayiAdi: yup.string().required(),
  });

  const validate = validateFormValues(validationSchema);

  // console.log(rowData, "rowData");

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Bayi"
        maxWidth="md"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid
                    item
                    xs={rowData.id ? 8 : 12}
                    sm={rowData.id ? 8 : 12}
                    md={rowData.id ? 8 : 12}
                  >
                    <CustomFieldWrapper
                      label="Bayi No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Bayi Kodu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bayiKod"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Bayi Adı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bayiAdi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Bayi Ana Şirket"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bayiAnaSirket"
                        rowData={rowData}
                        component={SirketInputForm}
                        readOnly={!yetki.kaydet}
                        inputRef={sirketInputRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Bayi Ana Kullanıcı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bayiAnaKullanici"
                        rowData={rowData}
                        component={KullaniciInputForm}
                        readOnly={!yetki.kaydet}
                        inputRef={kullaniciInputRef}
                        sirketInputRef={sirketInputRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Max. İskonto Oranı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="maxIskontoOrani"
                        component={NumberInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Fiyat Listesi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="fiyatListesiid"
                        rowData={rowData}
                        component={FiyatListesiInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Aktif"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aktif"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                  </Grid>
                  {rowData.id && (
                    <Grid item xs={4}>
                      {!modalLoading ? (
                        <CustomDropZone
                          sx={{
                            maxWidth: "130px",
                            maxHeight: "170px",
                          }}
                          dropZoneText={
                            <Typography variant="caption">Logo seç</Typography>
                          }
                          route="Bayiler/UploadLogo"
                          afterUpload={setLogo}
                          defaultImg={`data:image/jpeg;base64,${rowData?.logo}`}
                          reqExtraData={[{ name: "bayiid", value: rowData.id }]}
                        />
                      ) : (
                        <Skeleton
                          sx={{ width: "90px", height: "90px" }}
                        ></Skeleton>
                      )}
                    </Grid>
                  )}
                  {/* <Grid item xs={4} sm={4} md={4}>
                    <CustomFieldWrapper
                      label="Bayi No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                  </Grid> */}
                </DialogContentTop>
                <Field
                  name="aciklama"
                  component={RichTextEditor}
                  readOnly={!yetki.kaydet}
                  rowData={rowData}
                  label="Bayi Açıklaması"
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <EkDosyaButton
                    reqData={{
                      id: rowData.id,
                    }}
                    routes={FileConfig}
                    ekranKodu={ekranKodu}
                    parentRowData={rowData}
                    disabled={!rowData?.id}
                  ></EkDosyaButton>
                  {context?.user?.yetkiler.find(
                    (x) => x.ekranKodu === EKRAN_KODU.ANLASMA_BILGILERI.value
                  )?.goruntule && (
                    <CustomColoredButton
                      startIcon={<WorkOutlineIcon />}
                      onClick={(e) => {
                        bayiAnlasmaBilgileriDialogRef?.current?.openRecord(
                          rowData.id
                        );
                      }}
                      color="purple"
                      disabled={!rowData?.id}
                    >
                      Anlaşma Bilgileri
                    </CustomColoredButton>
                  )}
                </Grid>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <BayiAnlasmaBilgileriDialog
        ref={bayiAnlasmaBilgileriDialogRef}
        listGridRef={listGridRef}
      ></BayiAnlasmaBilgileriDialog>
    </>
  );
});
export default BayilerRecord;
