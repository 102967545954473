import React from "react";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

const DateTimeInputForm = React.forwardRef(
  ({ input, meta, label, defaultValue = "", ...rest }, ref) => {
    const changeValue = (newValue) => {
      if (newValue) {
        input.onChange(newValue);
      } else {
        input.onChange(null);
      }
    };

    React.useImperativeHandle(ref, () => ({
      changeValue: changeValue,
      value: input.value,
    }));

    return (
      <TextField
        {...input}
        sx={{
          width: "100%",
          input: {
            ...(!input?.value ? { color: "grey" } : {}),
          },
        }}
        label={label}
        onChange={(e) => input.onChange(e.target.value)}
        variant="standard"
        type="datetime-local"
        // value={
        //   input?.value
        //     ? dayjs(input?.value)?.format("YYYY-MM-DDTHH:mm")
        //     : defaultValue
        // }
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ readOnly: rest.readOnly }}
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        {...rest}
      />
    );
  }
);
export default DateTimeInputForm;
