import Type from "../../entities/Gorusmeler";

const PageConfig = {
  title: "Gorusmeler",
  ekranKodu: "Gorusmeler_Res",
  type: Type,
  listRoute: "Gorusmeler/List",
  getByIdRoute: "Gorusmeler/GetById",
  insertRoute: "Gorusmeler/Insert",
  updateRoute: "Gorusmeler/Update",
  deleteRoute: "Gorusmeler/Delete",
  hatirlatmaEkleRoute: "Gorusmeler/HatirlatmaEkle",
};

export { PageConfig };
