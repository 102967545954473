import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import { AppContext } from "../../../common/AppContext";

function SirketInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  personelInputRef,
  kullaniciInputRef,
  ...rest
}) {
  const context = React.useContext(AppContext);
  return (
    <AutocomplateInputForm
      ref={inputRef}
      readOnly={readOnly}
      rowData={rowData}
      remoteDataAdaptor={{
        fetchRoute: "Sirket/ListForSelect",
        fildsForText: ["sirket_Kod"],
        fieldForValue: "id",
        // reqData: context.user?.bayiKullanicisi
        //   ? context.user?.bayiKullanicisiBayiid
        //   : 0,
        fieldLabel:
          input?.name === "bayiAnaSirket"
            ? "bayiAnaSirketStr"
            : input?.name === "firmaid"
            ? "firmaidStr"
            : "sirket_Kod",
      }}
      onChangeExtra={(oldVal, newVal) => {
        if (personelInputRef?.current) {
          personelInputRef.current.changeValue(null);
        }
        if (Array.isArray(kullaniciInputRef)) {
          kullaniciInputRef.forEach((ref) => {
            // ref.current.changeValue(null);
          });
        } else if (kullaniciInputRef?.current) {
          kullaniciInputRef.current.changeValue(null);
        }
      }}
      input={input}
      {...rest}
    />
  );
}
export default SirketInputForm;
