import React, { useContext } from "react";
import DataGrid, {
  Column,
  Button,
  Lookup,
  Scrolling,
  Paging,
  Pager,
  Selection,
  Export,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { HttpAuthorized } from "../../common/common";
import {
  apiUrl,
  APP_BAR_HEIGHT,
  FILTER_HEIGHT,
  PLAN_DURUM,
  TAB_HEIGHT,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import { findPrimaryKey } from "../../common/constant-functions/findPrimaryKey";
import { Grid, Paper } from "@mui/material";
import { Add } from "@mui/icons-material";
import "../../css/PageGrid.css";
import { AppContext } from "../../common/AppContext";
import { printToast } from "../../common/constant-functions/printToast";
import ExportButton from "../buttons/ExportButton";
import { Stack } from "@mui/system";
import getTeklifGoruntule from "../../common/constant-functions/getTeklifGoruntule";
import GridClearButton from "../buttons/custom/GridClearButton";
import useGridState from "../../hooks/use-grid-state";
import SmallButton from "../buttons/SmallButton";
import { ctheme } from "../../theme/theme";

export default function PageGrid({
  PageConfig,
  listGridRef,
  filterRef,
  recordRef,
  isCreatable = true,
  ekranKodu,
  allowSingleSelection,
  onSelectionChanged,
  height,
  ekleBtnDisabled = false,
  columnBtnVisible = true,
  editBtnVisible = true,
  detayRecordRef,
  CustomGridHeaderButton,
  CustomColumnButton = [],
  hasTab = false,
  reqData = {},
  exportButtonVisible = true,
  gridId,
}) {
  const id = gridId + "_PageGrid";
  const context = useContext(AppContext);

  const { saveState, loadState } = useGridState(
    PageConfig.type,
    listGridRef,
    id
  );

  const store = new CustomStore({
    key: findPrimaryKey(PageConfig.type),
    load(loadOptions) {
      let gridSort;
      if (loadOptions.sort) {
        gridSort = loadOptions.sort.map((i) => {
          let entity = PageConfig.type.find((x) => x.name == i.selector);
          return {
            propertyName: i.selector,
            order: i.desc ? "desc" : "asc",
            nullsLast: entity?.nullsLast,
          };
        });
      }
      return HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${PageConfig.listRoute}`,
        data: {
          Criteria: filterRef?.current?.filterData ?? { ...reqData },
          GridCriterias: {
            sortModel: loadOptions.sort ? gridSort : null,
            filterModel: [],
          },
          Pagination: loadOptions.isLoadingAll
            ? {
                rowOffset: 0,
                maxRowsPerPage: listGridRef.current.instance.totalCount(),
              }
            : {
                rowOffset: loadOptions.skip,
                maxRowsPerPage: loadOptions.take,
              },
        },
      })
        .then((res) => {
          printToast(res.data);

          return {
            data: res.data.data.list,
            totalCount: res.data.data.pagination.totalRowCount,
          };
        })
        .catch((error) => {
          printToast(error.response.data);
          return {
            data: [],
            totalCount: 0,
          };
        });
    },
  });

  // const externalColumnButton = (e) => {
  //   return (
  //     <>
  //       <GridClearButton gridRef={listGridRef}></GridClearButton>
  //     </>
  //   );
  // };

  const onRowPrepared = (e) => {
    if (e.rowType === "data" && gridId === "Toplanti") {
      const durumValue = e.data["durum"];
      if (durumValue === PLAN_DURUM.GERCEKLESTI.Value) {
        e.rowElement.style.backgroundColor = ctheme.colors.frostedPlains;
      } else if (durumValue === PLAN_DURUM.IPTAL_EDILDI.Value) {
        e.rowElement.style.backgroundColor = ctheme.colors.lightOrange;
      }
    }
  };

  return (
    <Paper
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <Stack
        direction="row"
        sx={{
          mb: "3px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        spacing={1}
      >
        <Grid>
          {isCreatable &&
            context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu)
              ?.kaydet && (
              <SmallButton
                startIcon={<Add />}
                onClick={() => {
                  recordRef?.current?.openRecord(null);
                }}
                disabled={ekleBtnDisabled}
              >
                Ekle
              </SmallButton>
            )}
          {exportButtonVisible && (
            <ExportButton
              pageConfig={PageConfig}
              gridRef={listGridRef}
            ></ExportButton>
          )}
          {CustomGridHeaderButton && (
            <CustomGridHeaderButton></CustomGridHeaderButton>
          )}
        </Grid>
        <Grid>
          <GridClearButton gridRef={listGridRef}></GridClearButton>
        </Grid>
      </Stack>

      <Grid variant="outlined">
        <DataGrid
          id={id}
          height={
            height
              ? height
              : hasTab
              ? `calc(100vh - ${APP_BAR_HEIGHT} - ${FILTER_HEIGHT} - ${TAB_HEIGHT}`
              : `calc(100vh - ${APP_BAR_HEIGHT} - ${FILTER_HEIGHT} )`
          }
          dataSource={store}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          // showRowLines={true}
          columnAutoWidth={true}
          remoteOperations={true}
          ref={listGridRef}
          {...(onSelectionChanged
            ? { onSelectionChanged: onSelectionChanged }
            : {})}
          toolbar={{
            items: [
              // { widget: 'dxButton', location: 'after', options: { icon: 'refresh' } },
            ],
          }}
          onRowPrepared={onRowPrepared}
        >
          <Export enabled={true} />
          {allowSingleSelection && <Selection mode={"single"} />}
          <Scrolling columnRenderingMode="virtual" />
          <StateStoring
            enabled={true}
            type="custom"
            storageKey={id}
            customLoad={loadState}
            customSave={saveState}
          />
          {PageConfig.type
            .filter((type) => {
              let gridVisible = type.visible?.includes("grid");
              if (gridVisible) {
                if (type.visibleConditions) {
                  let innerVisible = true;
                  for (let i = 0; i < type.visibleConditions?.length; i++) {
                    if (type.visibleConditions[i].isTeklifGoruntule) {
                      innerVisible = getTeklifGoruntule(
                        ekranKodu,
                        context.user.yetkiler
                      );
                      if (!innerVisible) break;
                    }

                    if (type.visibleConditions[i].isHarmonyAdmin) {
                      innerVisible =
                        context.user.yetkiGrubuTipi ===
                        YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;
                      if (!innerVisible) break;
                    }
                    if (type.visibleConditions[i].gizlenecekYetkiGrubuTipi) {
                      if (
                        Array.isArray(
                          type.visibleConditions[i].gizlenecekYetkiGrubuTipi
                        )
                      ) {
                        innerVisible = !type.visibleConditions[
                          i
                        ].gizlenecekYetkiGrubuTipi.includes(
                          context.user.yetkiGrubuTipi
                        );
                      } else {
                        innerVisible =
                          context.user.yetkiGrubuTipi !==
                          type.visibleConditions[i].gizlenecekYetkiGrubuTipi;
                      }
                      if (!innerVisible) break;
                    }
                  }

                  return innerVisible;
                } else {
                  return true;
                }
              } else return false;
            })
            .map((i, index) => {
              return (
                <Column
                  key={i.name}
                  dataType={i.dataType}
                  caption={i.label}
                  dataField={i.dataField}
                  name={i.name}
                  visibleIndex={index}
                  alignment={
                    i.alignment
                      ? i.alignment
                      : i.dataType === "number"
                      ? "right"
                      : i.dataType === "boolean"
                      ? "center"
                      : "left"
                  }
                  defaultSortOrder={i.firstSort ? i.firstSort : null}
                  width={i.width ? i.width : null}
                  minWidth={i.minWidth ? i.minWidth : 100}
                  maxWidth={i.maxWidth ? i.maxWidth : 100}
                  format={i.format ? i.format : null}
                  visible={i.pageGridHidden ? false : true}
                  {...(i.calculateSortValue && {
                    calculateSortValue: i.calculateSortValue,
                  })}
                  {...(i.defaultSortIndex && {
                    defaultSortIndex: i.defaultSortIndex,
                  })}
                  {...(i.sortingMethod && {
                    sortingMethod: i.sortingMethod,
                  })}
                  {...(i.cellRender && { cellRender: i.cellRender })}
                  {...(i.customizeText && { customizeText: i.customizeText })} //todo excel e aktarırken muinin bileşenleri aktarılmadığı için cell render kullanılan fieldlara bu alanın da eklenmesi gerekir
                  allowExporting={i.allowExporting ?? true}
                  allowSorting={i.allowSorting ?? true}
                  {...(i.cellTemplate && { cellTemplate: i.cellTemplate })}
                >
                  {i.dataType === "select" ? (
                    <Lookup
                      key={i.dataField + "_lookup"}
                      dataSource={i.selectItems}
                      displayExpr="Text"
                      valueExpr="Value"
                    ></Lookup>
                  ) : i.dataType === "button" ? (
                    <></>
                  ) : null}
                </Column>
              );
            })}
          <Column
            type="buttons"
            visibleIndex={0}
            fixed={true}
            fixedPosition={0}
            alignment={"center"}
            visible={columnBtnVisible}
            // headerCellRender={(e) => {
            //   return externalColumnButton(e);
            // }}
          >
            <Button
              hint={"Düzenle"}
              icon={"edit"}
              onClick={(e) => {
                detayRecordRef
                  ? detayRecordRef?.current?.openRecord(e.row.key)
                  : recordRef?.current?.openRecord(e.row.key);
              }}
              visible={editBtnVisible}
            ></Button>
            {CustomColumnButton}
          </Column>
          <Paging defaultPageSize={12} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={[15, 25, 50]}
            displayMode="full"
          />
        </DataGrid>
      </Grid>
    </Paper>
  );
}
