import Type from "../../entities/MusteriTalep";

const PageConfig = {
  title: "Talepler",
  ekranKodu: "Musteri_Res",
  type: Type,
  listRoute: "MusteriTalep/List",
  getByIdRoute: "MusteriTalep/GetById",
  insertRoute: "MusteriTalep/Insert",
  updateRoute: "MusteriTalep/Update",
  deleteRoute: "MusteriTalep/Delete",
  deleteWithFilesRoute: "MusteriTalep/DeleteWithFiles",
};

const FileConfig = {
  allFilesDownloadRoute: "MusteriTalep/DosyalariIndir",
  fileDownloadRoute: "MusteriTalep/DosyaIndir",
  filesInfoRoute: "MusteriTalep/DosyaBilgileri",
  fileUploadRoute: "MusteriTalep/DosyaYukle",
  deleteFilesRoute: "MusteriTalep/DeleteFiles",
};

export { PageConfig, FileConfig };
