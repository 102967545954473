import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { EKRAN_KODU, ISIN_DURUMU, ISLEM_TURU } from "../../common/constants";
import { ctheme } from "../../theme/theme";
import "../../css/YorumInputForm.css";
import { AppContext } from "../../common/AppContext";
import EditIcon from "@mui/icons-material/Edit";
import IsYorumDialog from "./IsYorumDialog";
import { HtmlEditor } from "devextreme-react";
import SearchIcon from "@mui/icons-material/Search";

const IsYorumList = React.forwardRef(
  ({ inDialog, ekranKodu, listGridRef, yorumMaxHeight = "55px" }, ref) => {
    const yorumDialogRef = useRef();
    const context = useContext(AppContext);

    const [yorumData, setYorumData] = useState({
      talepId: null,
      yorumlar: [],
    });

    useImperativeHandle(ref, () => ({
      setYorumData: setYorumData,
      talepId: yorumData.talepId,
    }));

    return (
      <>
        {!inDialog && (
          <>
            <Paper
              sx={{
                width: "100%",
                bgcolor: ctheme.colors.lightYellowTransparent,
              }}
            >
              <Typography variant="subtitle1" align="center">
                YORUMLAR
              </Typography>
            </Paper>
            <Divider sx={{ borderColor: "#c3dfbc" }} />
          </>
        )}

        <Grid
          sx={{
            overflow: "auto",
            bgcolor: ctheme.colors.lightYellowTransparent,
            height: inDialog ? "70vh" : `calc(100vh - 85px )`,
          }}
        >
          <Grid
            sx={{
              pl: "5px",
            }}
          >
            {yorumData?.yorumlar?.map((yorum, index) => {
              return (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    m: "5px 1px",
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    sx={{ p: "0px 10px" }}
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "green",
                        }}
                      >
                        {yorum?.yorumYapanKullaniciIdStr}
                        {yorum.isinDurumu &&
                          ` - ${
                            Object.values(ISIN_DURUMU).find(
                              (isinDurumu) =>
                                isinDurumu.Value === yorum.isinDurumu
                            ).Text
                          }`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "success.main",
                        }}
                      >
                        {dayjs(yorum?.tarih).format("HH:mm / DD.MM.YYYY ")}
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          yorumDialogRef?.current?.openRecord(yorum.id);
                        }}
                      >
                        {yorum.yorumYapanKullaniciId === context?.user?.id ? (
                          <EditIcon fontSize="inherit" />
                        ) : (
                          <SearchIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid xs={12}>
                    {yorum?.yorum && (
                      <div className="widget-container">
                        <HtmlEditor
                          className="myHtmlEditor"
                          value={yorum?.yorum}
                          readOnly={true}
                          style={{
                            maxHeight: yorumMaxHeight,
                          }}
                        ></HtmlEditor>
                      </div>
                    )}
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    sx={{ p: "0px 10px" }}
                  >
                    <Grid item>
                      <Typography variant="caption" sx={{ color: "#046582" }}>
                        Harcanan süre: {yorum?.harcananSure ?? 0} Dakika
                      </Typography>
                    </Grid>
                    <Grid item>
                      {yorum?.islemTuru && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#046582",
                          }}
                        >
                          İşlem türü:{" "}
                          {
                            Object.values(ISLEM_TURU).find(
                              (x) => x.Value === yorum?.islemTuru
                            )?.Text
                          }
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </Grid>
        <IsYorumDialog
          ref={yorumDialogRef}
          ekranKodu={EKRAN_KODU.GOREVLER.value}
          yorumListRef={ref}
          listGridRef={listGridRef}
        ></IsYorumDialog>
      </>
    );
  }
);

export default IsYorumList;
