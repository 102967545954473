import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form, FormSpy } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./TeklifDetayConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import { URUN_TIP, PARA_BIRIMI, apiUrl } from "../../common/constants";
import UrunlerInputForm from "../../components/inputs/custom-form-inputs/UrunlerInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { AppContext } from "../../common/AppContext";
import dayjs from "dayjs";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";

const TeklifDetayRecord = React.forwardRef(
  (
    {
      ekranKodu,
      listGridRef,
      detailGridRef,
      editGridRef,
      fiyatListeid,
      teklifid,
      kullaniciSayisi,
      setGridDs,
    },
    ref
  ) => {
    const context = React.useContext(AppContext);

    const initialState = {
      aktif: true,
      degisiklikTarihi: dayjs(),
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
      degisiklikYapan: context?.user?.id,
    };

    const urunKoduInputRef = useRef();
    const urunTipiRef = useRef();

    const customFetchData = (id) => {
      let rows = editGridRef.current.instance.getVisibleRows();
      let currentRow = rows.find((x) => x.key == id);
      setRowData(currentRow.data);
      setModalLoading(false);
    };

    const customInsertOrUpdate = (values) => {
      var tempChanges = editGridRef.current.instance
        .option("editing.changes")
        .slice();
      if (recId) {
        let change = tempChanges.find((x) => x.key == recId);
        if (!change) {
          tempChanges.push({
            key: recId,
            data: {},
            type: "update",
          });
          change = tempChanges.find((x) => x.key == recId);
        }

        let rows = editGridRef.current.instance.getVisibleRows();
        let currentRow = rows.find((x) => x.key == recId);
        Object.keys(currentRow.data).map((key) => {
          if (values[key] != currentRow.data[key]) {
            change.data[key] = values[key];
          }
        });
      } else {
        tempChanges.push({
          key: crypto.randomUUID(),
          data: values,
          type: "insert",
        });
      }

      editGridRef.current.instance.option("editing.changes", tempChanges);

      handleClose();
    };

    const values = useRef(null);
    var brFiyatEnabled = useRef(null);
    brFiyatEnabled = true;

    const afterDelete = () => {
      if (typeof recId === typeof "") {
        let tempChanges =
          editGridRef.current.instance.option("editing.changes");
        editGridRef.current.instance.option(
          "editing.changes",
          tempChanges.filter((x) => x.key != recId)
        );
      } else {
        let ds = editGridRef.current.instance.option("dataSource");
        setGridDs(ds.filter((x) => x.id != recId));
      }
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData,
      setModalLoading,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      detailGridRef,
      false,
      customFetchData,
      customInsertOrUpdate,
      null,
      afterDelete
    );

    const validationSchema = yup.object().shape({
      ...(context.user.bayiKullanicisi && {
        iskontoOrani: yup
          .number()
          .max(
            context?.user?.maxIskontoOrani,
            "İskonto oranı maximum '" +
              context?.user?.maxIskontoOrani +
              "' olabilir."
          ),
      }),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Teklif Satırı Girişi"
          maxWidth="sm"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              reset,
              values: formValues,
            }) => (
              <FormComponent
                onSubmit={handleSubmit}
                style={{ height: "530px" }}
              >
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props) => {
                    form.change(
                      "gosterilecekUrunAdi",
                      urunKoduInputRef?.current?.label
                    );

                    if (
                      values?.current?.kdvOrani != props.values.kdvOrani ||
                      values?.current?.birimFiyat != props.values.birimFiyat ||
                      values?.current?.iskontoOrani != props.values.iskontoOrani
                    ) {
                      let xkdvTutari =
                        ((props.values.birimFiyat
                          ? props.values.birimFiyat
                          : 0) *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                        100;
                      form.change("kdvTutari", xkdvTutari);
                      let xkdvliBirimFiyat =
                        (props.values.birimFiyat
                          ? props.values.birimFiyat
                          : 0) + xkdvTutari;
                      form.change("kdvliBirimFiyat", xkdvliBirimFiyat);
                      let xIskontoTutari =
                        (xkdvliBirimFiyat *
                          (props.values.iskontoOrani
                            ? props.values.iskontoOrani
                            : 0)) /
                        100;
                      form.change("iskontoTutari", xIskontoTutari);

                      form.change(
                        "genelToplam",
                        xkdvliBirimFiyat - xIskontoTutari
                      );
                    }

                    values.current = props.values;
                  }}
                />
                <DialogContentWrapper>
                  {/* <DialogContentTop> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Teklif Detay No"
                      modalLoading={modalLoading}
                      rowData={rowData}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper>
                    <FieldWrapper label="Teklif No" modalLoading={modalLoading}>
                      <Field
                        disabled
                        name="masterid"
                        component={TextInputForm}
                        defaultValue={teklifid}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Ürün Tipi"
                      modalLoading={modalLoading}
                      color="#db2c2c"
                    >
                      <Field
                        name="urunTip"
                        items={Object.values(URUN_TIP)}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                        ref={urunTipiRef}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    {formValues.urunTip != URUN_TIP.BAGIMSIZ.Value ? (
                      <FieldWrapper
                        label="Ürün/Paket Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="urunid"
                          rowData={rowData}
                          component={UrunlerInputForm}
                          readOnly={!yetki.kaydet}
                          inputRef={urunKoduInputRef}
                          ekranKodu={ekranKodu}
                          urunTipiRef={urunTipiRef}
                          onChangeExtra={(old, newVal) => {
                            if (newVal?.Value && fiyatListeid) {
                              HttpAuthorized.request({
                                method: "POST",
                                url: `${apiUrl}FiyatListeMaster/UrunFiyatGetir`,
                                data: {
                                  urunId: newVal.Value,
                                  fiyatListesiId: fiyatListeid,
                                  kullaniciSayisi: kullaniciSayisi,
                                },
                              })
                                .then((res) => {
                                  if (res.data.data !== null) {
                                    form?.change(
                                      "birimFiyat",
                                      res.data.data.birimFiyat
                                    );
                                    form?.change(
                                      "dovizKod",
                                      res.data.data.birim
                                    );
                                    form?.change(
                                      "kdvOrani",
                                      res.data.data.kdvOrani
                                    );

                                    brFiyatEnabled = false;
                                  }
                                })
                                .catch((error) => {
                                  printToast(error.response.data);
                                });
                            }
                          }}
                        />
                      </FieldWrapper>
                    ) : (
                      <FieldWrapper
                        label="Hizmet Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="bagimsizUrunAdi"
                          rowData={rowData}
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    )}
                    <FieldWrapper label="Açıklama" modalLoading={modalLoading}>
                      <Field
                        name="aciklama"
                        rowData={rowData}
                        type="text"
                        component={MultilineTextInputForm}
                        readOnly={!yetki.kaydet}
                        minRows={3}
                        maxRows={4}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Birim Fiyat"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="birimFiyat"
                        component={NumberInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                        disabled={!brFiyatEnabled}

                        // onChange={(e) => tutarlariHesapla(e, "birimFiyat")}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Birim" modalLoading={modalLoading}>
                      <Field
                        name="dovizKod"
                        items={Object.values(PARA_BIRIMI)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                        disabled={!brFiyatEnabled}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Kdv Oranı" modalLoading={modalLoading}>
                      <Field
                        name="kdvOrani"
                        component={NumberInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                        disabled={!brFiyatEnabled}
                        // onChange={(e) => tutarlariHesapla(e, "kdvOrani")}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="İskonto Oranı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="iskontoOrani"
                        component={NumberInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                        // onChange={(e) => tutarlariHesapla(e, "iskontoOrani")}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Kdv Tutarı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kdvTutari"
                        component={NumberInputForm}
                        readOnly={true}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Kdv'li Birim Fiyat"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kdvliBirimFiyat"
                        component={NumberInputForm}
                        readOnly={true}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="İskonto Tutarı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="iskontoTutari"
                        component={NumberInputForm}
                        readOnly={true}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Genel Toplam"
                      modalLoading={modalLoading}
                      color="#1eb91e"
                    >
                      <Field
                        name="genelToplam"
                        component={NumberInputForm}
                        readOnly={true}
                        rowData={rowData}
                      />
                    </FieldWrapper>
                  </Grid>
                  {/* </DialogContentTop> */}
                  <DialogActionsWrapper>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default TeklifDetayRecord;
