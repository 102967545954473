import React, { useRef } from "react";
import usePageRecord from "../../hooks/use-page-record";
import PageConfig from "./DestekConfig";
import * as yup from "yup";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Grid } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import dayjs from "dayjs";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import PersonelInputForm from "../../components/inputs/custom-form-inputs/PersonelInputForm";
import {
  DESTEK_ISLEM_TURU,
  EKRAN_KODU,
  RAPOR_KATEGORI,
} from "../../common/constants";
import { AppContext } from "../../common/AppContext";
import RecordButton from "../../components/buttons/custom/RecordButton";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import TalepGoruntuleButton from "../../components/buttons/custom/TalepGoruntuleButton";
import HomeRecord from "../Home/HomeRecord";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";

const DestekRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, state, afterSubmitFnc }, ref) => {
    const context = React.useContext(AppContext);

    const initialState = {
      tarih: dayjs(),
      destekVerenId: context?.user?.id,
      destekVerenIdStr: `${context?.user?.isim} ${context?.user?.soyisim}`,
      raporKategori: RAPOR_KATEGORI.DESTEK.Value,
      ...state,
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    const sirketInputRef = useRef();
    const iletisimKurulanKisiInputRef = useRef();
    const islemTuruInputRef = useRef();
    const talepRecordRef = useRef();

    // const today = dayjs().startOf("day");
    // const minDate = today.subtract(2, "month").startOf("day");

    const validationSchema = yup.object().shape({
      tarih: yup
        .date()
        .min(
          dayjs().startOf("day").subtract(2, "month").startOf("day").toDate(),
          "Bulunduğunuz tarihten 2 Ay öncesine destek kaydı giremezsiniz."
        )
        .required(),
      islemTuru: yup.number().required(),
      destekAlanId: yup.number().when("islemTuru", {
        is: (value) =>
          [
            DESTEK_ISLEM_TURU.ANALIZ_VE_TEST.Value,
            DESTEK_ISLEM_TURU.GUNCELLEME.Value,
            DESTEK_ISLEM_TURU.KURULUM.Value,
          ].includes(value),
        then: yup.number().nullable(),
        otherwise: yup.number().required(), //whendeki koşul sağlanmazsa çalışır
      }),
      sirketId: yup.number().required(),
      // destekSuresi: yup.number().required(),
      aciklama: yup.string().required(),
      destekVerenId: yup.number().required(),
      destekSuresi: yup.number().when("islemTuru", {
        is: (value) =>
          [
            DESTEK_ISLEM_TURU.YAZILIMSAL_HATA.Value,
            DESTEK_ISLEM_TURU.INCELEME.Value,
          ].includes(value),
        then: yup.number().nullable(),
        otherwise: yup.number().required(),
      }),
      sorumlular: yup.array().when("islemTuru", {
        is: (value) =>
          [
            DESTEK_ISLEM_TURU.YAZILIMSAL_HATA.Value,
            DESTEK_ISLEM_TURU.INCELEME.Value,
          ].includes(value),
        then: yup.array().required(),
        otherwise: yup.array().nullable(),
      }),
      km: yup.number().when("islemTuru", {
        is: (value) => value === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value,
        then: yup.number().required(),
        otherwise: yup.number().nullable(),
      }),
      yolUcreti: yup.number().when("islemTuru", {
        is: (value) => value === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value,
        then: yup.number().required(),
        otherwise: yup.number().nullable(),
      }),
    });

    const validate = validateFormValues(validationSchema);

    const yonlendirilecekSorumluVisible = () => {
      return (
        islemTuruInputRef?.current?.value ===
          DESTEK_ISLEM_TURU.YAZILIMSAL_HATA.Value ||
        islemTuruInputRef?.current?.value === DESTEK_ISLEM_TURU.INCELEME.Value
      );
    };

    const firmaZiyaretiExtraFieldVisible = () => {
      return (
        islemTuruInputRef?.current?.value ===
          DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value ||
        rowData.islemTuru === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value
      );
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Destek Girişi"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
              errors,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper label="No" modalLoading={modalLoading}>
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Rapor Kategorisi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(RAPOR_KATEGORI)}
                          name="raporKategori"
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Yapilan İşin Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="tarih"
                          component={DateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>

                      <FieldWrapper label="Sorumlu" modalLoading={modalLoading}>
                        <Field
                          name="destekVerenId"
                          rowData={rowData}
                          remoteDataAdaptor={{
                            fetchRoute: "User/KullaniciListForSelect",
                            fildsForText: ["isimSoyisim"],
                            fieldForValue: "id",
                            reqData: { getSirket_KodFromSession: true },
                          }}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      {!yonlendirilecekSorumluVisible() && (
                        <FieldWrapper
                          label="İş İçin Harcanan Süre (dk)"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="destekSuresi"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                            numberInput={true}
                            disabled={yonlendirilecekSorumluVisible()}
                          />
                        </FieldWrapper>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="İş Grubu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketId"
                          rowData={rowData}
                          component={SirketInputForm}
                          readOnly={!yetki.kaydet}
                          inputRef={sirketInputRef}
                          personelInputRef={iletisimKurulanKisiInputRef}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="İletişim Kurulan Kişi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="destekAlanId"
                          rowData={rowData}
                          component={PersonelInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            sirketInputRef?.current?.value === ""
                          }
                          inputRef={iletisimKurulanKisiInputRef}
                          ekranKodu={ekranKodu}
                          sirketInputRef={sirketInputRef}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="İşlem Türü"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="islemTuru"
                          items={Object.values(DESTEK_ISLEM_TURU)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                          ref={islemTuruInputRef}
                        />
                      </FieldWrapper>
                      {/* todo bu işlem türü firma ziyareti seçildiğinde gözükecek */}
                      {firmaZiyaretiExtraFieldVisible() && (
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12} sm={6}>
                            <FieldWrapper
                              label="KM"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="km"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                            <FieldWrapper
                              label="Konaklama Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="konaklamaUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FieldWrapper
                              label="Yol Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="yolUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                            <FieldWrapper
                              label="Yemek Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="yemekUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                          </Grid>
                        </Grid>
                      )}

                      {yonlendirilecekSorumluVisible() && (
                        <FieldWrapper
                          label="Yönlendirilecek Sorumlu"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="sorumlular"
                            rowData={rowData}
                            remoteDataAdaptor={{
                              fetchRoute:
                                "User/KullaniciListForTalepYonlendirmeSelect",
                              fildsForText: ["isimSoyisim"],
                              fieldForValue: "id",
                              reqData: {
                                talepId: rowData?.talepId,
                              },
                            }}
                            component={MultipleAutocomplateInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </FieldWrapper>
                      )}
                    </Grid>
                  </DialogContentTop>

                  <Field
                    name="aciklama"
                    component={RichTextEditor}
                    readOnly={!yetki.kaydet}
                    label={"Açıklama"}
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>
                <DialogActionsWrapper
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    {rowData.talepId > 0 && (
                      <TalepGoruntuleButton
                        onClick={(e) => {
                          talepRecordRef?.current?.openRecord(rowData.talepId);
                        }}
                      />
                    )}
                  </Grid>

                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    submitExtra={() => {
                      if (Object.keys(errors).length < 1) {
                        handleClose();
                      }
                    }}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <HomeRecord
          ref={talepRecordRef}
          ekranKodu={EKRAN_KODU.ANASAYFA.value}
          listGridRef={listGridRef}
        ></HomeRecord>
      </>
    );
  }
);

export default DestekRecord;
