import Type from "../../entities/DestekAsimTahsilat";

const PageConfig = {
  title: "Destek Aşımı Ücretlendirme",
  ekranKodu: "Tahsilat_Takip_Res",
  type: Type,
  listRoute: "DestekAsimTahsilatTakip/List",
  bulkUpdateRoute: "DestekAsimTahsilatTakip/BulkUpdate",
};

export default PageConfig;
