import dayjs from "dayjs";
import {
  AY,
  TAHSILAT_DURUMU,
  TAHSILAT_DURUMU_INT,
  TUTAR_FORMAT,
} from "../common/constants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "tahsilEdildi",
    label: "Tahsil Edildi",
    dataType: "boolean",
    dataField: "tahsilEdildi",
    visible: ["grid"],
    allowEditing: true,
  },
  //   //todo bu destek tablosuna eklenecek mi
  //   {
  //     name: "tahsilEdilenTutar",
  //     label: "Tahsil Edilen Tutar",
  //     dataType: "number",
  //     dataField: "tahsilEdilenTutar",
  //     visible: ["grid"],
  //     format: TUTAR_FORMAT,
  //     allowEditing: true,
  //   },
  {
    name: "sirketAdi",
    label: "Firma Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
    firstSort: "asc",
  },

  {
    name: "destekAy",
    label: "Destek Ayı",
    dataType: "select",
    dataField: "destekAy",
    visible: ["grid"],
    selectItems: AY,
  },
  {
    name: "anlasilanSure",
    label: "Standart Süre (Dakika)",
    dataType: "number",
    dataField: "anlasilanSure",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "aylikAnlasilanStandartUcret",
    label: "Anlaşılan Ücret",
    dataType: "number",
    dataField: "aylikAnlasilanStandartUcret",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "sureAsimi",
    label: "Süre Aşımı (Dakika)",
    dataType: "number",
    dataField: "sureAsimi",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "asimUcreti",
    label: "Aşım Ücreti",
    dataType: "number",
    dataField: "asimUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "km",
    label: "Km",
    dataType: "number",
    dataField: "km",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "yolUcreti",
    label: "Yol Ücreti",
    dataType: "number",
    dataField: "yolUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "konaklamaUcreti",
    label: "Konaklama Ücreti",
    dataType: "number",
    dataField: "konaklamaUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "yemekUcreti",
    label: "Yemek Ücreti",
    dataType: "number",
    dataField: "yemekUcreti",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "toplamTutar",
    label: "Toplam Tahsilat Tutarı",
    dataType: "number",
    dataField: "toplamTutar",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    column: "aylikAnlasilanStandartUcret",
    // showInColumn: "aylikAnlasilanStandartUcret",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Anlaşılan Ücret: {0}",
    visible: ["summary"],
  },
  {
    column: "asimUcreti",
    // showInColumn: "asimUcreti",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    // customizeText: (data) => {
    //   return `Toplam Aşım Ücreti: ${data.value}`;
    // },
    displayFormat: "Toplam Aşım Ücreti: {0}",
    visible: ["summary"],
  },
  {
    column: "toplamTutar",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam: {0}",
    visible: ["summary"],
  },
  //todo filtre------------------
  //   {
  //     name: "sirketAdi",
  //     label: "Firma",
  //     dataType: "text",
  //     dataField: "sirketAdi",
  //     visible: ["filter"],
  //   },
  {
    name: "sirketId",
    label: "Firma",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
  {
    name: "ay",
    label: "Ay",
    dataType: "select",
    dataField: "ay",
    visible: ["filter"],
    items: AY,
    defaultValue: dayjs().month() + 1,
  },
  {
    name: "tahsilEdildi",
    label: "Tahsilat Durumu",
    dataType: "select",
    dataField: "tahsilEdildi",
    visible: ["filter"],
    items: Object.values(TAHSILAT_DURUMU),
    defaultValue: TAHSILAT_DURUMU.TAHSILAT_YAPILMADI.Value,
  },
];

export default entity;
