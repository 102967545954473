import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./GorusmelerConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";
import {
  GORUSME_TIPI,
  GORUSME_DURUMU,
  GORUSME_KAYNAK,
} from "../../common/constants";
import PotansiyelMusteriInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriInputForm";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import Typography from "@mui/material/Typography";
import TeklifMasterRecord from "../Teklif/TeklifMasterRecord";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import SellIcon from "@mui/icons-material/Sell";
import GorusmeHatirlatmaDialog from "./GorusmeHatirlatmaDialog";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PotansiyelMusteriEklemeliInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriEklemeliInputForm";
import KullaniciInputForm from "../../components/inputs/custom-form-inputs/KullaniciInputForm";
import ReferansVerenInputForm from "../../components/inputs/custom-form-inputs/ReferansVerenInputForm";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const GorusmelerRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const context = React.useContext(AppContext);

  const teklifVerRef = useRef();
  const gorusmeHatirlatmaRef = useRef();

  const initialState = {
    kayitTarihi: dayjs(),
    kayitEden: context?.user?.id,
    durum: 1,
  };

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const validationSchema = yup.object().shape({
    baslangicTarihi: yup.date().required(),
    tipi: yup.number().required(),
    potFirmaid: yup.number().required(),
    // durum: yup.number().required(),
    durum: yup.number().when("teklifid", {
      is: null,
      then: yup
        .number()
        .oneOf([1, 2], "Teklifi olmayan görüşmelerde bu durum seçilemez ")
        .required(),
      otherwise: yup.number().required(),
    }),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Görüşme"
        maxWidth="md"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12} sm={6} md={6}>
                    <CustomFieldWrapper
                      label="Görüşme No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görüşme Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="baslangicTarihi"
                        component={DateTimeInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görüşme Tipi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(GORUSME_TIPI)}
                        rowData={rowData}
                        name="tipi"
                        component={AutocomplateInputForm}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Firma Adı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="potFirmaid"
                        rowData={rowData}
                        component={PotansiyelMusteriEklemeliInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Durum"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(GORUSME_DURUMU)}
                        rowData={rowData}
                        name="durum"
                        component={AutocomplateInputForm}
                        disabled={!rowData?.id || rowData?.teklifDurumu}
                        readOnly={!rowData?.id || rowData?.teklifDurumu}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Teklif No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifid"
                        component={TextInputForm}
                        readOnly={true}
                      />
                    </CustomFieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card
                      sx={{
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <CardHeader
                        sx={{ padding: "3px" }}
                        title={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              textAlign: "center",
                              fontSize: "16px", // veya istediğiniz değer
                              fontWeight: "bold", // veya istediğiniz değer
                            }}
                          >
                            {"Görüşülen Kişi"}
                          </Typography>
                        }
                      />
                      {/* <CardHeader
                        title="Görüşülen Kişi"
                        sx={{
                          textAlign: "center",
                          typography: {
                            fontSize: "2px", // Font boyutunu ayarlayabilirsiniz
                            fontWeight: "bold", // Kalınlığı ayarlayabilirsiniz
                          },
                          padding: "3px",
                        }}
                      ></CardHeader> */}
                      <CardContent sx={{ padding: "3px" }}>
                        <CustomFieldWrapper
                          label="Ad Soyad"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="gorusmeAdSoyad"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Telefon"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="gorusmeTelefonNo"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="E-Posta"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="gorusmeEposta"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                      </CardContent>
                    </Card>
                    <CustomFieldWrapper
                      label="M. T."
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="musteriTemsilcisi"
                        component={KullaniciInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Referans Veren"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="referansVeren"
                        component={ReferansVerenInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Kaynak"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kaynak"
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                        items={Object.values(GORUSME_KAYNAK)}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                  </Grid>
                </DialogContentTop>
                <Field
                  label="Açıklama-1"
                  name="aciklama1"
                  component={MultilineTextInputForm}
                  type="text"
                  rowData={rowData}
                  modalLoading={modalLoading}
                  minRows={5}
                />
                <Field
                  label="Açıklama-2"
                  name="aciklama2"
                  component={MultilineTextInputForm}
                  type="text"
                  rowData={rowData}
                  modalLoading={modalLoading}
                  minRows={5}
                />
                <Field
                  label="Açıklama-3"
                  name="aciklama3"
                  component={MultilineTextInputForm}
                  type="text"
                  rowData={rowData}
                  modalLoading={modalLoading}
                  minRows={5}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <ButtonWrapper
                    color="primary"
                    startIcon={<SellIcon />}
                    onClick={(e) => {
                      {
                        rowData.teklifid
                          ? teklifVerRef?.current?.openRecord(rowData.teklifid)
                          : teklifVerRef?.current?.openRecord(null);
                      }
                      teklifVerRef?.current?.openRecord(null);
                    }}
                    disabled={!rowData.id}
                  >
                    {rowData.teklifid ? "Teklifi Göster" : "Teklif Ver"}
                  </ButtonWrapper>
                  <ButtonWrapper
                    color="primary"
                    startIcon={<AccessAlarmIcon />}
                    onClick={(e) => {
                      gorusmeHatirlatmaRef?.current?.openRecord(null);
                    }}
                    disabled={!rowData.id}
                  >
                    Görüşme Hatırlat
                  </ButtonWrapper>
                </Grid>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <TeklifMasterRecord
        ref={teklifVerRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        gorusmeRef={rowData}
        // detailGridRef={detailGridRef}
      ></TeklifMasterRecord>
      <GorusmeHatirlatmaDialog
        ref={gorusmeHatirlatmaRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        gorusmeRef={rowData}
      ></GorusmeHatirlatmaDialog>
    </>
  );
});
export default GorusmelerRecord;
