import {
  Alert,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import LoginIcon from "@mui/icons-material/Login";
import { AppContext } from "../../common/AppContext";
import routes from "../../common/routes";
import { ctheme } from "../../theme/theme";
import { YETKI_GRUBU_TIPI } from "../../common/constants";

export default function Login() {
  const [loginData, setLoginData] = useState({ kullaniciAdi: "", sifre: "" });
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();
  const context = useContext(AppContext);

  const buttonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      buttonRef.current.click();
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          width: 500,
          // height: "50%",
          padding: 2,
          bgcolor: ctheme.colors.lynxWhite,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
        variant="outlined"
      >
        <Grid className="headerWrapper" sx={{ width: "100%" }}>
          <Grid
            sx={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <img src="/erplogorenkli.png" alt="Logo" />
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 1, mb: 4 }} />
        </Grid>

        <Container sx={{ width: "90%", mb: 2 }} className="contentWrapper">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "end",
            }}
          >
            <Typography>Kullanıcı Adı</Typography>
            <TextField
              value={loginData.kullaniciAdi}
              inputProps={{ style: { padding: 13 } }}
              onChange={(e) =>
                setLoginData({ ...loginData, kullaniciAdi: e.target.value })
              }
              sx={{ width: "65%" }}
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <br />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Şifre</Typography>
            <TextField
              sx={{ width: "65%" }}
              inputProps={{ style: { padding: 13 } }}
              type="password"
              value={loginData.sifre}
              onChange={(e) =>
                setLoginData({ ...loginData, sifre: e.target.value })
              }
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <br />
          <Button
            ref={buttonRef}
            onClick={() => {
              AuthService.login(loginData.kullaniciAdi, loginData.sifre).then(
                (res) => {
                  if (res.success) {
                    context.setUser(res.data);

                    if (res?.data?.data?.params) {
                      let params = JSON.parse(res.data.data.params);
                      context.userParamsRef.current = params;
                    }

                    if (
                      res.data.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value
                    ) {
                      navigate(routes.musteriTalepleri.path);
                    } else if (
                      res.data.yetkiGrubuTipi ===
                        YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
                      res.data.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI.Value ||
                      res.data.yetkiGrubuTipi ===
                        YETKI_GRUBU_TIPI.BAYI_DESTEK.Value
                    ) {
                      navigate(routes.home.path);
                    } else {
                      navigate(routes.gorevler.path);
                    }
                  } else {
                    setErrorMessage(res.message);
                  }
                  console.log(res);
                }
              );
            }}
            sx={{ width: "100%", p: 1, mt: 1.5, fontSize: "17px" }}
            variant="contained"
            color="primary"
            startIcon={<LoginIcon />}
          >
            Giriş Yap
          </Button>
        </Container>
      </Paper>
      {errorMessage && (
        <Alert sx={{ mt: 5 }} variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
    </Grid>
  );
}
