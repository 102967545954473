const PageConfig = {
  title: "İş Yorumları",
  ekranKodu: "Gorev_Res",
  listRoute: "IsYorum/List",
  getByIdRoute: "IsYorum/GetById",
  insertRoute: "IsYorum/Insert",
  updateRoute: "IsYorum/Update",
  deleteRoute: "IsYorum/Delete",
};

export default PageConfig;
