import { DATE_FORMAT, YETKI_GRUBU_TIPI } from "../common/constants";
import { DATE_WIDTH, ID_WIDTH } from "../common/entityconstants";
import dayjs from "dayjs";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";

const entity = [
  {
    name: "id",
    label: "Potansiyel M. No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "musteriAdi",
    label: "Potansiyel M. Adı",
    dataType: "text",
    dataField: "musteriAdi",
    visible: ["grid"],
  },
  {
    name: "vergiNo",
    label: "Vergi No",
    dataType: "text",
    dataField: "vergiNo",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "kayitEdenStr",
    label: "Kayıt Eden",
    dataType: "text",
    dataField: "kayitEdenStr",
    visible: ["grid"],
  },
  {
    name: "adres",
    label: "Adres",
    dataType: "text",
    dataField: "adres",
    visible: ["grid"],
  },
  {
    name: "webSitesi",
    label: "Web Sitesi",
    dataType: "text",
    dataField: "webSitesi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "musteriAdi",
    label: "Müşteri Adı",
    dataType: "text",
    dataField: "musteriAdi",
    visible: ["filter"],
  },
  {
    name: "vergiNo",
    label: "Vergi No",
    dataType: "text",
    dataField: "vergiNo",
    visible: ["filter"],
  },
  {
    name: "filtreKayitTarihi1",
    label: "Kayıt Tarihi-1",
    dataType: "date",
    dataField: "filtreKayitTarihi1",
    visible: ["filter"],
    defaultValue: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    name: "filtreKayitTarihi2",
    label: "Kayıt Tarihi-2",
    dataType: "date",
    dataField: "filtreKayitTarihi2",
    visible: ["filter"],
    defaultValue: dayjs().format("YYYY-MM-DD"),
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
