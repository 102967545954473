import { PLAN_DURUM, YETKI_GRUBU_TIPI } from "../common/constants";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import {
  ACIKLAMA_WIDTH,
  BASLIK_WIDTH,
  DATE_WIDTH,
  ID_WIDTH,
} from "../common/entityconstants";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { personelJoin } from "../common/constant-functions/personelJoin";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Firma",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: YETKI_GRUBU_TIPI.MUSTERI.Value,
      },
    ],
  },
  {
    name: "konuBasligi",
    label: "Konu Başlığı",
    dataType: "text",
    dataField: "konuBasligi",
    visible: ["grid"],
    width: BASLIK_WIDTH,
  },
  {
    name: "konu",
    label: "Konu",
    dataType: "text",
    dataField: "konu",
    visible: ["grid"],
    width: ACIKLAMA_WIDTH,
  },
  {
    name: "planTarihi",
    label: "Plan Tarihi",
    dataType: "date",
    dataField: "planTarihi",
    visible: ["grid"],
    width: DATE_WIDTH,
  },
  {
    name: "planSaati",
    dataField: "planTarihi",
    label: "Plan Saati",
    dataType: "date",
    format: "HH:mm",
    visible: ["grid"],
    width: DATE_WIDTH,
  },
  {
    name: "aksiyonTarihi",
    label: "Aksiyon Tarihi",
    dataType: "date",
    dataField: "aksiyonTarihi",
    visible: ["grid"],
    width: DATE_WIDTH,
  },
  {
    name: "durum",
    label: "Durum",
    dataType: "select",
    dataField: "durum",
    visible: ["grid"],
    selectItems: Object.values(PLAN_DURUM),
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
    width: ACIKLAMA_WIDTH,
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "lokasyon",
    label: "Lokasyon",
    dataType: "text",
    dataField: "lokasyon",
    visible: ["grid"],
    width: ACIKLAMA_WIDTH,
  },

  {
    name: "isTakip",
    label: "İş Takip",
    dataType: "boolean",
    dataField: "isTakip",
    visible: ["grid"],
  },
  {
    name: "sorumlular",
    label: "Sorumlular",
    dataType: "text",
    dataField: "sorumlular",
    visible: ["grid"],
    customizeText: (cellData) => personelJoin(cellData?.value),
    allowSorting: false,
  },
  {
    name: "yetkililer",
    label: "Yetkililer",
    dataType: "text",
    dataField: "yetkililer",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
    allowSorting: false,
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "tarih1",
    label: "Aksiyon Başlangıç Tarihi",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
  },
  {
    name: "tarih2",
    label: "Aksiyon Bitiş Tarihi",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  {
    name: "sirket",
    label: "Şirket",
    dataType: "text",
    dataField: "sirket",
    visible: ["filter"],
    gizlenecekYetkiGrubuTipi: YETKI_GRUBU_TIPI.MUSTERI.Value,
  },
  {
    name: "durum",
    label: "Durum",
    dataType: "select",
    dataField: "durum",
    visible: ["filter"],
    items: Object.values(PLAN_DURUM),
    defaultValue: PLAN_DURUM.PLANLANDI.Value,
  },
];

export default entity;
