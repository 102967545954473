import React, { useRef } from "react";
import { Grid, Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import { ExcelImportConfig, PageConfig } from "./UrunlerConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import { URUN_TIP, URUN_TIP_MODULPAKET } from "../../common/constants";
import UrunGrubuInputForm from "../../components/inputs/custom-form-inputs/UrunGrubuInputForm";
import ExcelImportButton from "../../components/buttons/custom/ExcelImportButton";
import DosyaYukleButton from "../../components/buttons/custom/DosyaYukleButton";
import DosyaYukle from "../../components/file/DosyaYukle";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const UrunlerRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const initialState = { aktif: true };

  const UrunGrubu1InputRef = useRef();
  const UrunGrubu2InputRef = useRef();
  const UrunGrubu3InputRef = useRef();
  const dosyaYukleRef = useRef();

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
    openRecord,
    recId,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const validationSchema = yup.object().shape({
    urunKodu: yup.string().required(),
    urunAdi: yup.string().required(),
    urunTipi: yup.number().required(),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Ürün"
        maxWidth="sm"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            // <FormComponent onSubmit={handleSubmit}>
            <form onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <CustomFieldWrapper
                    label="Ürün No"
                    modalLoading={modalLoading}
                  >
                    <Field disabled name="id" component={TextInputForm} />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Ürün Tipi"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunTipi"
                      items={Object.values(URUN_TIP_MODULPAKET)}
                      rowData={rowData}
                      component={AutocomplateInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper
                    label="Ürün Kodu"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunKodu"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Ürün Adı"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunAdi"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Ürün Grubu 1"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunGrubu1"
                      rowData={rowData}
                      component={UrunGrubuInputForm}
                      inputRef={UrunGrubu1InputRef}
                      ekranKodu={ekranKodu}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Ürün Grubu 2"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunGrubu2"
                      rowData={rowData}
                      component={UrunGrubuInputForm}
                      inputRef={UrunGrubu2InputRef}
                      ekranKodu={ekranKodu}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Ürün Grubu 3"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="urunGrubu3"
                      rowData={rowData}
                      component={UrunGrubuInputForm}
                      inputRef={UrunGrubu3InputRef}
                      ekranKodu={ekranKodu}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper label="Aktif" modalLoading={modalLoading}>
                    <Field
                      name="aktif"
                      component={CheckboxForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                </Stack>
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <DosyaYukleButton
                  label={"Excel'den Al"}
                  onClick={() => {
                    dosyaYukleRef?.current?.openModal();
                  }}
                />
                <DosyaYukle
                  route={"Urunler/ExcelImport"}
                  ref={dosyaYukleRef}
                  // reqExtraData={}
                  rowData={rowData}
                  setRowData={() => {}}
                  allowedFileExtensions={[".xls", ".xlsx"]}
                  modalTitle="Excel'den Al"
                  handleCloseExtra={() => {
                    openRecord(recId);
                  }}
                  sutunSiralamasi={
                    "Ürün Tipi - Ürün Kodu - Ürün Adı - Ürün Grubu 1 - Ürün Grubu 2 - Ürün Grubu 3"
                  }
                />
                {/* <Grid>
                  <ExcelImportButton
                    reqData={{
                      id: rowData.id,
                    }}
                    routes={ExcelImportConfig}
                    ekranKodu={ekranKodu}
                    parentRowData={rowData}
                    // disabled={!rowData?.id}
                  ></ExcelImportButton>
                </Grid> */}
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </form>
            // </FormComponent>
          )}
        />
      </ScrollDialog>
    </>
  );
});
export default UrunlerRecord;
