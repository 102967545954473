import React, { useRef } from "react";
import PageGrid from "../../components/grid/PageGrid";
import GorevConfig from "./GorevConfig";
import PageFilter from "../../components/filter/PageFilter";
import { Button } from "devextreme-react/data-grid";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import IsSonucuDialog from "../Home/IsSonucuDialog";

export default function Gorev({ ekranKodu, onSelectionChanged, listGridRef }) {
  const filterRef = useRef();
  const analizSonucuDialogRef = useRef();
  const isSonucuDialogRef = useRef();

  const CustomColumnButton = [
    <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepId);
      }}
    ></Button>,
    <Button
      key="isSonucu"
      hint="İş Sonucu"
      icon="fieldchooser"
      visible={(e) => {
        return e.row.data.isSonucu != null;
      }}
      onClick={(e) => {
        isSonucuDialogRef?.current?.openRecord(e.row.data.talepId);
      }}
    />,
  ];

  return (
    <>
      <PageFilter
        PageConfig={GorevConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Gorev"
        PageConfig={GorevConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        CustomColumnButton={CustomColumnButton}
        height={"45vh"}
        allowSingleSelection={true}
        onSelectionChanged={onSelectionChanged}
        editBtnVisible={false}
      />
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={false}
        buttonVisible={true}
        listGridRef={listGridRef}
      ></AnalizSonucuDialog>
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog>
    </>
  );
}
