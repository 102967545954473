import React, { useRef } from "react";
import MainTab from "../../components/tab/MainTab";
import { apiUrl } from "../../common/constants";
import BekleyenGorev from "./BekleyenGorev";
import IsYorumList from "./IsYorumList";
import { HttpAuthorized } from "../../common/common";
import Gorev from "./Gorev";

export default function Gorevler({ ekranKodu }) {
  const yorumListRef = useRef();
  const listGridRef = useRef();

  const onSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData && selectedRowsData[0]) {
      let talepId = selectedRowsData[0].talepId;
      yorumList(talepId);
    }
  };

  const yorumList = (talepId) => {
    HttpAuthorized.post(apiUrl + "IsYorum/List", {
      talepId: talepId,
    }).then(async (res) => {
      await yorumListRef.current.setYorumData({
        talepId: talepId,
        yorumlar: res.data.data,
      });
      await listGridRef.current.instance.refresh();
    });
  };

  const tabOnChangeExtra = () => {
    yorumListRef.current.setYorumData({
      talepId: null,
      yorumlar: null,
    });
  };

  return (
    <>
      <MainTab
        labels={["Bekleyen Görevler", "Tüm Görevler"]}
        tabOnChangeExtra={tabOnChangeExtra}
      >
        <BekleyenGorev
          ekranKodu={ekranKodu}
          onSelectionChanged={onSelectionChanged}
          yorumListRef={yorumListRef}
          listGridRef={listGridRef}
        ></BekleyenGorev>
        <Gorev
          ekranKodu={ekranKodu}
          onSelectionChanged={onSelectionChanged}
          listGridRef={listGridRef}
        ></Gorev>
      </MainTab>
      <IsYorumList ref={yorumListRef} listGridRef={listGridRef} />
    </>
  );
}
