import React, { useRef } from "react";
import PageConfig from "./KontrolEdilecekIslerConfig";
import PageGrid from "../../components/grid/PageGrid";
import PageFilter from "../../components/filter/PageFilter";
import { Button } from "devextreme-react/data-grid";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";

export default function KontrolEdilecekIsler({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  // const recordRef = useRef();
  const analizSonucuDialogRef = useRef();

  const CustomColumnButton = [
    <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepId);
      }}
    ></Button>,
  ];

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="FirmaTalep"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        CustomColumnButton={CustomColumnButton}
        editBtnVisible={false}
      />
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={false}
        // buttonVisible={true}
      ></AnalizSonucuDialog>
    </>
  );
}
