import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function SehirInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  ulkeInputRef,
  onChangeExtra,
  ...rest
}) {
  const ulkeidValue =
    typeof ulkeInputRef !== "undefined" && ulkeInputRef?.current?.value !== ""
      ? ulkeInputRef?.current?.value
      : rowData?.ulkeid;

  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "UlkeSehirIlce/SehirlerListForSelect",
          fildsForText: ["sehirAdi"],
          fieldForValue: "sehirid",
          reqData: {
            ...{
              ulkeid: ulkeidValue,
            },
          },
          // fieldLabel: "sehirAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
