import { ListItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ctheme } from "../../../theme/theme";

const NavigateListItem = ({ path, children, color, onClick }) => {
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        p: "4px 8px",
        cursor: "pointer",
        color: color ?? ctheme.colors.navyBlue,
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      }}
      onClick={() => {
        onClick && onClick();
        path && navigate(path);
      }}
    >
      {children}
    </ListItem>
  );
};

export default NavigateListItem;
