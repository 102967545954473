import React, { useContext, useState } from "react";
import DataGrid, {
  Column,
  Lookup,
  Scrolling,
  Paging,
  Editing,
  StateStoring,
  FilterRow,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { Grid, Paper, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import "../../css/PageGrid.css";
import { AppContext } from "../../common/AppContext";
import GridClearButton from "../buttons/custom/GridClearButton";
import useGridState from "../../hooks/use-grid-state";
import SmallButton from "../buttons/SmallButton";

export default function EditablePageGrid({
  dataSource,
  entity,
  detailRef,
  isCreatable,
  ekranKodu,
  detailGridRef,
  gridId,
  filterRowRef,
  customFilterRef,
}) {
  const id = gridId + "_PageGrid";
  const context = useContext(AppContext);

  const { saveState, loadState } = useGridState(entity, detailGridRef, id);

  console.log(customFilterRef);

  return (
    <Paper sx={{ backgroundColor: "transparent" }}>
      <Stack
        direction="row"
        sx={{
          mb: "3px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        spacing={1}
      >
        <Grid>
          {isCreatable &&
          context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu)
            ?.kaydet ? (
            <SmallButton
              startIcon={<Add />}
              onClick={() => {
                detailRef?.current?.openRecord(null);
              }}
            >
              Ekle
            </SmallButton>
          ) : null}
        </Grid>
        <Grid>
          <GridClearButton gridRef={detailGridRef}></GridClearButton>
        </Grid>
      </Stack>

      <Grid variant="outlined">
        <DataGrid
          id={id}
          dataSource={dataSource}
          showBorders={true}
          showRowLines={true}
          columnAutoWidth={true}
          remoteOperations={true}
          ref={detailGridRef}
          onToolbarPreparing={(e) => {
            e.toolbarOptions.visible = false;
          }}
          onSaving={(e) => {
            console.log("drid save: ", e);
          }}
          allowColumnReordering={true}
          allowColumnResizing={true}
        >
          <FilterRow
            visible={filterRowRef}
            showOperationChooser={false}
            applyFilter={customFilterRef}
          />
          <Paging enabled={false} />
          <Scrolling mode="standard" />
          <StateStoring
            enabled={true}
            type="custom"
            storageKey={id}
            customLoad={loadState}
            customSave={saveState}
          />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}
            selectTextOnEditStart={true}
            startEditAction={"click"}
          />
          <Column
            type="buttons"
            visibleIndex={0}
            fixed={true}
            fixedPosition={0}
            alignment={"center"}
            // visible={columnBtnVisible}
          ></Column>
          {entity
            .filter((type) => type.visible?.includes("grid"))
            .map((i, index) => {
              return (
                <Column
                  key={i.name}
                  dataType={i.dataType}
                  caption={i.label}
                  dataField={i.dataField}
                  name={i.name}
                  visibleIndex={index}
                  allowEditing={i.allowEditing}
                  alignment={
                    i.dataType === "number"
                      ? "right"
                      : i.dataType === "boolean"
                      ? "center"
                      : "left"
                  }
                  defaultSortOrder={i.firstSort ? i.firstSort : null}
                  {...(i.defaultSortIndex && {
                    defaultSortIndex: i.defaultSortIndex,
                  })}
                  width={i.width ? i.width : null}
                  minWidth={i.minWidth ? i.minWidth : 50}
                  format={i.format ? i.format : null}
                  visible={i.pageGridHidden ? false : true}
                  cellRender={i.cellRender ? i.cellRender : null}
                  // {...(i.cellRender && { cellRender: i.cellRender })}
                >
                  {i.dataType === "select" ? (
                    <Lookup
                      key={i.dataField + "_lookup"}
                      dataSource={i.selectItems}
                      displayExpr="Text"
                      valueExpr="Value"
                    ></Lookup>
                  ) : null}
                </Column>
              );
            })}
        </DataGrid>
      </Grid>
    </Paper>
  );
}
