import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import entity from "../../entities/YetkiEkrani";
import EditablePageGrid from "../../components/grid/EditablePageGrid";
import useYetkiValues from "../../hooks/use-yetki-values";
import * as yup from "yup";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import PageFilter from "../../components/filter/PageFilter";
import PageConfig from "./YetkiConfig";
import { TextBox } from "devextreme-react";

const YetkiRecord = React.forwardRef(
  ({ ekranKodu, detailGridRef, listGridRef }, ref) => {
    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = useYetkiValues(ref, detailGridRef, listGridRef, ekranKodu);

    const validationSchema = yup.object().shape({
      yetkiGrubuAdi: yup.string().required(),
      yetkiGrubuTipi: yup.string().required(),
    });

    // const [filterValue, setFilterValue] = useState("");

    // const handleFilterChange = (e) => {
    //   setFilterValue(e.value);
    // };

    // const customFilter = () => {
    //   return ["ekranKodu", "contains", filterValue];
    // };

    const filterRef = useRef();
    const validate = validateFormValues(validationSchema);

    return (
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yetki"
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Grid
                  item
                  container
                  direction="row"
                  columnSpacing={2}
                  spacing={6}
                >
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldWrapper label="Yetki No" modalLoading={modalLoading}>
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldWrapper
                      label="Yetki Grubu Adı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="yetkiGrubuAdi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldWrapper
                      label="Yetki Grubu Tipi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="yetkiGrubuTipi"
                        items={Object.values(YETKI_GRUBU_TIPI)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
                {/* <Grid sx={{ marginTop: 5 }}>
                  <TextBox
                    value={false}
                    onValueChanged={handleFilterChange}
                    placeholder="Filtreleme yapmak için bir değer girin"
                  ></TextBox>
                </Grid> */}
                <Grid sx={{ marginTop: 5 }}>
                  {/* <TextBox
                    value={filterValue}
                    onValueChanged={handleFilterChange}
                    placeholder="Filtreleme yapmak için bir değer girin"
                  /> */}
                  <EditablePageGrid
                    gridId="YetkiEkranlari"
                    dataSource={rowData.yetkiEkranlari}
                    filterRef={filterRef}
                    entity={entity}
                    ekranKodu={ekranKodu}
                    detailGridRef={detailGridRef}
                    listGridRef={listGridRef}
                    filterRowRef={true}
                    // customFilterRef={customFilter}
                  />
                </Grid>
              </DialogContentWrapper>

              <DialogActionsWrapper>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  deleteCondition={(rowData) =>
                    rowData.id !== 1 && rowData.id !== 2 && rowData.id !== 3
                  }
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);
export default YetkiRecord;
