import { AY, TUTAR_FORMAT } from "../common/constants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";
import dayjs from "dayjs";
import fillYearForSelect from "../common/constant-functions/fillYearForSelect";

const entity = [
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "harcananSureDestek",
    label: "Harcanan Süre - Destek (Dakika)",
    dataType: "number",
    dataField: "harcananSureDestek",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "harcananSureTeklifsizYazilim",
    label: "Harcanan Süre - Teklifsiz Yazılım (Dakika)",
    dataType: "number",
    dataField: "harcananSureTeklifsizYazilim",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "harcananSureTeklifli",
    label: "Harcanan Süre - Teklifli (Dakika)",
    dataType: "number",
    dataField: "harcananSureTeklifli",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "anlasilanSure",
    label: "Anlaşılan Süre (Dakika)",
    dataType: "number",
    dataField: "anlasilanSure",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "toplamHarcananSure",
    label: "Toplam Harcanan Süre (Dakika)",
    dataType: "number",
    dataField: "toplamHarcananSure",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "degerlendirmeyeAlinanSure",
    label: "Değerlendirmeye Alınan Süre (Dakika)",
    dataType: "number",
    dataField: "degerlendirmeyeAlinanSure",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "harcananSureHataTalebi",
    label: "Harcanan Süre-Hata Düzeltme (Dakika)",
    dataType: "number",
    dataField: "harcananSureHataTalebi",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "sureAsimi",
    label: "Süre Aşımı (Dakika)",
    dataType: "number",
    dataField: "sureAsimi",
    visible: ["grid"],
    // format: TUTAR_FORMAT,
    cellRender: ({ value }) => (
      <div style={{ color: value > 0 ? "red" : "black", fontWeight: "bold" }}>
        {value?.toFixed(2)}
      </div>
    ),
  },
  //todo--------filtre--------------------------------------
  {
    name: "ay",
    label: "Ay",
    dataType: "select",
    dataField: "ay",
    visible: ["filter"],
    items: AY,
    // defaultValue: dayjs().month() + 1, // dayjs().month() + 1 bulunduğumuz ayın ay değerini int olarak alır 3 gibi
  },
  {
    name: "yil",
    label: "Yıl",
    dataType: "select",
    dataField: "yil",
    visible: ["filter"],
    items: fillYearForSelect(),
    defaultValue: dayjs().year(),
  },
  {
    name: "sirketId",
    label: "Şirket Kod",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
];

export default entity;
