const PageConfig = {
  title: "Personeller",
  ekranKodu: "Personel_Res",
  type: null,
  insertRoute: "Personel/Insert",
  getByIdRoute: "Personel/GetById",
  updateRoute: "Personel/Update",
  deleteRoute: "Personel/Delete",
  EMailGetByIdRoute: "Personel/EmailGetById",
  EMailUpdateRoute: "Personel/UpdateEMail",
};

export default PageConfig;
