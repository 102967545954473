import React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxForm({
  input,
  meta,
  onChangeExtra,
  disabled,
  ...rest
}) {
  return (
    <Checkbox
      {...input}
      checked={Boolean(input.value)}
      onChange={({ target }) => {
        input.onChange(target.checked);
        onChangeExtra && onChangeExtra(target.checked);
      }}
      disabled={disabled}
      {...rest}
    />
  );
}
