import React from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

const PrivateRoute = ({ children }) => {
  let user = AuthService.getCurrentUser();
  if (!user?.token || user?.token === "") {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
