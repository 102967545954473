import React, { useRef } from "react";
import usePageRecord from "../../hooks/use-page-record";
import PageConfig from "./DestekConfig";
import * as yup from "yup";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Grid } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import { DESTEK_ISLEM_TURU, EKRAN_KODU } from "../../common/constants";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import HomeRecord from "../Home/HomeRecord";
import TalepGoruntuleButton from "../../components/buttons/custom/TalepGoruntuleButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";

const DestekDetayRecord = React.forwardRef(
  ({ ekranKodu, listGridRef }, ref) => {
    const talepRecordRef = useRef();

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      context,
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu);

    const validationSchema = yup.object().shape({
      destekVerenId: yup.number().required(),
      aciklama: yup.string().required(),
      destekAlanId: yup.number().when("islemTuru", {
        is: (value) =>
          [
            DESTEK_ISLEM_TURU.ANALIZ_VE_TEST.Value,
            DESTEK_ISLEM_TURU.GUNCELLEME.Value,
            DESTEK_ISLEM_TURU.KURULUM.Value,
          ].includes(value),
        then: yup.number().nullable(),
        otherwise: yup.number().required(), //whendeki koşul sağlanmazsa çalışır
      }),
      sorumlular: yup.array().when("islemTuru", {
        is: (value) =>
          [
            DESTEK_ISLEM_TURU.YAZILIMSAL_HATA.Value,
            DESTEK_ISLEM_TURU.INCELEME.Value,
          ].includes(value),
        then: yup.array().required(),
        otherwise: yup.array().nullable(),
      }),
      km: yup.number().when("islemTuru", {
        is: (value) => value === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value,
        then: yup.number().required(),
        otherwise: yup.number().nullable(),
      }),
      yolUcreti: yup.number().when("islemTuru", {
        is: (value) => value === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value,
        then: yup.number().required(),
        otherwise: yup.number().nullable(),
      }),
    });

    const validate = validateFormValues(validationSchema);

    const yonlendirilecekSorumluVisible = () => {
      return (
        rowData.islemTuru === DESTEK_ISLEM_TURU.YAZILIMSAL_HATA.Value ||
        rowData.islemTuru === DESTEK_ISLEM_TURU.INCELEME.Value
      );
    };

    const firmaZiyaretiExtraFieldVisible = () => {
      return rowData.islemTuru === DESTEK_ISLEM_TURU.FIRMA_ZIYARETI.Value;
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Destek Detayı"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
              errors,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper label="No" modalLoading={modalLoading}>
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Destek Süresi (dk)"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="destekSuresi"
                          component={TextInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            yonlendirilecekSorumluVisible() ||
                            rowData.tahsilEdildi
                          }
                          numberInput={true}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="Destek Veren"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="destekVerenId"
                          rowData={rowData}
                          remoteDataAdaptor={{
                            fetchRoute: "User/KullaniciListForSelect",
                            fildsForText: ["isimSoyisim"],
                            fieldForValue: "id",
                            reqData: { getSirket_KodFromSession: true },
                          }}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="İşlem Türü"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="islemTuru"
                          items={Object.values(DESTEK_ISLEM_TURU)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Destek Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="tarih"
                          component={DateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Firma" modalLoading={modalLoading}>
                        <Field
                          name="sirketId"
                          rowData={rowData}
                          component={SirketInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Destek Alan"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="destekAlanId"
                          rowData={rowData}
                          remoteDataAdaptor={{
                            fetchRoute: "Personel/ListForSelect",
                            fildsForText: ["adSoyad"],
                            fieldForValue: "id",
                            reqData: {
                              sirketId: rowData?.sirketId,
                            },
                          }}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>

                      {yonlendirilecekSorumluVisible() && (
                        <FieldWrapper
                          label="Yönlendirilecek Sorumlu"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="sorumlular"
                            rowData={rowData}
                            remoteDataAdaptor={{
                              fetchRoute:
                                "User/KullaniciListForTalepYonlendirmeSelect",
                              fildsForText: ["isimSoyisim"],
                              fieldForValue: "id",
                              reqData: {
                                talepId: rowData.talepId,
                              },
                            }}
                            component={MultipleAutocomplateInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </FieldWrapper>
                      )}

                      {firmaZiyaretiExtraFieldVisible() && (
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12} sm={6}>
                            <FieldWrapper
                              label="KM"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="km"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                            <FieldWrapper
                              label="Konaklama Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="konaklamaUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FieldWrapper
                              label="Yol Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="yolUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                            <FieldWrapper
                              label="Yemek Ücreti"
                              modalLoading={modalLoading}
                            >
                              <Field
                                name="yemekUcreti"
                                component={TextInputForm}
                                readOnly={!yetki.kaydet}
                                numberInput={true}
                              />
                            </FieldWrapper>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </DialogContentTop>

                  <Field
                    name="aciklama"
                    component={RichTextEditor}
                    readOnly={!yetki.kaydet}
                    label={"Destek İçeriği"}
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>
                <DialogActionsWrapper
                  container
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    {rowData.talepId > 0 && (
                      <TalepGoruntuleButton
                        onClick={(e) => {
                          talepRecordRef?.current?.openRecord(rowData.talepId);
                        }}
                      />
                    )}
                  </Grid>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    deleteDisabled={context.user.id !== rowData.destekVerenId}
                    submitExtra={() => {
                      if (Object.keys(errors).length < 1) {
                        handleClose();
                      }
                    }}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <HomeRecord
          ref={talepRecordRef}
          ekranKodu={EKRAN_KODU.ANASAYFA.value}
          listGridRef={listGridRef}
        ></HomeRecord>
      </>
    );
  }
);

export default DestekDetayRecord;
