import axios from "axios";
import AuthService from "../services/AuthService";
import { apiUrl } from "./constants";

const HttpAuthorized = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  timeout: 40000,
  withCredentials: false,
});

HttpAuthorized.interceptors.request.use(
  function (config) {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      config.headers.Authorization = currentUser.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//todo-------------------------Http-------------------------------------

var Http = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  timeout: 40000,
  withCredentials: false,
});

//todo-------------------------HttpAuthorizedFile-------------------------------

var HttpAuthorizedFile = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
  },
  timeout: 40000,
  withCredentials: false,
});

HttpAuthorizedFile.interceptors.request.use(
  function (config) {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      config.headers.Authorization = currentUser.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//todo-------------------------HttpAuthorizedBlob-----------------------------
var HttpAuthorizedBlob = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  timeout: 40000,
  withCredentials: false,
  responseType: "blob",
});

HttpAuthorizedBlob.interceptors.request.use(
  function (config) {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      config.headers.Authorization = currentUser.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { HttpAuthorized, Http, HttpAuthorizedFile, HttpAuthorizedBlob };
