import { Alert, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { ctheme } from "../../theme/theme";

function SureTypography(label, value, color) {
  return (
    <Grid
      sx={{ display: "flex", justifyContent: "space-between" }}
      container
      direction="row"
    >
      <Typography variant="body2">{label}:</Typography>
      <Typography
        variant="body2"
        color={color ? color : ctheme.colors.retroLime}
      >
        {` ${value?.toFixed(2)} Dakika`}
      </Typography>
    </Grid>
  );
}

const DestekSuresiDialog = React.forwardRef(({ filterRef }, ref) => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  async function fetchData(sirketId) {
    HttpAuthorized.request({
      method: "POST",
      url: "Destek/DestekSuresi",
      data: { sirketId: sirketId, month: filterRef?.current?.filterData?.ay },
    })
      .then((res) => {
        setRowData(res.data.data);
        // setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
      });
  }

  const openRecord = (sirketId) => {
    setOpen(true);
    fetchData(sirketId);
  };
  const closeRecord = (id) => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    openRecord: openRecord,
    closeRecord: closeRecord,
  }));

  const tarihAraligi = () => {
    const today = new Date();
    const selectedMonth = filterRef?.current?.filterData?.ay
      ? filterRef?.current?.filterData?.ay - 1
      : today.getMonth();
    const firstDayOfMonth = new Date(today.getFullYear(), selectedMonth, 1);
    const lastDayOfMonth = new Date(today.getFullYear(), selectedMonth + 1, 0);
    return `${firstDayOfMonth.toLocaleDateString(
      "tr-TR"
    )} - ${lastDayOfMonth.toLocaleDateString("tr-TR")}`;
  };

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Destek Süresi Durumu"
      maxWidth="xs"
    >
      <Stack sx={{ p: 4 }} spacing={2}>
        {SureTypography(
          "Kullanılan Destek Süresi",
          rowData.degerlendirmeyeAlinanSure ?? 0
        )}
        {SureTypography("Aylık Standart Süre", rowData.anlasilanSure ?? 0)}
        {SureTypography(
          "Kalan Destek Süresi",
          (rowData.anlasilanSure ?? 0) -
            (rowData.degerlendirmeyeAlinanSure ?? 0) <
            0
            ? 0
            : (rowData.anlasilanSure ?? 0) -
                (rowData.degerlendirmeyeAlinanSure ?? 0)
        )}
        {SureTypography("Süre Aşımı", rowData.sureAsimi ?? 0, "red")}

        <Typography variant="body2">
          {tarihAraligi()} tarihleri arasında alınan toplam destek süresi
          gösterilmektedir.
        </Typography>

        <Alert severity="error">
          Yazılımsal hata kaynaklı iş süreleri kullanılan destek süresine dahil
          edilmemiştir.
        </Alert>
      </Stack>
    </ScrollDialog>
  );
});

export default DestekSuresiDialog;
