import React from "react";
import { Grid } from "@mui/material";
import { AppContext } from "../../common/AppContext";
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "../../common/constants";
import { ctheme } from "../../theme/theme";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { routesArr } from "../../router/router";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { useLocation } from "react-router-dom";
import AppBarInfoButton from "./AppBarInfoButton";
import AppBarLogoButton from "./AppBarLogoButton";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: ctheme.colors.gridHeaderColor,
  color: "black",
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MyAppBar({ handleDrawerOpen, open }) {
  const context = React.useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <AppBar position="fixed" open={open} sx={{ height: APP_BAR_HEIGHT }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <img
              src="/erplogorenkli.png"
              style={{ height: "50px", marginRight: 10 }}
              alt="Logo"
            />
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="primary.main"
            sx={{ cursor: "default" }}
          >
            {routesArr.find((x) => x.path === pathname)?.text ||
              routesArr
                .find((x) => x.children?.find((y) => y.path === pathname))
                ?.children.find((y) => y.path === pathname)?.text}

            {/* {routesArr.find((x) => x.path == pathname)?.text}  */}
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <Grid sx={{ display: "block", mr: 3, cursor: "default" }}>
            <Typography sx={{ fontSize: "8pt" }} align="center">
              {context.user?.sirketAdi}
            </Typography>
            <Typography sx={{ fontSize: "8pt" }} align="center">
              {context.user?.isim + " " + context.user?.soyisim}
            </Typography>
          </Grid>
          <AppBarInfoButton></AppBarInfoButton>
          <AppBarLogoButton></AppBarLogoButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
