import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./SirketBazliSureAnaliziConfig";

export default function SirketBazliSureAnalizi({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="SirketBazliSureAnalizi"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        columnBtnVisible={false}
        isCreatable={false}
        ekranKodu={ekranKodu}
      />
    </>
  );
}
