import { useState } from "react";

function useFieldWidth() {
  const [fieldTextWidth, setFieldTextWidth] = useState("35%");
  const [fieldCompWidth, setFieldCompWidth] = useState("65%");

  return {
    fieldTextWidth,
    setFieldTextWidth,
    setFieldCompWidth,
    fieldCompWidth,
  };
}

export default useFieldWidth;
