import React, { useRef } from "react";
import { Button } from "devextreme-react/button";
import { HEADER_COLUMN_BUTTONS } from "../../../common/gridconstants";
import AlertDialog from "../../dialogs/AlertDialog";

export default function GridClearButton({ gridRef }) {
  const alertDialogRef = useRef();

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        height={"25px"}
        icon={HEADER_COLUMN_BUTTONS.CLEAR_BUTTON.icon}
        hint={HEADER_COLUMN_BUTTONS.CLEAR_BUTTON.hint}
        onClick={() => {
          alertDialogRef?.current?.openRecord();
        }}
        style={{ backgroundColor: "transparent" }}
      ></Button>
      <AlertDialog
        ref={alertDialogRef}
        onayBtnClick={() => gridRef.current.instance.state(null)}
        dialogTitle=""
        contentText="Sütun değişiklikleri varsayılan olarak değiştirilecek ve kayıt edilecek. Devam etmek istediğinize emin misiniz?"
      ></AlertDialog>
    </div>
  );
}
