import Type from "../../entities/ProjeSurecTakip";

const PageConfig = {
  title: "ProjeSurecTakip",
  ekranKodu: "ProjeSurecTakip_Res",
  type: Type,
  listRoute: "ProjeSurecTakip/List",
  getByIdRoute: "ProjeSurecTakip/GetById",
  insertRoute: "ProjeSurecTakip/Insert",
  updateRoute: "ProjeSurecTakip/Update",
  deleteRoute: "ProjeSurecTakip/Delete",
};

export { PageConfig };
