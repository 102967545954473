import { TextField } from "@mui/material";
import React from "react";

export default function TextInputForm({
  input,
  meta,
  label,
  defaultValue,
  numberInput,
  inputRef,
  onChangeExtra,
  ...rest
}) {
  return (
    <TextField
      {...input}
      {...(inputRef && { inputRef: inputRef })}
      sx={{ width: "100%" }}
      label={label}
      {...(numberInput && { type: "number" })}
      variant="standard"
      defaultValue={defaultValue}
      onChange={(event, value) => {
        input.onChange(
          numberInput || input.type === "number"
            ? event.target.value === ""
              ? undefined
              : +event.target.value
            : event.target.value
        );

        onChangeExtra && onChangeExtra();
      }}
      autoComplete="off"
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      inputProps={{ readOnly: rest.readOnly, maxLength: rest.maxLength }}
      {...rest}
    />
  );
}
