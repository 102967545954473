import React, { useRef } from "react";
import PageConfig from "./ToplantiConfig";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import { Assignment, SupportAgent } from "@mui/icons-material";
import { Grid } from "@mui/material";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import { Field, Form } from "react-final-form";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { PLAN_DURUM, apiUrl } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import dayjs from "dayjs";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import DestekRecord from "../Destek/DestekRecord";
import { HttpAuthorized } from "../../common/common";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import HizmetFormDialog from "../Sirket/HizmetFormDialog";
import { AppContext } from "../../common/AppContext";

const ToplantiRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const sirketInputRef = useRef();
  const personelInputRef = useRef();
  const destekRecordRef = useRef();
  const planTarihiInputRef = useRef();
  const aksiyonTarihiInputRef = useRef();
  const hizmetFormuDialogRef = useRef();

  const initialState = {
    planTarihi: dayjs(),
    isTakip: false,
    // aksiyonTarihi: dayjs(),
  };

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
    setRowDataFnc,
  } = usePageRecord(
    ref,
    PageConfig,
    listGridRef,
    ekranKodu,
    initialState,
    null,
    null
  );

  const validationSchema = yup.object().shape({
    durum: yup.number().required(),
    // isTakip: yup.boolean().required(),
    sirketId: yup.number().required(),
  });

  const validate = validateFormValues(validationSchema);

  const CustomFieldWrapper = ({ label, modalLoading, children }) => {
    return (
      <FieldWrapper
        label={label}
        modalLoading={modalLoading}
        width="89%"
        textWidth="11%"
      >
        {children}
      </FieldWrapper>
    );
  };
  const context = React.useContext(AppContext);

  const destekInitialState = {
    tarih: rowData?.aksiyonTarihi,
    aciklama: rowData?.aciklama,
    sirketId: rowData?.sirketId,
    sirket_Kod: rowData?.sirket_Kod,
    kayitEdenKullanici: context?.user?.id,
    destekVerenId:
      rowData?.yetkililer?.length > 0 ? rowData.yetkililer[0].id : null,
    destekVerenIdStr:
      rowData?.yetkililer?.length > 0
        ? rowData.yetkililer[0].isimSoyisim
        : null,
    destekAlanId:
      rowData?.sorumlular?.length > 0 ? rowData.sorumlular[0].id : null,
    destekAlanIdStr:
      rowData?.sorumlular?.length > 0 ? rowData.sorumlular[0].adSoyad : null,
  };

  const destekAfterSubmitFnc = (res) => {
    if (rowData.destekId == null && res?.data?.data?.id > 0) {
      HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}Toplanti/DestekIdUpdate`,
        data: {
          id: rowData.id,
          destekId: res?.data?.data?.id,
        },
      })
        .then((res) => {
          setRowDataFnc(res.data.data);
          listGridRef?.current?.instance?.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const durumInputOnChangeExtra = (value, newValue) => {
    if (newValue.Value === PLAN_DURUM.GERCEKLESTI.Value) {
      aksiyonTarihiInputRef.current.changeValue(
        planTarihiInputRef.current.value
      );
    }
  };

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Toplantı"
        maxWidth="lg"
      >
        <Form
          key="toplantiForm"
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12} sm={6} md={4}>
                    <FieldWrapper
                      label="Toplantı No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper>
                    <FieldWrapper label="Firma" modalLoading={modalLoading}>
                      <Field
                        name="sirketId"
                        rowData={rowData}
                        component={SirketInputForm}
                        readOnly={!yetki.kaydet}
                        inputRef={sirketInputRef}
                        personelInputRef={personelInputRef}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Konu Başlığı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="konuBasligi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FieldWrapper
                      label="Plan Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="planTarihi"
                        component={DateTimeInputForm}
                        readOnly={!yetki.kaydet}
                        ref={planTarihiInputRef}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Durum" modalLoading={modalLoading}>
                      <Field
                        name="durum"
                        items={Object.values(PLAN_DURUM)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                        onChangeExtra={durumInputOnChangeExtra}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Konu" modalLoading={modalLoading}>
                      <Field
                        name="konu"
                        component={MultilineTextInputForm}
                        readOnly={!yetki.kaydet}
                        minRows={1}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FieldWrapper
                      label="Aksiyon Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aksiyonTarihi"
                        component={DateTimeInputForm}
                        readOnly={!yetki.kaydet}
                        ref={aksiyonTarihiInputRef}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Destek No" modalLoading={modalLoading}>
                      <Field
                        disabled
                        name="destekId"
                        component={TextInputForm}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Destek Kaydı Oluşturuldu"
                      modalLoading={modalLoading}
                      width="55%"
                      textWidth="45%"
                    >
                      <Field
                        name="isTakip"
                        component={CheckboxForm}
                        disabled={true}
                      />
                    </FieldWrapper>
                  </Grid>
                </DialogContentTop>
                <Grid container direction="row" columnSpacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      label="Sorumlular"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="sorumlular"
                        rowData={rowData}
                        remoteDataAdaptor={{
                          fetchRoute: "Personel/ListForSelect",
                          fildsForText: ["adSoyad"],
                          fieldForValue: "id",
                          reqData: {
                            sirketId:
                              sirketInputRef?.current?.value === ""
                                ? 0
                                : sirketInputRef?.current?.value,
                          },
                        }}
                        component={MultipleAutocomplateInputForm}
                        readOnly={
                          !yetki.kaydet || sirketInputRef?.current?.value === ""
                        }
                        ref={personelInputRef}
                        sirketInputRef={sirketInputRef}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FieldWrapper
                      label="Yetkililer"
                      modalLoading={modalLoading}
                      width="83%"
                      textWidth="17%"
                    >
                      <Field
                        name="yetkililer"
                        rowData={rowData}
                        remoteDataAdaptor={{
                          fetchRoute: "User/KullaniciListForSelect",
                          fildsForText: ["isimSoyisim"],
                          fieldForValue: "id",
                          reqData: {
                            getSirket_KodFromSession: true,
                          },
                        }}
                        component={MultipleAutocomplateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
                <CustomFieldWrapper
                  label="Lokasyon"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="lokasyon"
                    component={MultilineTextInputForm}
                    readOnly={!yetki.kaydet}
                    minRows={1}
                  />
                </CustomFieldWrapper>
                <Field
                  name="aciklama"
                  component={RichTextEditor}
                  label="Açıklama"
                  modalLoading={modalLoading}
                  readOnly={!yetki.kaydet}
                  style={{ marginTop: 15 }}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <>
                    {rowData.destekId > 0 ? (
                      <ButtonWrapper
                        color="info"
                        startIcon={<SupportAgent />}
                        onClick={(e) => {
                          destekRecordRef.current?.openRecord(rowData.destekId);
                        }}
                        disabled={!rowData.id}
                      >
                        Destek Kaydını Görüntüle
                      </ButtonWrapper>
                    ) : (
                      <ButtonWrapper
                        color="info"
                        startIcon={<SupportAgent />}
                        onClick={(e) => {
                          destekRecordRef.current?.openRecord(null);
                        }}
                        disabled={!rowData.id}
                      >
                        Destek Kaydını Oluştur
                      </ButtonWrapper>
                    )}
                    <CustomColoredButton
                      color="purple"
                      startIcon={<Assignment />}
                      onClick={(e) => {
                        hizmetFormuDialogRef.current?.openRecord();
                      }}
                      disabled={!rowData.id}
                    >
                      Hizmet Formları
                    </CustomColoredButton>
                  </>
                </Grid>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  deleteDisabled={rowData.destekId > 0}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <DestekRecord
        ref={destekRecordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        state={destekInitialState}
        afterSubmitFnc={destekAfterSubmitFnc}
      ></DestekRecord>
      <HizmetFormDialog
        ekranKodu={ekranKodu}
        ref={hizmetFormuDialogRef}
        parentRowData={rowData}
      ></HizmetFormDialog>
    </>
  );
});
export default ToplantiRecord;
