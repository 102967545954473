import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import GanttComp from "../../components/gantt/GanttComp";
import { PageConfig } from "./GorevlerGanttConfig.js";
import {
  tasks,
  dependencies,
  resources,
  resourceAssignments,
} from "../../common/denemeData.js";
import Gantt, {
  Tasks,
  Dependencies,
  Resources,
  ResourceAssignments,
  Column,
  Editing,
  Toolbar,
  Item,
  Validation,
} from "devextreme-react/gantt";
// import { Toolbar } from "devextreme-react";

function GorevlerGantt({ ekranKodu }) {
  // const listGridRef = useRef();
  const filterRef = useRef();
  // const recordRef = useRef();
  // const detailGridRef = useRef();

  // console.log(PageConfig);
  return (
    <>
      <Gantt taskListWidth={500} scaleType="weeks" height={700}>
        <Tasks dataSource={tasks} />
        <Dependencies dataSource={dependencies} />
        <Resources dataSource={resources} />
        {/* <ResourceAssignments dataSource={resourceAssignments} /> */}

        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="collapseAll" />
          <Item name="expandAll" />
          <Item name="separator" />
          <Item name="addTask" />
          <Item name="deleteTask" />
          <Item name="separator" />
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>

        <Column dataField="title" caption="Subject" width={300} />
        <Column dataField="start" caption="Start Date" />
        <Column dataField="end" caption="End Date" />

        <Validation autoUpdateParentTasks />
        <Editing enabled />
      </Gantt>
    </>
  );
}

export default GorevlerGantt;
