import React, { useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import { HttpAuthorizedFile } from "../../common/common";
import "../../css/CustomDropZone.css";
import { Grid, Stack } from "@mui/material";
import { printToast } from "../../common/constant-functions/printToast";

function CustomDropZone({
  reqExtraData,
  route,
  afterUpload,
  defaultImg,
  dropZoneText = "Resim Yükle",
  sx,
}) {
  const [state, setState] = useState({
    isDropZoneActive: false,
    imageSource: defaultImg,
    textVisible: true,
    progressVisible: false,
    progressValue: 0,
  });

  const onDropZoneEnter = (e) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setState({ ...state, isDropZoneActive: true });
    }
  };

  const onDropZoneLeave = (e) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setState({ ...state, isDropZoneActive: false });
    }
  };

  const onUploaded = (e) => {
    const { file } = e;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setState({
        ...state,
        isDropZoneActive: false,
        imageSource: fileReader.result,
      });
    };
    fileReader.readAsDataURL(file);
    setState({
      ...state,
      textVisible: false,
      progressVisible: false,
      progressValue: 0,
    });
  };

  const onProgress = (e) => {
    setState({ ...state, progressValue: (e.bytesLoaded / e.bytesTotal) * 100 });
  };

  const onUploadStarted = () => {
    setState({
      ...state,
      imageSource: "",
      progressVisible: true,
    });
  };

  return (
    <Grid sx={sx}>
      <Stack
        sx={{ width: "100%", height: "100%" }}
        id="dropzone-external"
        className={`flex-box ${
          state.isDropZoneActive
            ? "dx-theme-accent-as-border-color dropzone-active"
            : "dx-theme-border-color"
        }`}
      >
        {state.imageSource && (
          <img id="dropzone-image" src={state.imageSource} alt="logo" />
        )}
        {state.textVisible && (
          <div id="dropzone-text" className="flex-box">
            {dropZoneText}
          </div>
        )}
        <ProgressBar
          id="upload-progress"
          min={0}
          max={100}
          width="30%"
          showStatus={false}
          visible={state.progressVisible}
          value={state.progressValue}
        ></ProgressBar>
      </Stack>

      <FileUploader
        id="file-uploader"
        dialogTrigger="#dropzone-external"
        dropZone="#dropzone-external"
        multiple={false}
        allowedFileExtensions={[".jpg", ".jpeg", ".gif", ".png", ".webp"]}
        uploadMode="instantly"
        uploadFile={(file, callback) => {
          var FormData = require("form-data");
          var reqData = new FormData();
          reqData.append("file", file);

          reqExtraData?.forEach((item) => {
            reqData.append(item.name, item.value);
          });

          HttpAuthorizedFile.post(route, reqData)
            .then((res) => {
              printToast(res?.data);
              afterUpload && afterUpload(res);
            })
            .catch((error) => printToast(error?.response?.data));
        }}
        visible={false}
        onDropZoneEnter={onDropZoneEnter}
        onDropZoneLeave={onDropZoneLeave}
        onUploaded={onUploaded}
        onProgress={onProgress}
        onUploadStarted={onUploadStarted}
      ></FileUploader>
    </Grid>
  );
}

export default CustomDropZone;
