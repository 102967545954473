import { ID_WIDTH } from "../common/entityconstants";
import { URUN_TIP } from "../common/constants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "urunTip",
    label: "Ürün Tipi",
    dataType: "select",
    dataField: "urunTip",
    visible: ["grid"],
    selectItems: Object.values(URUN_TIP),
  },
  {
    name: "gosterilecekUrunAdi",
    label: "Ürün/Paket Adı",
    dataType: "text",
    dataField: "gosterilecekUrunAdi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  {
    name: "birimFiyat",
    label: "Birim Fiyat",
    dataType: "number",
    dataField: "birimFiyat",
    visible: ["grid"],
  },
  {
    name: "dovizKod",
    label: "Para Birimi",
    dataType: "text",
    dataField: "dovizKod",
    visible: ["grid"],
  },
  {
    name: "kdvOrani",
    label: "KDV Oranı",
    dataType: "number",
    dataField: "kdvOrani",
    visible: ["grid"],
  },
  {
    name: "kdvTutari",
    label: "KDV Tutarı",
    dataType: "number",
    dataField: "kdvTutari",
    visible: ["grid"],
  },
  {
    name: "kdvliBirimFiyat",
    label: "KDVli Birim Fiyat",
    dataType: "number",
    dataField: "kdvliBirimFiyat",
    visible: ["grid"],
  },
  {
    name: "iskontoOrani",
    label: "İskonto Oranı",
    dataType: "number",
    dataField: "iskontoOrani",
    visible: ["grid"],
  },
  {
    name: "iskontoTutari",
    label: "İskonto Tutarı",
    dataType: "number",
    dataField: "iskontoTutari",
    visible: ["grid"],
  },
  {
    name: "genelToplam",
    label: "Genel Toplam",
    dataType: "number",
    dataField: "genelToplam",
    visible: ["grid"],
  },
  {
    name: "kayitEdenStr",
    label: "Kaydı Oluşturan",
    dataType: "text",
    dataField: "kayitEdenStr",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
  },
];

export default entity;
