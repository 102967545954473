import React, { useRef, useContext } from "react";
import YetkiGrubuRecord from "../../../pages/Yetki-grubu/YetkiGrubuRecord";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import { AppContext } from "../../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../../common/constants";

export default function YetkiGrubuInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  ...rest
}) {
  const dialogRef = useRef();
  const context = useContext(AppContext);

  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        insertButtonRef={dialogRef}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "Yetki/YetkiGrubuListForSelect",
          fildsForText: ["yetkiGrubuAdi"],
          fieldForValue: "id",
          reqData: {},
        }}
        ref={inputRef}
        input={input}
        onlyUpdateBtn={true}
        {...rest}
      />
      {context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && (
        <YetkiGrubuRecord
          ref={dialogRef}
          ekranKodu={ekranKodu}
          inputRef={inputRef}
        ></YetkiGrubuRecord>
      )}
    </>
  );
}
