import React from "react";
import SmallButton from "../SmallButton";
import PrintIcon from "@mui/icons-material/Print";
import { toast } from "react-toastify";

export default function DestekAsimTahsilatTakipPrintButton({ filterRef, pdfDialogRef }) {
  return (
    <SmallButton
      color="info"
      startIcon={<PrintIcon />}
      onClick={() => {
        var aData = {Ay :filterRef?.current?.filterData?.ay, SirketId : filterRef?.current?.filterData?.sirketId};
        console.log(filterRef);
        if (
          filterRef?.current?.filterData?.sirketId > 0 &&
          filterRef?.current?.filterData?.ay > 0
        ) {
          pdfDialogRef?.current?.openRecord(-1, true,
            aData
          );
          
          console.log("YAZDIR");
        } else {
          toast.warning("Filtreden şirket ve ay seçimi yapmalısınız.");
        }
      }}
    >
      Yazdır
    </SmallButton>
  );
}
