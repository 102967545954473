import React from "react";
import * as yup from "yup";
import useDialog from "../../hooks/use-dialog";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { Field, Form } from "react-final-form";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import { Save } from "@mui/icons-material";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import useSubmit from "../../hooks/use-submit";

const TeklifRedDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, pdfDialogRef }, ref) => {
    const { open, closeRecord, validateFormValues, id } = useDialog(ref);

    const handleSubmit = useSubmit(
      { updateRoute: "MusteriTalep/TeklifRed" },
      null,
      listGridRef
    );

    const validationSchema = yup.object().shape({
      onayRedAciklama: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Teklif Ver"
        maxWidth="sm"
      >
        <Form
          onSubmit={(values) =>
            handleSubmit({
              ...values,
              id: id,
            })
          }
          initialValues={{}}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <FieldWrapper
                    label="Red Açıklaması"

                    //modalLoading={modalLoading}
                  >
                    <Field
                      name="onayRedAciklama"
                      component={MultilineTextInputForm}
                      type="text"
                    />
                  </FieldWrapper>
                </Stack>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <ButtonWrapper
                  color="success"
                  startIcon={<Save />}
                  onClick={(e) => {
                    form.submit();
                    closeRecord();
                    pdfDialogRef.current.closeRecord();
                  }}
                >
                  Kaydet
                </ButtonWrapper>
              </DialogActionsWrapper>
            </form>
          )}
        />
      </ScrollDialog>
    );
  }
);
export default TeklifRedDialog;
