import React from "react";
import IsKapatmaDialog from "../Musteri-talep/IsKapatmaDialog";
import TemsilciAnaliziDialog from "./TemsilciAnaliziDialog";
import TalepYonlendirmeDialog from "./TalepYonlendirmeDialog";
import YorumDialog from "./YorumDialog";
import PdfDialog from "../../components/pdf/PdfDialog";
import AnalizSonucuDialog from "./AnalizSonucuDialog";
import TeklifRecord from "./TeklifRecord";
import BayiTeklifRecord from "./BayiTeklifRecord";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import { AppContext } from "../../common/AppContext";
import EMailDialog from "../Personel/EmailDialog";
import IsSonucuDialog from "./IsSonucuDialog";
import AlertDialog from "../../components/dialogs/AlertDialog";
import TeklifIptalDialog from "./TeklifIptalDialog";
import BayiTalebiMerkezeGonderDialog from "../Musteri-talep/BayiTalebiMerkezeGonderDialog";
import BayidenMusteriyeDirekTeklifRecord from "./BayidenMusteriyeDirekTeklifRecord";

export default function HomeRecordDialogs({
  ekranKodu,
  rowData,
  setRowDataFnc,
  listGridRef,
  teklifRecordRef,
  bayiTeklifRecordRef,
  yorumDialogRef,
  analizSonucuDialogRef,
  temsilciAnaliziDialogRef,
  talepYonlendirmeDialogRef,
  teklifPdfDialogRef,
  isKapatmaDialogRef,
  onayliPdfDialogRef,
  destekAsimPdfDialogRef,
  recordRef,
  emailDialogRef,
  isSonucuDialogRef,
  analizSonucuAlertDialogRef,
  teklifIptalAlertDialogRef,
  bayiTalebiMerkezeGonderDialogRef,
  bayidenMusteriyeDirekTeklifRecordRef,
}) {
  const context = React.useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  return (
    <>
      <TeklifRecord
        ref={teklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
      />
      <BayiTeklifRecord
        ref={bayiTeklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
      />
      <BayidenMusteriyeDirekTeklifRecord
        ref={bayidenMusteriyeDirekTeklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
      />
      <AlertDialog
        ref={analizSonucuAlertDialogRef}
        dialogTitle="Dikkat!"
        contentText="Analiz sonucu girilmemiş."
        onayBtnText="Analiz sonucu gir"
        redBtnText="Kapat"
        onayBtnClick={() => {
          analizSonucuDialogRef?.current?.openRecord(rowData?.id);
        }}
      />
      <YorumDialog ref={yorumDialogRef} listGridRef={listGridRef} />
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={
          context?.user?.yetkiGrubuTipi ===
            YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && yetki.kaydet
        }
        setRowDataFnc={setRowDataFnc}
      />
      <TemsilciAnaliziDialog
        ref={temsilciAnaliziDialogRef}
        ekranKodu={ekranKodu}
        isEditable={true}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
      />
      <TalepYonlendirmeDialog
        ref={talepYonlendirmeDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        afterSubmitFnc={() => recordRef.current.openRecord(rowData.id)}
      />
      <PdfDialog
        ref={teklifPdfDialogRef}
        title="Teklif"
        url="Talep/TeklifPdf"
      />
      <PdfDialog
        ref={onayliPdfDialogRef}
        title="Onaylı Teklif"
        url="Talep/TeklifOnayliBelge"
      />
      <PdfDialog
        ref={destekAsimPdfDialogRef}
        title="Destek Aşım Detayı"
        url="DestekAsimTahsilatTakip/DestekAsimPdf"
      />
      <IsKapatmaDialog
        ref={isKapatmaDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        setRowDataFnc={setRowDataFnc}
      />
      <BayiTalebiMerkezeGonderDialog
        ref={bayiTalebiMerkezeGonderDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        setRowDataFnc={setRowDataFnc}
      />

      <EMailDialog ref={emailDialogRef} yetki={yetki} rowData={rowData} />
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={yetki.kaydet}
        listGridRef={listGridRef}
      />
      <TeklifIptalDialog
        teklifIptalAlertDialogRef={teklifIptalAlertDialogRef}
        rowData={rowData}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
      />
    </>
  );
}
