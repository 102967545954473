import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";

const TemsilciAnaliziDialog = React.forwardRef(
  ({ ekranKodu, isEditable, setRowDataFnc, listGridRef }, ref) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "Talep/TemsilciAnaliziGetById");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          temsilciAnalizi: res?.data?.data?.temsilciAnalizi,
          talepDurumu: res?.data?.data?.talepDurumu,
        };
      });
    };

    const handleSubmit = useSubmit(
      { updateRoute: "Talep/TemsilciAnaliziUpdate" },
      setRowData,
      listGridRef,
      afterSubmitFnc
    );

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Temsilci Analizi"
        maxWidth="lg"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Field
                  name="temsilciAnalizi"
                  component={RichTextEditor}
                  readOnly={!isEditable}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {isEditable && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit().then(() => {
                        closeRecord();
                      });
                    }}
                  />
                )}
                {isEditable && (
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                )}
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default TemsilciAnaliziDialog;
