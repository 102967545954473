import React from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import useSektorRecord from "../../hooks/use-sektor-record";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const SektorRecord = React.forwardRef(({ ekranKodu, SektorInputRef }, ref) => {
  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  } = useSektorRecord(ref, SektorInputRef, ekranKodu);

  const validationSchema = yup.object().shape({
    sektor: yup.string().required(),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Sektor"
        maxWidth="xs"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <FormComponent onSubmit={handleSubmit}> */}
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <CustomFieldWrapper label="No" modalLoading={modalLoading}>
                    <Field disabled name="id" component={TextInputForm} />
                  </CustomFieldWrapper>
                  <CustomFieldWrapper
                    label="Sektör"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="sektor"
                      component={TextInputForm}
                      // readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                </Stack>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <RecordButton
                  yetki={yetki}
                  yetkisizseAc={true}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
              {/* </FormComponent> */}
            </form>
          )}
        />
      </ScrollDialog>
    </>
  );
});
export default SektorRecord;
