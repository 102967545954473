import Type from "../../entities/Kullanici";

const PageConfig = {
  title: "Kullanıcılar",
  ekranKodu: "Kullanicilar_Res",
  type: Type,
  listRoute: "User/List",
  getByIdRoute: "User/GetById",
  insertRoute: "User/Insert",
  updateRoute: "User/Update",
  deleteRoute: "User/Delete",
};

export default PageConfig;
