import styled from "@emotion/styled";
import { DialogActions, DialogContent } from "@mui/material";
import React from "react";
import useDialog from "../../hooks/use-dialog";
import ScrollDialog from "../dialogs/ScrollDialog";
import PageReport from "./PageReport";

const CustomPdfDialog = styled(ScrollDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    // width: "21cm",
    // height: "29.7cm",
    height: "100vh",
  },
}));

const PdfDialog = React.forwardRef(
  ({ title, url, base64, actionButtons }, ref) => {
    const { open, closeRecord, id, showActionButtons } = useDialog(ref);
    return (
      <CustomPdfDialog
        maxWidth="md"
        open={open}
        handleClose={closeRecord}
        modalTitle={title}
        canFullScreen={false}
      >
        <DialogContent id="dialog-content">
          <PageReport base64={base64} url={url} requestData={{ id: id }} />
        </DialogContent>
        {showActionButtons && <DialogActions>{actionButtons}</DialogActions>}
      </CustomPdfDialog>
    );
  }
);

export default PdfDialog;
