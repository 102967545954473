import { Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

function FilterFieldWrapper({
  modalLoading,
  children,
  label,
  columnDirection,
  ...rest
}) {
  return modalLoading ? (
    <Skeleton width={200}></Skeleton>
  ) : (
    <Grid
      sx={{
        width: 200,
        pr: "4px",
        pl: "4px",
      }}
      {...rest}
    >
      {label ? (
        <Typography sx={{ fontSize: "10pt", fontWeight: "bold" }}>
          {label}
        </Typography>
      ) : null}
      {children}
    </Grid>
  );
}
export default FilterFieldWrapper;
