import Type from "../../entities/YazilimsalTahsilat";

const PageConfig = {
  title: "Yazılımsal Tahsilat Takibi",
  ekranKodu: "Tahsilat_Takip_Res",
  type: Type,
  listRoute: "YazilimsalTahsilatTakip/List",
  bulkUpdateRoute: "YazilimsalTahsilatTakip/BulkUpdate",
};

export default PageConfig;
