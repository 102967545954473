import React from "react";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Unstable_DateField as DateField } from "@mui/x-date-pickers/DateField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";

export default function DateInputForm({
  input,
  meta,
  label,
  defaultValue = "",
  ...rest
}) {
  return (
    <TextField
      {...input}
      sx={{
        width: "100%",
        input: {
          ...(!input?.value ? { color: "grey" } : {}),
        },
      }}
      label={label}
      onChange={(e) => input.onChange(e.target.value)}
      variant="standard"
      type="date"
      value={
        input?.value ? dayjs(input?.value)?.format("YYYY-MM-DD") : defaultValue
      }
      // value={input?.value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ readOnly: rest.readOnly }}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      {...rest}
    />
  );
}
