import Type from "../../entities/Urunler";

const PageConfig = {
  title: "Urunler",
  ekranKodu: "Urunler_Res",
  type: Type,
  listRoute: "Urunler/List",
  getByIdRoute: "Urunler/GetById",
  insertRoute: "Urunler/Insert",
  updateRoute: "Urunler/Update",
  deleteRoute: "Urunler/Delete",
};

const ExcelImportConfig = {
  fileUploadRoute: "Urunler/ExcelImport",
};

export { PageConfig, ExcelImportConfig };
