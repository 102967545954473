import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "teklifTeklifDetayid",
    label: "Teklif Detay No",
    dataType: "number",
    dataField: "teklifTeklifDetayid",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "urunAdi",
    label: "Ürün Adı",
    dataType: "text",
    dataField: "urunAdi",
    visible: ["grid"],
  },
  {
    name: "planTarihi",
    label: "Plan Tarihi",
    dataType: "date",
    dataField: "planTarihi",
    visible: ["grid"],
  },
  {
    name: "gerceklesenTarih",
    label: "Gerçekleşen Tarih",
    dataType: "date",
    dataField: "gerceklesenTarih",
    visible: ["grid"],
  },
  {
    name: "gerceklesti",
    label: "Gerçekleşti",
    dataType: "boolean",
    dataField: "gerceklesti",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
];

export default entity;
