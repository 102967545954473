import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { ctheme } from "../../theme/theme";
import { Grid } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import React from "react";

const BootstrapDialogTitle = React.forwardRef(
  ({ children, onClose, setFullScreen, canFullScreen, ...rest }, ref) => {
    return (
      <DialogTitle
        sx={{
          m: 0,
          p: "2px",
          pl: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "12pt",
          bgcolor: ctheme.colors.lynxWhite,
        }}
        {...rest}
      >
        {children}
        <Grid>
          {canFullScreen && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                setFullScreen((prevState) => !prevState);
              }}
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
            >
              <FullscreenIcon />
            </IconButton>
          )}
          {onClose && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </DialogTitle>
    );
  }
);

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default BootstrapDialogTitle;
