const apiUrl = process.env.REACT_APP_API_URL;

const TALEP_TIPI = [
  { Value: 1, Text: "Yazılımsal Geliştirme" },
  { Value: 2, Text: "Eğitim" },
  { Value: 3, Text: "Hata Talebi" },
];

const TEKLIF_BIRIMI = { TRY: { Value: "TRY", Text: "TRY" } };

const YETKI_GRUBU_TIPI = {
  HARMONY_ADMIN: { Value: 1, Text: "Harmony Admin" },
  HARMONY_DESTEK: { Value: 2, Text: "Harmony Destek" },
  MUSTERI: { Value: 3, Text: "Müşteri" },
  BAYI: { Value: 4, Text: "Bayi" },
  BAYI_DESTEK: { Value: 5, Text: "Bayi Destek" },
};

const TALEP_DURUMU = {
  YENI_TALEP: { Value: 1, Text: "Yeni Talep", Color: "#379237" },
  ANALIZ_EDILIYOR: { Value: 2, Text: "Analiz Ediliyor", Color: "#37E2D5" },
  // CEVAP_BEKLENIYOR: { Value: 10, Text: "Cevap Bekleniyor", Color: "#BA94D1" },
  // TEKLIF_VERILDI: { Value: 3, Text: "Teklif Verildi", Color: "#84D2C5" },
  // TEKLIF_REDDEDILDI: { Value: 4, Text: "Teklif Reddedildi", Color: "#EB596E" },
  // TEKLIF_ONAYLANDI: { Value: 5, Text: "Teklif Onaylandı", Color: "#54E346" },
  YAZILIM_SURECINDE: {
    Value: 6,
    Text: "Yazılım Sürecine Alındı",
    Color: "#61A4BC",
  },
  YAPILMASI_UYGUN_GORULMEDI: {
    Value: 7,
    Text: "Yapılması Uygun Görülmedi",
    Color: "#F58634",
  },
  GUNCELLEME_BEKLIYOR: {
    Value: 8,
    Text: "Güncelleme Bekliyor",
    Color: "#FFCC29",
  },
  PROGRAMA_EKLENDI: { Value: 9, Text: "Programa Eklendi", Color: "#4700D8" },
  FIRMA_TARAFINDAN_KAPATILDI: {
    Value: 11,
    Text: "Firma Tarafından Kapatıldı",
    Color: "#820000",
  },
  // BAYIYE_TEKLIF_VERILDI: {
  //   Value: 12,
  //   Text: "Teklif Bayiye İletildi",
  //   Color: "#84D2C5",
  // },
  TALEP_MERKEZE_ILETILDI: {
    Value: 13,
    Text: "Talep Merkeze İletildi",
    Color: "#84D2C5",
  },
};

const TALEP_TEKLIF_DURUMU = {
  BAYIYE_TEKLIF_VERILDI: {
    Value: 1,
    Text: "Bayiye Teklif Verildi",
    Color: "#379237",
  },
  MUSTERIYE_TEKLIF_VERILDI: {
    Value: 2,
    Text: "Müşteriye Teklif Verildi",
    Color: "#BA94D1",
  },
  MUSTERI_TEKLIFI_REDDETTI: {
    Value: 3,
    Text: "Müşteri Teklifi Reddetti",
    Color: "#EB596E",
  },
  MUSTERI_TEKLIFI_ONAYLADI: {
    Value: 4,
    Text: "Müşteri Teklifi Onayladı",
    Color: "#54E346",
  },
};

const TALEP_DURUMU_FILTER = [
  { Value: 1, Text: "İşleme Alındı" },
  { Value: 2, Text: "Tamamlandı" },
  { Value: 3, Text: "Diğer" },
];

const EKRAN_KODU = {
  ANASAYFA: { value: "AnaSayfa_Res", text: "Anasayfa" },
  //: "BekleyenTalepler_Res",
  KULLANICILAR: { value: "Kullanicilar_Res", text: "Kullanıcılar" },
  YETKILER: { value: "Yetki_Res", text: "Yetkiler" },
  MUSTERI_TALEP: { value: "Musteri_Talep_Res", text: "Müşteri Talep" },
  PROFIL: { value: "Profil_Res", text: "Profil" },
  SIRKET: { value: "Sirket_Res", text: "Şirket" },
  DESTEK: { value: "Destek_Res", text: "Destek" },
  DESTEK_GECMISI: { value: "Destek_Gecmisi_Res", text: "Destek Geçmişi" },
  GOREVLER: { value: "Gorev_Res", text: "Görev" },
  RAPORLAR: { value: "Rapor_Res", text: "Rapor" },
  NOTLAR: { value: "Not_Res", text: "Not" },
  TAHSILAT_TAKIP: { value: "Tahsilat_Takip_Res", text: "Tahsilat Takip" },
  TANIMLAMALAR: { value: "Tanimlamalar_Res", text: "Tanımlamalar" },
  KULLANICI_BILGILERI: {
    value: "KullaniciBilgileri_Res",
    text: "Kullanıcı Bilgileri",
  },
  TOPLANTI: { value: "Toplanti_Res", text: "Toplantı" },
  KONTROL_EDILECEK_ISLER: {
    value: "KontrolEdilecekIsler_Res",
    text: "Kontrol Edilecek İşler",
  },
  ANLASMA_BILGILERI: {
    value: "AnlasmaBilgileri_Res",
    text: "Anlaşma Bilgileri",
  },
  URUNLER: { value: "Urunler_Res", text: "Ürünler" },
  FIYAT_LISTE_MASTER: { value: "FiyatListeMaster_Res", text: "Fiyat Listesi" },
  BAYILER: { value: "Bayiler_Res", text: "Bayiler" },
  POTANSIYELMUSTERI: {
    value: "PotansiyelMusteri_Res",
    text: "Potansiyel Müşteri",
  },
  TEKLIFMASTER: {
    value: "TeklifMaster_Res",
    text: "Satış Teklifi",
  },
  GORUSMELER: {
    value: "Gorusmeler_Res",
    text: "Görüşmeler",
  },
  PROJESURECTAKIP: {
    value: "ProjeSurecTakip_Res",
    text: "Proje Süreç Takip",
  },
  GOREVLER_GANTT: { value: "GorevlerGantt_Res", text: "Görevler Gantt" },
};

const ONCELIK_DURUMU = [
  { Value: 1, Text: "Standart" },
  { Value: 2, Text: "Önemli" },
  { Value: 3, Text: "Çok Önemli" },
  { Value: 4, Text: "Acil" },
];

const ILGILI_MODUL = {
  TEDARIK_ZINCIRI_YONETIMI: { Value: 1, Text: "Tedarik Zinciri Yönetimi" },
  MUHASEBE_FINANS_YONETIMI: { Value: 2, Text: "Muhasebe Finans Yönetimi" },
  INSAN_KAYNAKLARI_YONETIMI: { Value: 3, Text: "İnsan Kaynakları Yönetimi" },
  DIGER: { Value: 4, Text: "Diğer" },
};

const ONAY_RED_DURUMU = {
  BELIRTILMEDI: { Value: 0, Text: "Belirtilmedi" },
  ONAY: { Value: 1, Text: "Onay" },
  RED: { Value: 2, Text: "Red" },
};

const AY = [
  { Value: 1, Text: "Ocak" },
  { Value: 2, Text: "Şubat" },
  { Value: 3, Text: "Mart" },
  { Value: 4, Text: "Nisan" },
  { Value: 5, Text: "Mayıs" },
  { Value: 6, Text: "Haziran" },
  { Value: 7, Text: "Temmuz" },
  { Value: 8, Text: "Ağustos" },
  { Value: 9, Text: "Eylül" },
  { Value: 10, Text: "Ekim" },
  { Value: 11, Text: "Kasım" },
  { Value: 12, Text: "Aralık" },
];

const ISLEM_TURU = {
  GENEL_YAZILIM_OPERASYONU: {
    Value: 1,
    Text: "Genel Yazılım Operasyonu",
  },
  ANALIZ_VE_TEST: { Value: 2, Text: "Analiz ve Test" },
  KURULUM: { Value: 3, Text: "Kurulum" },
  EGITIM: { Value: 4, Text: "Eğitim" },
  PARTI_PROGRAMA_VERILEN_DESTEKLER: {
    Value: 5,
    Text: "3. Parti Programa Verilen Destekler",
  },
  DANISMANLIK: { Value: 6, Text: "Danışmanlık" },
  INCELEME: { Value: 7, Text: "İnceleme" },
  HATA_DUZELTME: { Value: 9, Text: "Hata Düzeltme" },
};

const DESTEK_ISLEM_TURU = {
  PARTI_PROGRAMA_VERILEN_DESTEKLER: {
    Value: 1,
    Text: "3. Parti Programa Verilen Destekler",
  },
  ANALIZ_VE_TEST: { Value: 2, Text: "Analiz ve Test" },
  EGITIM: { Value: 3, Text: "Uzaktan Eğitim" },
  FIRMA_ZIYARETI: { Value: 4, Text: "Firma Ziyareti" },
  GENEL_DESTEK: { Value: 5, Text: "Genel Destek" },
  GUNCELLEME: { Value: 6, Text: "Güncelleme" },
  KURULUM: { Value: 7, Text: "Kurulum" },
  YAZILIMSAL_DESTEK: { Value: 8, Text: "Yazılımsal Destek" },
  YAZILIMSAL_HATA: { Value: 9, Text: "Yazılımsal Hata" },
  INCELEME: { Value: 10, Text: "İnceleme" },
};

const RAPOR_KATEGORI = {
  TALEP: { Value: 1, Text: "Talep" },
  DESTEK: { Value: 2, Text: "Destek" },
};

const ISIN_DURUMU = {
  YENI_IS: { Value: 1, Text: "Yeni İş" },
  UZERINDE_CALISILIYOR: { Value: 2, Text: "Üzerinde Çalışılıyor" },
  YONLENDIRILDI: { Value: 3, Text: "Yönlendirildi" },
  ARA_VERILDI: { Value: 4, Text: "Ara verildi" },
  TAMAMLANDI: { Value: 5, Text: "Tamamlandı" },
};

const APP_BAR_HEIGHT = "64px";
const FILTER_HEIGHT = "200px";
const TAB_HEIGHT = "48px";

const DATE_FORMAT = "dd.MM.yyyy";
const LOADING_DIV_ID = "preloader";

const TUTAR_FORMAT = "0##,###.00";
const DRAWER_WIDTH = 240;

const ISIN_DURUMU_FILTER = {
  TAMAMLANDI: { Value: 1, Text: "Tamamlandı" },
  DEVAM_EDIYOR: { Value: 2, Text: "Devam Ediyor" },
  GELISTIRME_YAPILMAYACAK: { Value: 3, Text: "Geliştirme Yapılmayacak" },
};

const TAHSILAT_DURUMU = {
  TAHSILAT_YAPILMADI: { Value: false, Text: "Tahsilat Yapılmadı" },
  TAHSILAT_YAPILDI: { Value: true, Text: "Tahsilat Yapıldı" },
};

const PLAN_TIP = {
  TOPLANTI: { Value: 1, Text: "Toplantı" },
};

const PLAN_DURUM = {
  PLANLANDI: { Value: 1, Text: "Planlandı" },
  GERCEKLESTI: { Value: 2, Text: "Gerçekleşti" },
  BEKLEMEDE: { Value: 3, Text: "Beklemede" },
  IPTAL_EDILDI: { Value: 4, Text: "İptal Edildi" },
};

const URUN_TIP = {
  MODUL: { Value: 1, Text: "Ürün" },
  PAKET: { Value: 2, Text: "Paket" },
  BAGIMSIZ: { Value: 3, Text: "Bağımsız" },
};

const URUN_TIP_MODULPAKET = {
  MODUL: { Value: 1, Text: "Ürün" },
  PAKET: { Value: 2, Text: "Paket" },
};

const AKTIF_PASIF = {
  AKTIF: { Value: true, Text: "Aktif" },
  PASIF: { Value: false, Text: "Pasif" },
};

const PARA_BIRIMI = {
  TL: { Value: "TL", Text: "TL" },
  USD: { Value: "USD", Text: "USD" },
  EUR: { Value: "EUR", Text: "EUR" },
};

const TEKLIF_DURUMU = {
  DUZENLENIYOR: { Value: 1, Text: "Düzenleniyor" },
  ONAYLANDI: { Value: 2, Text: "Onaylandı" },
  BEKLEMEDE: { Value: 3, Text: "Beklemede" },
  REDDEDILDI: { Value: 4, Text: "Reddedildi" },
};

const CARI_TIPI = {
  MUSTERI: { Value: 1, Text: "Müşteri" },
  POTMUSTERI: { Value: 2, Text: "Potansiyel Müşteri" },
};

const GORUSME_TIPI = {
  TELEFON: { Value: 1, Text: "Telefon Görüşmesi" },
  FIRMA_ZIYARETI: { Value: 2, Text: "Firma Ziyareti" },
  ONLINE_TOPLANTI: { Value: 3, Text: "Online Toplantı" },
  KONFERANS: { Value: 4, Text: "Konferans" },
  FUAR: { Value: 5, Text: "Fuar" },
};

const GORUSME_DURUMU = {
  ILK_GORUSME: { Value: 1, Text: "İlk Görüşme" },
  DEMO_SURECI: { Value: 2, Text: "Demo Sürecinde" },
  TEKLIF_SURECINDE: { Value: 3, Text: "Tekliflendirme Sürecinde" },
  // ON_GORUSME: { Value: 3, Text: "Ön Görüşme" },
  SATIS_GERCEKLESTI: { Value: 4, Text: "Satış Gerçekleşti" },
  OLUMSUZ_SONUCLANDI: { Value: 5, Text: "Olumsuz Sonuçlandı" },
};

const BAYI_TALEP_DURUMU = {
  ONAYLANDI: { Value: 2, Text: "Onaylandı" },
  REDDEDILDI: { Value: 3, Text: "Reddedildi" },
};

const TAHSILAT_DURUMU_INT = {
  TAHSILAT_YAPILDI: { Value: 1, Text: "Tahsil Edildi" },
  TAHSILAT_YAPILMADI: { Value: 2, Text: "Tahsil Edilmedi" },
  YAR_TAHSILAT_YAPILDI: { Value: 3, Text: "Yarım Tahsil Edildi" },
};

const GORUSME_KAYNAK = {
  WEB_FORMU: { Value: 1, Text: "Web Formu" },
  GELEN_ARAMA: { Value: 2, Text: "Gelen arama" },
  GELEN_EMAIL: { Value: 3, Text: "Gelen e-mail" },
  GIDEN_ARAMA: { Value: 4, Text: "Giden arama" },
  GIDEN_EMAIL: { Value: 5, Text: "Giden e-mail" },
  REFERANS: { Value: 6, Text: "Referans" },
  MERKEZ_CALISAN: { Value: 7, Text: "Merkez Çalışan" },
  BAYI: { Value: 8, Text: "Bayi/Distributor" },
};

const CALISAN_SAY_ARALIGI = {
  CAL1: { Value: 1, Text: "1-10 çalışan" },
  CAL2: { Value: 2, Text: "11-50 çalışan" },
  CAL3: { Value: 3, Text: "51-200 çalışan" },
  CAL4: { Value: 4, Text: "201-500 çalışan" },
  CAL5: { Value: 5, Text: "501-1.000 çalışan" },
  CAL6: { Value: 6, Text: "1.001-5.000 çalışan" },
  CAL7: { Value: 7, Text: "5.001-10.000 çalışan" },
  CAL8: { Value: 8, Text: "10.001+ çalışan" },
};

export {
  apiUrl,
  TALEP_TIPI,
  TALEP_DURUMU,
  EKRAN_KODU,
  ONCELIK_DURUMU,
  ONAY_RED_DURUMU,
  APP_BAR_HEIGHT,
  FILTER_HEIGHT,
  DATE_FORMAT,
  LOADING_DIV_ID,
  TALEP_DURUMU_FILTER,
  YETKI_GRUBU_TIPI,
  TEKLIF_BIRIMI,
  TUTAR_FORMAT,
  AY,
  ISLEM_TURU,
  RAPOR_KATEGORI,
  ISIN_DURUMU,
  DRAWER_WIDTH,
  ISIN_DURUMU_FILTER,
  ILGILI_MODUL,
  TAHSILAT_DURUMU,
  TAB_HEIGHT,
  PLAN_TIP,
  PLAN_DURUM,
  DESTEK_ISLEM_TURU,
  URUN_TIP,
  AKTIF_PASIF,
  PARA_BIRIMI,
  TEKLIF_DURUMU,
  CARI_TIPI,
  GORUSME_TIPI,
  GORUSME_DURUMU,
  BAYI_TALEP_DURUMU,
  URUN_TIP_MODULPAKET,
  TAHSILAT_DURUMU_INT,
  TALEP_TEKLIF_DURUMU,
  GORUSME_KAYNAK,
  CALISAN_SAY_ARALIGI,
};
