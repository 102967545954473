import React from "react";
import List from "@mui/material/List";
import { routesArr } from "../../router/router";
import { AppContext } from "../../common/AppContext";
import DrawerListItem from "./DrawerListItem";

export default function DrawerList() {
  const context = React.useContext(AppContext);

  return (
    <List>
      {routesArr
        .filter((x) => {
          return (
            x.showInSidebar &&
            context?.user?.yetkiler?.find(
              (y) => y.ekranKodu === x.ekranKodu && y.goruntule
            )
          );
        })
        .map((route, index) => (
          <DrawerListItem route={route} key={index} />
        ))}
    </List>
  );
}
