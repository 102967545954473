import { Close, Comment, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useContext, useRef } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import TalepDetayiDialog from "../Gorev/TalepDetayiDialog";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import useSubmit from "../../hooks/use-submit";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import { FileConfig } from "../Musteri-talep/MusteriTalepConfig";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import TemsilciAnaliziDialog from "./TemsilciAnaliziDialog";
import YorumDialog from "./YorumDialog";
import YorumlariGoruntuleButton from "../../components/buttons/custom/YorumlariGoruntuleButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import HomeRecord from "./HomeRecord";
import { EKRAN_KODU, YETKI_GRUBU_TIPI } from "../../common/constants";
import TalepGoruntuleButton from "../../components/buttons/custom/TalepGoruntuleButton";
import { AppContext } from "../../common/AppContext";
import DeleteDialog from "../../components/dialogs/custom/DeleteDialog";
import DeleteDescriptionDialog from "../Gorev/DeleteDescriptionDialog";

const AnalizSonucuDialog = React.forwardRef(
  (
    {
      ekranKodu,
      isEditable,
      setRowDataFnc,
      buttonVisible = false,
      listGridRef,
    },
    ref
  ) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(
        ref,
        buttonVisible
          ? "Talep/AnalizSonucuGetByIdForGorevPage"
          : "Talep/AnalizSonucuGetById"
      );

    const afterSubmitFnc = (res) => {
      setRowData(res || {});
      setRowDataFnc &&
        setRowDataFnc((prevState) => ({
          ...prevState,
          analizSonucu: res.analizSonucu,
        }));
    };

    const handleSubmit = useSubmit(
      { updateRoute: "Talep/AnalizSonucuUpdate" },
      afterSubmitFnc
    );

    const talepDetayiDialogRef = useRef();
    const temsilciAnaliziDialogRef = useRef();
    const yorumDialogRef = useRef();
    const talepRecordRef = useRef();
    const context = useContext(AppContext);
    const deleteDialogRef = useRef();
    const deleteDescriptionDialogRef = useRef();

    const handleDelete = () => {
      deleteDescriptionDialogRef?.current?.openRecord();
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Analiz Sonucu Yapılacak Geliştirmeler"
          maxWidth="lg"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            //validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Field
                    name="analizSonucu"
                    component={RichTextEditor}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>
                <DialogActionsWrapper
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    {buttonVisible && (
                      <>
                        <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            temsilciAnaliziDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          color="grape"
                          //disabled={!rowData.yonlendirenAciklama}
                        >
                          Temsilci Analizi
                        </CustomColoredButton>
                        {/* <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            yonlendirenAciklamaDialogRef?.current?.openRecord(
                              talepDetayId
                            );
                          }}
                          color="grape"
                          //disabled={!rowData.yonlendirenAciklama}
                        >
                          Yönlendiren Görevli Açıklaması
                        </CustomColoredButton> */}
                        <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            talepDetayiDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          color="purple"
                          disabled={!rowData.talepDetayiVarMi}
                        >
                          Müşteri Talep Açıklama
                        </CustomColoredButton>
                        <EkDosyaButton
                          reqData={{
                            id: rowData.id,
                          }}
                          routes={FileConfig}
                          ekranKodu={ekranKodu}
                          parentRowData={rowData}
                          disabled={!rowData?.id}
                        ></EkDosyaButton>
                        <YorumlariGoruntuleButton
                          onClick={(e) => {
                            yorumDialogRef?.current?.openRecord(rowData.id);
                          }}
                        />
                        {context.user.yetkiGrubuTipi ===
                          (YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
                            YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value) && (
                          <>
                            <TalepGoruntuleButton
                              onClick={(e) => {
                                talepRecordRef?.current?.openRecord(rowData.id);
                              }}
                              label="Asıl Kaydı Aç"
                            />
                            <CustomColoredButton
                              startIcon={<Delete />}
                              onClick={(e) => {
                                deleteDialogRef?.current?.openRecord();
                              }}
                              color="red"
                            >
                              Görevi Sil
                            </CustomColoredButton>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid>
                    {isEditable && (
                      <SaveButton
                        onClick={(e) => {
                          form.submit();
                        }}
                      />
                    )}
                    {isEditable && (
                      <CancelButton
                        onClick={() => {
                          form.reset();
                        }}
                        disabled={submitting || pristine}
                      />
                    )}
                  </Grid>
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        {buttonVisible && (
          <>
            <TalepDetayiDialog ref={talepDetayiDialogRef}></TalepDetayiDialog>
            <TemsilciAnaliziDialog
              ref={temsilciAnaliziDialogRef}
              ekranKodu={ekranKodu}
              isEditable={false}
            ></TemsilciAnaliziDialog>
            <YorumDialog ref={yorumDialogRef} isIsYorum={true}></YorumDialog>
            <HomeRecord
              ref={talepRecordRef}
              ekranKodu={EKRAN_KODU.ANASAYFA.value}
            ></HomeRecord>
            <DeleteDialog
              alertDialogRef={deleteDialogRef}
              onayBtnClick={() => {
                handleDelete();
              }}
              title={"Talebe bağlı görevler siliniyor!"}
            />
            <DeleteDescriptionDialog
              ref={deleteDescriptionDialogRef}
              ekranKodu={ekranKodu}
              talepId={rowData.id}
              listGridRef={listGridRef}
              masterDialogClose={closeRecord}
            ></DeleteDescriptionDialog>
          </>
        )}
      </>
    );
  }
);

export default AnalizSonucuDialog;
