import { Button } from "@mui/material";
import React from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function DestekSuresiDurumuButton({ onClick }) {
  return (
    <Button
      sx={{
        p: "1px 7px",
        fontSize: "10pt",
        textTransform: "unset",
      }}
      color="info"
      variant="contained"
      startIcon={<SupportAgentIcon />}
      size="small"
      onClick={onClick}
    >
      Destek Süresi Durumu
    </Button>
  );
}
