import React from "react";
import AlertDialog from "../AlertDialog";

export default function DeleteDialog({
  alertDialogRef,
  onayBtnClick,
  title,
  content,
}) {
  return (
    <AlertDialog
      dialogTitle={title ?? "Dikkat Kayıt Siliniyor!"}
      contentText={content ?? "Silmek istediğinize emin misiniz?"}
      ref={alertDialogRef}
      onayBtnClick={onayBtnClick}
    ></AlertDialog>
  );
}
