const DATE_WIDTH = "115px";
const ID_WIDTH = "70px";
const ILGILI_MODUL_WIDTH = "200px";
const ANALIZ_SONUCU_WIDTH = "300px";
const IS_SONUCU_WIDTH = "300px";
const BASLIK_WIDTH = "200px";
const ONCELIK_DURUMU_WIDTH = "110px";
const OKUNMAYAN_YORUM_WIDTH = "135px";
const KDV_WIDTH = "130px";
const ACIKLAMA_WIDTH = "250px";
const LONG_DATE_WIDTH = "150px";

const FILTER_TYPE = {
  FAST_FILTER: 1,
};

export {
  DATE_WIDTH,
  ID_WIDTH,
  ILGILI_MODUL_WIDTH,
  ANALIZ_SONUCU_WIDTH,
  IS_SONUCU_WIDTH,
  BASLIK_WIDTH,
  ONCELIK_DURUMU_WIDTH,
  OKUNMAYAN_YORUM_WIDTH,
  KDV_WIDTH,
  ACIKLAMA_WIDTH,
  LONG_DATE_WIDTH,
  FILTER_TYPE,
};
