import React, { useRef } from "react";
import PersonelRecord from "../../../pages/Personel/PersonelRecord";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function PersonelInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  sirketInputRef,
  onChangeExtra,
  ...rest
}) {
  const personelDialogRef = useRef();

  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        insertButtonRef={personelDialogRef}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "Personel/ListForSelect",
          fildsForText: ["adSoyad"],
          fieldForValue: "id",
          reqData: {
            ...(sirketInputRef?.current
              ? {
                  sirketId:
                    sirketInputRef?.current?.value === ""
                      ? rowData.sirketId
                      : sirketInputRef?.current?.value,
                }
              : { getSirket_KodFromSession: true }),
          },
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
      <PersonelRecord
        ref={personelDialogRef}
        ekranKodu={ekranKodu}
        personelInputRef={inputRef}
        sirketInputRef={sirketInputRef}
      ></PersonelRecord>
    </>
  );
}
