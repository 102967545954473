import { YETKI_GRUBU_TIPI } from "../common/constants";
import { FILTER_TYPE, ID_WIDTH } from "../common/entityconstants";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "sirket_Kod",
    label: "Şirket Kodu",
    dataType: "text",
    dataField: "sirket_Kod",
    visible: ["grid"],
  },
  {
    name: "temsilciIdStr",
    label: "Temsilci Adı",
    dataType: "text",
    dataField: "temsilciIdStr",
    visible: ["grid"],
  },
  // {
  //   name: "logo",
  //   label: "Logo",
  //   dataType: "text",
  //   dataField: "logo",
  //   visible: ["grid"],
  //   cellRender: (e) => {
  //     return <Avatar alt="Logo" src={`data:image/jpeg;base64,${e?.value}`} />;
  //   },
  // },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
    firstSort: "asc",
  },
  {
    name: "bayiKod",
    label: "Bayi Kodu",
    dataType: "text",
    dataField: "bayiKod",
    visible: ["grid"],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filter
  {
    name: "searchAll",
    label: "Şirket",
    dataType: "text",
    dataField: "searchAll",
    visible: ["filter"],
    filterType: FILTER_TYPE.FAST_FILTER,
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
