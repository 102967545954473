import { useMemo } from "react";
import { HttpAuthorized } from "../common/common";
import { printToast } from "../common/constant-functions/printToast";
import { apiUrl } from "../common/constants";
import { loadingVisible } from "../common/constant-functions/loadingVisible";

function useSubmit(PageConfig, setRowData, listGridRef, afterSubmitFnc) {
  async function handleSubmit(values) {
    loadingVisible(true);
    await HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${
        values.id ? PageConfig.updateRoute : PageConfig.insertRoute
      }`,
      data: {
        ...values,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData && setRowData(res.data.data);
        listGridRef && listGridRef?.current?.instance?.refresh();
        afterSubmitFnc && afterSubmitFnc(res);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  return useMemo(() => handleSubmit, []);
}

export default useSubmit;
