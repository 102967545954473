import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function BayilerInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  urunTipiRef,
  onChangeExtra,
  ...rest
}) {
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "Bayiler/BayilerListForSelect",
          fildsForText: ["bayiKod"],
          fieldForValue: "id",
          fieldLabel: "bayiKod",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
