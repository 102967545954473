import Type from "../../entities/Bayiler";
import { EKRAN_KODU } from "../../common/constants";

const PageConfig = {
  title: "Bayiler",
  ekranKodu: "Bayiler_Res",
  type: Type,
  listRoute: "Bayiler/List",
  getByIdRoute: "Bayiler/GetById",
  insertRoute: "Bayiler/Insert",
  updateRoute: "Bayiler/Update",
  deleteRoute: "Bayiler/Delete",
};

const FileConfig = {
  allFilesDownloadRoute: "Bayiler/DosyalariIndir",
  fileDownloadRoute: "Bayiler/DosyaIndir",
  filesInfoRoute: "Bayiler/DosyaBilgileri",
  fileUploadRoute: "Bayiler/DosyaYukle",
  deleteFilesRoute: "Bayiler/DeleteFiles",
};

const BayiAnlasmaBilgileriConfig = {
  ekranKodu: EKRAN_KODU.ANLASMA_BILGILERI.value,
  getByIdRoute: "Bayiler/AnlasmaBilgileriGetById",
  updateRoute: "Bayiler/AnlasmaBilgileriUpdate",
  contractUploadRoute: "Bayiler/ContractUpload",
  contractGetByIdRoute: "Bayiler/ContractGetById",
};

export { PageConfig, FileConfig, BayiAnlasmaBilgileriConfig };
