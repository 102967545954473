import { Button } from "@mui/material";
import React from "react";

export default function SmallButton({
  children,
  startIcon,
  onClick,
  color,
  ...rest
}) {
  return (
    <Button
      sx={{
        p: "1px 7px",
        fontSize: "10pt",
        marginY: 0.2,
        marginX: 0.2,
      }}
      color={color}
      variant="contained"
      startIcon={startIcon}
      size="small"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}
