import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function TalepUstTalepInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  onChangeExtra,
  ...rest
}) {
  const idValue = rowData?.talepId;
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "TalepYonlendirme/TalepListForUstTalepSelect",
          fildsForText: ["talep"],
          fieldForValue: "talepid",
          reqData: {
            ...{
              id: idValue,
            },
          },

          // fieldLabel: "ulkeAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
