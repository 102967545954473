import { Paper } from "@mui/material";
import React, { useState } from "react";
import Draggable from "react-draggable";

export default function DraggablePaper(props) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  return (
    <Draggable
      disabled={props.fullScreen}
      onStop={(e, d) => {
        if (!props.fullScreen) {
          setPosition({
            x:
              d.lastX <
              (-1 * (window.innerWidth - d.node.offsetWidth)) / 2 -
                d.node.offsetWidth +
                50
                ? (-1 * (window.innerWidth - d.node.offsetWidth)) / 2 -
                  d.node.offsetWidth +
                  50
                : d.lastX >
                  (window.innerWidth - d.node.offsetWidth) / 2 / 2 +
                    d.node.offsetWidth -
                    50
                ? (window.innerWidth - d.node.offsetWidth) / 2 +
                  d.node.offsetWidth -
                  50
                : d.lastX,
            y:
              d.lastY < (-1 * (window.innerHeight - d.node.offsetHeight)) / 2
                ? (-1 * (window.innerHeight - d.node.offsetHeight)) / 2
                : d.lastY >
                  (window.innerHeight - d.node.offsetHeight) / 2 / 2 +
                    d.node.offsetHeight -
                    50
                ? (window.innerHeight - d.node.offsetHeight) / 2 +
                  d.node.offsetHeight -
                  50
                : d.lastY,
          });
        }
      }}
      position={props.fullScreen ? { x: 0, y: 0 } : position}
      cancel={
        '[id*="dialog-content-wrapper"],#auto-complate-input,[id*="dialog-content"]'
      }
    >
      <Paper {...props} />
    </Draggable>
  );
}
