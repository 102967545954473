import React from "react";
import AlertDialog from "../../components/dialogs/AlertDialog";
import useSubmit from "../../hooks/use-submit";

export default function TeklifIptalDialog({
  teklifIptalAlertDialogRef,
  rowData,
  setRowDataFnc,
  listGridRef,
}) {
  const handleSubmit = useSubmit(
    { updateRoute: "Talep/TeklifIptal" },
    setRowDataFnc,
    listGridRef
  );

  return (
    <AlertDialog
      ref={teklifIptalAlertDialogRef}
      onayBtnClick={() => {
        if (rowData.tahsilEdildi !== true) {
          handleSubmit({ id: rowData.id });
        }
      }}
      dialogTitle={
        rowData.tahsilEdildi
          ? "Teklif İptal Edilemez!"
          : "Teklif İptal Ediliyor!"
      }
      contentText={
        rowData.tahsilEdildi
          ? "Teklif tutarı tahsil edildiği için teklif iptal edilemez!"
          : "Teklifi iptal etmek istediğinize emin misiniz?"
      }
      dialogActionsVisible={rowData.tahsilEdildi === true ? false : true}
    ></AlertDialog>
  );
}
