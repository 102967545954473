import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { HttpAuthorized } from "../../../common/common";
import { apiUrl } from "../../../common/constants";
import { CircularProgress } from "@mui/material";

const MultipleAutocomplateInputForm = React.forwardRef(
  (
    {
      input,
      meta,
      items,
      label,
      remoteDataAdaptor,
      rowData,
      insertButtonRef,
      readOnly,
      clearable = true,
      setCustomChipLabels,
      onlyUpdateBtn = false,
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);

    const changeValue = (newValue) => {
      if (newValue) {
      } else {
        input.onChange(null);
      }
    };

    React.useImperativeHandle(ref, () => ({
      changeValue: changeValue,
      value: input.value || [],
    }));

    const getFieldText = (data) => {
      let label = "";
      remoteDataAdaptor.fildsForText?.forEach((fName, index) => {
        label += data[fName];
        if (index !== remoteDataAdaptor?.fildsForText?.length - 1) {
          label += " - ";
        }
      });

      return {
        Text: label,
        Value: data[remoteDataAdaptor.fieldForValue],
      };
    };

    const setDefaultValue = () => {
      let arr = [];
      if (rowData && rowData[input.name] && remoteDataAdaptor) {
        arr = rowData[input.name].map((data) => ({
          Text: data[remoteDataAdaptor.fildsForText],
          Value: data[remoteDataAdaptor.fieldForValue],
        }));
      }

      return arr;
    };

    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (remoteDataAdaptor) {
        setOptions(setDefaultValue());
      } else {
        setOptions(items);
      }
    }, []);

    function fetchData() {
      setLoading(true);

      if (remoteDataAdaptor.fetchRoute) {
        HttpAuthorized.request({
          method: "POST",
          url: `${apiUrl}${remoteDataAdaptor.fetchRoute}`,
          data: remoteDataAdaptor.reqData,
        })
          .then((res) => {
            setOptions(
              res?.data?.data?.map((data) => getFieldText(data)) ?? []
            );
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => setLoading(false));
      }
    }

    const mapItemToValue = (item) => {
      return {
        [remoteDataAdaptor.fieldForValue]:
          item[remoteDataAdaptor.fieldForValue] || item.Value,
        [remoteDataAdaptor.fildsForText]:
          item[remoteDataAdaptor.fildsForText] || item.Text,
      };
    };

    const handleOnChange = (e, value) => {
      if (remoteDataAdaptor) {
        const mappedValues = value.map(mapItemToValue);
        const uniqueValues = mappedValues.filter((item, index, self) => {
          return (
            index ===
            self.findIndex(
              (t) =>
                t[remoteDataAdaptor.fieldForValue] ===
                item[remoteDataAdaptor.fieldForValue]
            )
          );
        });
        input.onChange(uniqueValues);
      } else {
        let result = [];

        for (let i = 0; i < value.length; i++) {
          let item = value[i];
          if (typeof item === "object" && "Value" in item) {
            let value = item.Value;
            if (!result.includes(value)) {
              result.push(value);
            }
          } else {
            result.push(item);
          }
        }
        input.onChange(result);
      }
    };

    return (
      <div>
        <Autocomplete
          {...input}
          value={input.value || []}
          multiple
          disableCloseOnSelect
          loading={loading}
          loadingText="Yükleniyor..."
          autoComplete={false}
          readOnly={readOnly}
          sx={{ width: "100%" }}
          noOptionsText="Seçenek bulunamadı"
          getOptionLabel={(option) => {
            if (option && option.Text) {
              return option.Text ?? "";
            }
            if (
              option &&
              remoteDataAdaptor &&
              option[remoteDataAdaptor.fildsForText]
            ) {
              return option[remoteDataAdaptor.fildsForText];
            }

            if (!remoteDataAdaptor) {
              if (option === 0 || (option && option !== "")) {
                let opt = options?.find((item) => item.Value === option);
                return opt?.Text ?? "";
              }
            }

            return option ?? "";
          }}
          options={options}
          onChange={handleOnChange}
          isOptionEqualToValue={(option, value) => {
            return option.Value === value[remoteDataAdaptor.fieldForValue];
          }}
          onOpen={() => remoteDataAdaptor && fetchData()}
          renderInput={(params) => (
            <TextField
              {...params}
              readOnly={readOnly}
              sx={{ width: "100%" }}
              label={label}
              variant="standard"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...rest}
        />
      </div>
    );
  }
);

export default MultipleAutocomplateInputForm;
