import React from "react";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useBasicDialog from "../../hooks/use-basic-dialog";
import HizmetForm from "./HizmetForm";

const HizmetFormDialog = React.forwardRef(
  ({ ekranKodu, parentRowData }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Hizmet Formları"
        maxWidth="lg"
        fullWidth={true}
      >
        <HizmetForm
          ekranKodu={ekranKodu}
          parentRowData={{ ...parentRowData, id: parentRowData.sirketId }}
        ></HizmetForm>
      </ScrollDialog>
    );
  }
);

export default HizmetFormDialog;
