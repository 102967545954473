import { ID_WIDTH } from "../common/entityconstants";
import { ekranAdiGetir } from "../common/constant-functions/ekranAdiGetir";
import { EKRAN_KODU } from "../common/constants";

const entity = [
  {
    name: "id",
    label: "Yetki Ekranı No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "asc",
    width: ID_WIDTH,
    allowEditing: false,
  },
  {
    name: "ekranKodu",
    label: "Ekran Kodu",
    dataType: "text",
    dataField: "ekranKodu",
    visible: ["grid"],
    allowEditing: false,
  },
  {
    name: "ekranKodu2",
    label: "Ekran Adı",
    dataType: "text",
    dataField: "ekranKodu",
    visible: ["grid"],
    cellRender: ({ value }) => (value = ekranAdiGetir(value)),
    allowEditing: false,
  },
  {
    name: "yonlendir",
    label: "Yönlendir",
    dataType: "boolean",
    dataField: "yonlendir",
    visible: ["grid"],
  },
  {
    name: "teklifGoruntule",
    label: "Teklif Görüntüle",
    dataType: "boolean",
    dataField: "teklifGoruntule",
    visible: ["grid"],
  },
  {
    name: "goruntule",
    label: "Görüntüle",
    dataType: "boolean",
    dataField: "goruntule",
    visible: ["grid"],
  },
  {
    name: "kaydet",
    label: "Kaydet",
    dataType: "boolean",
    dataField: "kaydet",
    visible: ["grid"],
  },
  {
    name: "sil",
    label: "Sil",
    dataType: "boolean",
    dataField: "sil",
    visible: ["grid"],
  },
];

export default entity;
