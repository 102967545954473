import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import ReferansVerenRecord from "../../../pages/ReferansVeren/ReferansVerenRecord";

export default function ReferansVerenInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  sirketInputRef,
  reqData,
  ...rest
}) {
  const referansVerenDialogRef = useRef();
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        insertButtonRef={referansVerenDialogRef}
        remoteDataAdaptor={{
          fetchRoute: "ReferansVeren/ReferansVerenListForSelect",
          fildsForText: ["referansVeren"],
          fieldForValue: "id",
          reqData: {},
        }}
        ref={inputRef}
        input={input}
        {...rest}
      />
      <ReferansVerenRecord
        ref={referansVerenDialogRef}
        ekranKodu={ekranKodu}
        referansVerenInputRef={inputRef}
      ></ReferansVerenRecord>
    </>
  );
}
