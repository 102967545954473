const PageConfig = {
  title: "GorevlerGantt",
  ekranKodu: "GorevlerGantt_Res",
  //   type: Type,
  ganttListRoute: "GorevlerGantt/GetGorevler",
  // getByIdRoute: "FiyatListeMaster/GetById",
  // insertRoute: "FiyatListeMaster/Insert",
  // updateRoute: "FiyatListeMaster/Update",
  // deleteRoute: "FiyatListeMaster/Delete",
};

export { PageConfig };
