import {
  ILGILI_MODUL,
  ISIN_DURUMU,
  ONCELIK_DURUMU,
  RAPOR_KATEGORI,
  TALEP_TIPI,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import KullaniciInputForm from "../components/inputs/custom-form-inputs/KullaniciInputForm";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import {
  ID_WIDTH,
  LONG_DATE_WIDTH,
  OKUNMAYAN_YORUM_WIDTH,
} from "../common/entityconstants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";
import ScrollableCell from "../components/grid/ScrollableCell";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: [],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "talepId",
    label: "Talep No",
    dataType: "number",
    dataField: "talepId",
    visible: ["grid"],
    width: ID_WIDTH,
  },
  {
    name: "okunmayanYorumSayisi",
    label: "Okunmayan Yorum",
    dataType: "number",
    dataField: "okunmayanYorumSayisi",
    visible: ["grid"],
    // cellRender: (e) => <MailBadge badgeContent={e?.value} />,
    alignment: "center",
    allowExporting: false,
    width: OKUNMAYAN_YORUM_WIDTH,
  },
  //todo talep analizi
  {
    name: "sirketAdi",
    label: "Talep Eden Firma",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "ilgiliModul",
    label: "İlgili Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["grid"],
    selectItems: Object.values(ILGILI_MODUL),
  },
  {
    name: "analizSonucu",
    label: "Talep Açıklama",
    dataType: "text",
    dataField: "analizSonucu",
    visible: ["grid"],
    // customizeText: (e) => e?.value && parseHtmlString(e?.value),
    cellRender: (e) => e?.value && ScrollableCell(parseHtmlString(e?.value)),
    width: "300px",
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
    firstSort: "asc",
    width: LONG_DATE_WIDTH,
    nullsLast: true,
  },
  {
    name: "terminTarihi",
    label: "Termin Tarihi",
    dataType: "date",
    dataField: "terminTarihi",
    visible: ["grid"],
    firstSort: "asc",
    width: LONG_DATE_WIDTH,
    nullsLast: true,
  },
  {
    name: "talepTipi",
    label: "Talep Tipi",
    dataType: "select",
    dataField: "talepTipi",
    visible: ["grid"],
    selectItems: TALEP_TIPI,
  },
  {
    name: "raporKategori",
    label: "Rapor Kategori",
    dataType: "select",
    dataField: "raporKategori",
    visible: ["grid"],
    selectItems: Object.values(RAPOR_KATEGORI),
  },
  {
    name: "oncelik",
    label: "Öncelik Durumu",
    dataType: "select",
    dataField: "oncelik",
    visible: ["grid"],
    selectItems: ONCELIK_DURUMU,
  },
  {
    name: "temsilciIdStr",
    label: "Firma Temsilcisi",
    dataType: "text",
    dataField: "temsilciIdStr",
    visible: ["grid"],
  },
  {
    name: "verilisTarihi",
    label: "Veriliş Tarihi",
    dataType: "date",
    dataField: "verilisTarihi",
    visible: ["grid"],
    firstSort: "asc",
    width: LONG_DATE_WIDTH,
    nullsLast: true,
  },
  {
    name: "hedefSure",
    label: "Hedef Süre (Dakika)",
    dataType: "number",
    dataField: "hedefSure",
    visible: ["grid"],
  },
  {
    name: "isinDurumu",
    label: "İşin Durumu",
    dataType: "select",
    dataField: "isinDurumu",
    visible: ["grid"],
    selectItems: Object.values(ISIN_DURUMU),
  },
  {
    name: "yonlendirenIdStr",
    label: "Yönlendiren",
    dataType: "text",
    dataField: "yonlendirenIdStr",
    visible: ["grid"],
  },
  {
    name: "yonlendirenAciklama",
    label: "Yönlendiren'in Açıklaması",
    dataType: "text",
    dataField: "yonlendirenAciklama",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "sorumlular",
    label: "Sorumlular",
    dataType: "text",
    dataField: "sorumlular",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
    // cellTemplate: (cellData) => sorumluJoin(cellData?.value),
    allowSorting: false,
  },
  {
    name: "ustid",
    label: "Üst Görevi",
    dataType: "text",
    dataField: "ustid",
    visible: ["grid"],
  },
  //todo
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "sorumluId",
    label: "Sorumlu",
    dataType: "custom",
    dataField: "sorumluId",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
    customComponentParams: {},
    onlyYetkiGrubuTipi: YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
  },
  {
    name: "TalepId",
    label: "Talep No",
    dataType: "number",
    dataField: "TalepId",
    visible: ["filter"],
  },
  {
    name: "ilgiliModul",
    label: "Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["filter"],
    items: Object.values(ILGILI_MODUL),
  },
  {
    name: "raporKategori",
    label: "Rapor Kategori",
    dataType: "select",
    dataField: "raporKategori",
    visible: ["filter"],
    items: Object.values(RAPOR_KATEGORI),
  },
  {
    name: "sirketId",
    label: "Talep Eden Firma",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
  {
    name: "yonlendirenId",
    label: "Yönlendiren Kullanıcı",
    dataType: "custom",
    dataField: "yonlendirenId",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
  },
  {
    name: "analizSonucu",
    label: "Talep Açıklama",
    dataType: "text",
    dataField: "analizSonucu",
    visible: ["filter"],
  },
];

export default entity;
