import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import MainTab from "../../components/tab/MainTab";
import YazilimsalDestekPageConfig from "./YazilimsalDestekConfig";
import GenelDestekPageConfig from "./GenelDestekConfig";
import DestekSuresiDialog from "./DestekSuresiDialog";
import { AppContext } from "../../common/AppContext";
import DestekSuresiDurumuButton from "../../components/buttons/custom/DestekSuresiDurumuButton";

export default function DestekGecmisi({ ekranKodu }) {
  const yazilimsalistGridRef = useRef();
  const genelListGridRef = useRef();
  const filterRef = useRef();

  //const recordRef = useRef();
  const destekSuresiDialogRef = useRef();
  const context = React.useContext(AppContext);

  function DestekSuresiDurumuBtn() {
    return (
      <DestekSuresiDurumuButton
        onClick={() => {
          destekSuresiDialogRef?.current?.openRecord(context.user.sirketId);
        }}
      />
    );
  }
  return (
    <>
      <MainTab labels={["Genel Destek", "Yazılımsal Geliştirme"]}>
        {/* geneldesteksekmesi */}
        <>
          <PageFilter
            PageConfig={GenelDestekPageConfig}
            ref={filterRef}
            listGridRef={genelListGridRef}
          />
          <PageGrid
            gridId="DestekGecmisi"
            PageConfig={GenelDestekPageConfig}
            listGridRef={genelListGridRef}
            filterRef={filterRef}
            // recordRef={recordRef}
            isCreatable={false}
            ekranKodu={ekranKodu}
            columnBtnVisible={false}
            CustomGridHeaderButton={DestekSuresiDurumuBtn}
            hasTab={true}
          />
        </>

        {/* yazilimsaldesteksekmesi */}
        <PageGrid
          PageConfig={YazilimsalDestekPageConfig}
          listGridRef={yazilimsalistGridRef}
          // filterRef={filterRef}
          // recordRef={recordRef}
          isCreatable={false}
          ekranKodu={ekranKodu}
          columnBtnVisible={false}
        />
      </MainTab>
      <DestekSuresiDialog
        ref={destekSuresiDialogRef}
        filterRef={filterRef}
      ></DestekSuresiDialog>
    </>
  );
}
