import { Grid } from "@mui/material";
// import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppContextProvider } from "./common/AppContext";
import Router from "./components/route/Router";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./components/loading/Spinner";
import "./App.css";
import { locale } from "devextreme/localization";

locale("tr");

function App() {
  return (
    <React.Fragment>
      {/* <ThemeProvider> */}
      <Grid
        sx={{
          backgroundImage: "url(/backGround.jpg)",
          backgroundSize: "100%",
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppContextProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Grid>
      <Spinner />
      {/* </ThemeProvider> */}
    </React.Fragment>
  );
}

export default App;
