import {
  CARI_TIPI,
  TEKLIF_DURUMU,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { ID_WIDTH } from "../common/entityconstants";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";
import MusteriInputForm from "../components/inputs/custom-form-inputs/MusteriInputForm";
import PotansiyelMusteriInputForm from "../components/inputs/custom-form-inputs/PotansiyelMusteriInputForm";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "id",
    label: "Teklif No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "teklifTarihi",
    label: "Teklif Tarihi",
    dataType: "date",
    dataField: "teklifTarihi",
    visible: ["grid"],
  },
  {
    name: "cariTipi",
    label: "CariTipi",
    dataType: "select",
    dataField: "cariTipi",
    visible: ["grid"],
    selectItems: Object.values(CARI_TIPI),
  },
  {
    name: "sirket_Kod",
    label: "Firma Adı",
    dataType: "text",
    dataField: "sirket_Kod",
    visible: ["grid"],
  },
  {
    name: "teklifDurumu",
    label: "Teklif Durumu",
    dataType: "select",
    dataField: "teklifDurumu",
    visible: ["grid"],
    selectItems: Object.values(TEKLIF_DURUMU),
  },
  {
    name: "bayiidStr",
    label: "Bayi Kodu",
    dataType: "text",
    dataField: "bayiidStr",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  // {
  //   name: "aktif",
  //   label: "Aktif",
  //   dataType: "boolean",
  //   dataField: "aktif",
  //   visible: ["grid"],
  // },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "id",
    label: "Teklif No",
    dataType: "number",
    dataField: "id",
    visible: ["filter"],
  },
  {
    name: "teklifTarihi1",
    label: "Teklif Tarihi 1",
    dataType: "date",
    dataField: "teklifTarihi1",
    visible: ["filter"],
  },
  {
    name: "teklifTarihi2",
    label: "Teklif Tarihi 2",
    dataType: "date",
    dataField: "teklifTarihi2",
    visible: ["filter"],
  },
  {
    name: "cariTipi",
    label: "Cari Tipi",
    dataType: "select",
    dataField: "cariTipi",
    visible: ["filter"],
    items: Object.values(CARI_TIPI),
  },
  {
    name: "potFirmaid",
    label: "Pot. Firma",
    dataType: "custom",
    dataField: "potFirmaid",
    visible: ["filter"],
    customComponent: PotansiyelMusteriInputForm,
  },
  {
    name: "teklifDurumu",
    label: "Teklif Durumu",
    dataType: "select",
    dataField: "teklifDurumu",
    visible: ["filter"],
    items: Object.values(TEKLIF_DURUMU),
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    customComponentParams: {},
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
