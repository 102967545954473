import { HttpAuthorized } from "../common";
import { apiUrl } from "../constants";
import { printToast } from "./printToast";
import PersonelConfig from "../../pages/Personel/PersonelConfig.js";

const personelInputOnChangeExtra = (personelId, emailDialogRef) => {
  if (personelId != null) {
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PersonelConfig.EMailGetByIdRoute}`,
      data: {
        id: personelId,
      },
    })
      .then((res) => {
        if (res?.data?.data?.talepEdenPersonelEMail == null) {
          emailDialogRef?.current?.openRecord(res?.data?.data?.id);
        }
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }
};

export default personelInputOnChangeExtra;
