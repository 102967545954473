import React from "react";

const ProgressBar = (value) => {
  const customprogressBarStyle = {
    topcontainer: {
      width: "100%",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    container: {
      width: "80%",
      height: "20px",
      backgroundColor: "#e0e0e0",
      borderRadius: "4px",
      overflow: "hidden",
      margin: "10px 0",
      position: "relative",
    },
    inner: {
      height: "100%",
      lineHeight: "20px",
      textAlign: "center",
      color: "white",
      transition: "width 0.3s ease",
    },
    text: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "0",
      color: "black",
    },
  };

  return (
    <div style={customprogressBarStyle.topcontainer}>
      <div style={customprogressBarStyle.container}>
        <div
          style={{
            ...customprogressBarStyle.inner,
            width: `${value}%`,
            backgroundColor: "#34a4eb",
          }}
        >
          <span style={customprogressBarStyle.text}>{value}%</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
