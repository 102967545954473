import { ID_WIDTH } from "../common/entityconstants";
import { URUN_TIP, URUN_TIP_MODULPAKET } from "../common/constants";
import UrunGrubuInputForm from "../components/inputs/custom-form-inputs/UrunGrubuInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "urunTipi",
    label: "Ürün Tipi",
    dataType: "select",
    dataField: "urunTipi",
    visible: ["grid"],
    selectItems: Object.values(URUN_TIP_MODULPAKET),
  },
  {
    name: "urunKodu",
    label: "Ürün Kodu",
    dataType: "text",
    dataField: "urunKodu",
    visible: ["grid"],
  },
  {
    name: "urunAdi",
    label: "Ürün Adı",
    dataType: "text",
    dataField: "urunAdi",
    visible: ["grid"],
  },
  {
    name: "urunGrubu1Str",
    label: "Ürün Grubu-1",
    dataType: "text",
    dataField: "urunGrubu1Str",
    visible: ["grid"],
  },
  {
    name: "urunGrubu2Str",
    label: "Ürün Grubu-2",
    dataType: "text",
    dataField: "urunGrubu2Str",
    visible: ["grid"],
  },
  {
    name: "urunGrubu3Str",
    label: "Ürün Grubu-3",
    dataType: "text",
    dataField: "urunGrubu3Str",
    visible: ["grid"],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "urunTipi",
    label: "Ürün Tipi",
    dataType: "select",
    dataField: "urunTipi",
    visible: ["filter"],
    items: Object.values(URUN_TIP_MODULPAKET),
  },
  {
    name: "urunKod",
    label: "Ürün Kodu",
    dataType: "text",
    dataField: "urunKod",
    visible: ["filter"],
  },
  {
    name: "urunAd",
    label: "Ürün Adı",
    dataType: "text",
    dataField: "urunAd",
    visible: ["filter"],
  },
  {
    name: "urunGrubu1",
    label: "Ürün Grubu-1",
    dataType: "custom",
    dataField: "urunGrubu1",
    visible: ["filter"],
    customComponent: UrunGrubuInputForm,
  },
  {
    name: "urunGrubu2",
    label: "Ürün Grubu-2",
    dataType: "custom",
    dataField: "urunGrubu2",
    visible: ["filter"],
    customComponent: UrunGrubuInputForm,
  },
  {
    name: "urunGrubu3",
    label: "Ürün Grubu-3",
    dataType: "custom",
    dataField: "urunGrubu3",
    visible: ["filter"],
    customComponent: UrunGrubuInputForm,
  },
  // {
  //   name: "aktif",
  //   label: "Aktif",
  //   dataType: "select",
  //   dataField: "aktif",
  //   visible: ["filter"],
  //   items: Object.values(AKTIF_PASIF)
  // }
];

export default entity;
