import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import useBasicDialog from "../../hooks/use-basic-dialog";
import { AppContext } from "../../common/AppContext";

const DeleteDescriptionDialog = React.forwardRef(
  ({ ekranKodu, talepId, listGridRef, masterDialogClose }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    const context = React.useContext(AppContext);

    const yetki =
      context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

    const afterSubmitFnc = () => {
      closeRecord();
      masterDialogClose();
    };

    const handleSubmit = useSubmit(
      {
        insertRoute: "Gorev/TalebeAitGorevleriSil",
      },
      null,
      listGridRef,
      afterSubmitFnc
    );

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Açıklama"
        maxWidth="lg"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{ talepId: talepId }}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Field
                  name="yorum"
                  component={MultilineTextInputForm}
                  readOnly={!yetki.kaydet}
                  maxLength={500}
                  minRows={5}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <SaveButton
                  onClick={(e) => {
                    form.submit().then(() => {
                      closeRecord();
                    });
                  }}
                />
                <CancelButton
                  onClick={() => {
                    form.reset();
                  }}
                  disabled={submitting || pristine}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default DeleteDescriptionDialog;
