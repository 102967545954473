function compareWithMonths(dateString, monthOffset, comparisonOperator) {
  const today = new Date();
  const compareDate = new Date(dateString);

  const adjustedDate = new Date();
  adjustedDate.setMonth(today.getMonth() + monthOffset);

  if (comparisonOperator === "<") {
    // parametre olarak aldığım ay değeri gönderdiğim ay değerinden örneğin -2 ise 2 ay öncesi ise true döner || +2 olsaydı 2 ay ssonrası ise true dönecekti
    return compareDate < adjustedDate;
  } else if (comparisonOperator === ">") {
    return compareDate > adjustedDate;
  } else {
    throw new Error("Geçersiz karşılaştırma operatörü");
  }
}
export default compareWithMonths;
