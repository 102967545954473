import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Comment } from "@mui/icons-material";

export default function YorumlariGoruntuleButton({ onClick, disabled }) {
  return (
    <ButtonWrapper
      color="primary"
      startIcon={<Comment />}
      onClick={onClick}
      disabled={disabled}
    >
      Yorumlar
    </ButtonWrapper>
  );
}
