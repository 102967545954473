const entity = [
  {
    name: "ne",
    label: "Ne",
    dataType: "string",
    dataField: "ne",
    visible: ["grid"],
  },
  {
    name: "tutar",
    label: "Tutar",
    dataType: "number",
    dataField: "tutar",
    visible: ["grid"],
  },
  {
    name: "dovizKod",
    label: "Döviz",
    dataType: "string",
    dataField: "dovizKod",
    visible: ["grid"],
  },
];

export default entity;
