import dayjs from "dayjs";
import fillYearForSelect from "../common/constant-functions/fillYearForSelect";
import { TUTAR_FORMAT } from "../common/constants";

const entity = [
  //   {
  //     name: "id",
  //     label: "No",
  //     dataType: "number",
  //     dataField: "id",
  //     visible: ["grid"],
  //     isPrimaryKey: true,
  //     width: "75px",
  //   },
  {
    name: "sorumlu",
    label: "Sorumlu",
    dataType: "text",
    dataField: "sorumlu",
    visible: ["grid"],
    firstSort: "asc",
  },
  {
    name: "ocak",
    label: "Ocak",
    dataType: "number",
    dataField: "ocak",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "subat",
    label: "Şubat",
    dataType: "number",
    dataField: "subat",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "mart",
    label: "Mart",
    dataType: "number",
    dataField: "mart",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "nisan",
    label: "Nisan",
    dataType: "number",
    dataField: "nisan",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "mayis",
    label: "Mayıs",
    dataType: "number",
    dataField: "mayis",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "haziran",
    label: "Haziran",
    dataType: "number",
    dataField: "haziran",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "temmuz",
    label: "Temmuz",
    dataType: "number",
    dataField: "temmuz",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "agustos",
    label: "Ağustos",
    dataType: "number",
    dataField: "agustos",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "eylul",
    label: "Eylül",
    dataType: "number",
    dataField: "eylul",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "ekim",
    label: "Ekim",
    dataType: "number",
    dataField: "ekim",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "kasim",
    label: "Kasim",
    dataType: "number",
    dataField: "kasim",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "aralik",
    label: "Aralık",
    dataType: "number",
    dataField: "aralik",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "toplamHarcananSure",
    label: "Toplam Harcanan Süre (Saat)",
    dataType: "number",
    dataField: "toplamHarcananSure",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  //todo--------filtre--------------------------------------
  {
    name: "yil",
    label: "Yıl",
    dataType: "select",
    dataField: "yil",
    visible: ["filter"],
    items: fillYearForSelect(),
    defaultValue: dayjs().year(),
  },
];

export default entity;
