import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function UlkeInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  onChangeExtra,
  ...rest
}) {
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "UlkeSehirIlce/UlkelerListForSelect",
          fildsForText: ["ulkeAdi"],
          fieldForValue: "ulkeid",
          reqData: {},
          // fieldLabel: "ulkeAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
