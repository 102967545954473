import React, { useContext, useRef } from "react";
import { Divider, List } from "@mui/material";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import AuthService from "../../services/AuthService";
import routes from "../../common/routes";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PopoverViaButton from "../buttons/PopoverViaButton";
import NavigateListItem from "../buttons/custom/NavigateListItem";
import AppBarAvatar from "./AppBarAvatar";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import ComputerIcon from "@mui/icons-material/Computer";
import GuncellemeVersiyonuDialog from "../../pages/Profile/GuncellemeVersiyonuDialog";

export default function AppBarLogoButton() {
  const context = useContext(AppContext);
  const guncellemeVersiyonuDialogRef = useRef();

  return (
    <>
      <PopoverViaButton PButton={() => <AppBarAvatar />}>
        <List sx={{ p: 1 }}>
          <NavigateListItem path={routes.kullaniciBilgileri.path}>
            <ManageAccountsIcon sx={{ mr: 1 }} />
            Kullanıcı Bilgilerim
          </NavigateListItem>
          <Divider />
          {context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value && (
            <>
              <NavigateListItem path={routes.profil.path}>
                <ManageAccountsIcon sx={{ mr: 1 }} />
                Şirket Bilgilerim
              </NavigateListItem>
              <Divider />
            </>
          )}
          {/* //TODO YARDİM PDF
        <NavigateListItem path={routes.yardim.path}>
          <HelpIcon sx={{ mr: 1 }} fontSize="small" />
          Yardım
        </NavigateListItem>
        <Divider /> */}
          {context.user.yetkiGrubuTipi ===
            YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && (
            <>
              <NavigateListItem
                // color="#e61d29"
                onClick={() => {
                  guncellemeVersiyonuDialogRef?.current?.openRecord();
                }}
              >
                <ComputerIcon sx={{ mr: 1 }} fontSize="small" />
                Güncelleme versiyonu çıkarıldı
                {context.user.guncellemeVersiyonuAdi &&
                  " (" + context.user.guncellemeVersiyonuAdi + ")"}
              </NavigateListItem>
              <Divider />
            </>
          )}
          <NavigateListItem
            path={routes.login.path}
            color="#e61d29"
            onClick={() => AuthService.logout(context)}
          >
            <LogoutIcon sx={{ mr: 1 }} fontSize="small" />
            Oturumu Kapat
          </NavigateListItem>
        </List>
      </PopoverViaButton>
      <GuncellemeVersiyonuDialog ref={guncellemeVersiyonuDialogRef} />
    </>
  );
}
