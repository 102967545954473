import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../../components/tab/TabPanel";
import { AppBar } from "@mui/material";
import { ctheme } from "../../theme/theme";

const MainTab = React.forwardRef(
  ({ labels, children, appBarColor, sx, tabOnChangeExtra }, ref) => {
    const childrenArray = React.Children.toArray(children);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      tabOnChangeExtra && tabOnChangeExtra(newValue);
    };

    React.useImperativeHandle(ref, () => ({
      selectedTabIndex: value,
    }));

    return (
      <Box
        sx={{
          width: "100%",
          ...sx,
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: appBarColor ?? ctheme.colors.gridHeaderLight }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            {labels?.map((label, index) => (
              <Tab label={label} key={index} />
            ))}
          </Tabs>
        </AppBar>
        {childrenArray?.map((tabPanel, index) => (
          <TabPanel value={value} index={index} key={index}>
            {tabPanel}
          </TabPanel>
        ))}
      </Box>
    );
  }
);

export default MainTab;
