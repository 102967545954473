export const ctheme = {
  colors: {
    navyBlue: "#1976d2",
    // lynxWhite: "#f7f7f7",
    lynxWhite: "#F6F6F6",
    brightNavyBlue: "rgb(25, 118, 210, 0.2)",
    superSilver: "rgb(238, 238, 238, 0.5)",
    gridHeaderColor: "#f7f9fc",
    teal: "#17B794",
    darkTeal: "#14a082",
    grape: "#8C5F76",
    darkGrape: "#85586F",
    gridHeaderLight: "rgba(248, 249, 252, 0.65)",
    aiburn: "#A03232",
    lightAiburn: "#9A3735",
    lightYellowTransparent: "#dfffd898",
    lightYellow: "#DFFFD8",
    retroLime: "#14C38E",
    lightPink: "#E966A0",
    pink: "#D25380",
    brown: "#CC7351",
    lightBrown: "#E08F62",
    frostedPlains: "#e0ffe0",
    lightOrange: "#FFEDC6",
    lightPurple: "#9376E0",
    purple: "#6554AF",
    red: "#B70404",
    lightRed: "#CD1818",
    green: "#557571",
    lightGreen: "#5C8984",
  },
  //   fonts: {
  //     body: "Arial, sans-serif",
  //     heading: "Georgia, serif",
  //   },
};
