import React from "react";
import "../../css/Loading.css";

const Spinner = ({ staticLoading }) => {
  return (
    <div
      className="loader-box"
      id="preloader"
      {...(staticLoading ? {} : { style: { display: "none" } })}
    >
      <div className="loader-design">
        <div className="circle-1"></div>
        <div className="circle-2"></div>
        <div className="circle-3"></div>
      </div>
    </div>
  );
};

export default Spinner;
