import React, { useCallback, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  Button,
  Format,
} from "devextreme-react/data-grid";

const dipToplamCellPrepared = (e) => {
  if (e.rowType === "data") {
    if (e.column.dataField === "tutar") {
      if (e.values[0] === "Kdv Tutarı") {
        e.cellElement.style.cssText = "color: red;padding: 3px;";
      } else if (e.values[0] === "İskonto Tutarı") {
        e.cellElement.style.cssText = "color: #ff00ff;padding: 3px;";
      } else if (e.values[0] === "Genel Toplam") {
        e.cellElement.style.cssText = "color: blue;padding: 3px";
      }
    } else if (e.column.dataField === "ne") {
      e.cellElement.style.cssText =
        "color: black; font-weight: bold;padding: 3px;";
    } else if (e.column.dataField === "dovizKod") {
      if (e.values[0] === "Kdv Tutarı") {
        e.cellElement.style.cssText = "color: red;padding: 3px;";
      } else if (e.values[0] === "İskonto Tutarı") {
        e.cellElement.style.cssText = "color: #ff00ff;padding: 3px;";
      } else if (e.values[0] === "Genel Toplam") {
        e.cellElement.style.cssText = "color: blue;padding: 3px";
      }
    }
  }
};

const DipToplamGrid = ({ entities, height, dataSource }) => {
  return (
    <div id="data-grid-demo">
      <DataGrid
        onCellPrepared={dipToplamCellPrepared}
        showColumnHeaders={false}
        width={"350px"}
        dataSource={dataSource}
        // keyExpr={entities.find((x) => x.isPrimaryKey).name}
        showBorders={true}
        height={height}
        toolbar={{
          items: [
            // { widget: 'dxButton', location: 'after', options: { icon: 'refresh' } },
          ],
        }}
      >
        <Paging enabled={false} />
        {entities
          .filter((type) => {
            let gridVisible = type.visible?.includes("grid");
            if (gridVisible) return true;
            else return false;
          })
          .map((i, index) => {
            return (
              <Column
                key={i.name}
                dataType={i.dataType}
                caption={i.label}
                dataField={i.dataField}
                name={i.name}
                visibleIndex={index}
                allowEditing={false}
                alignment={"left"}
                //   i.alignment
                //     ? i.alignment
                //     : i.dataType === "number"
                //     ? "right"
                //     : i.dataType === "boolean"
                //     ? "center"
                //     : "left"
                // }
                defaultSortOrder={i.firstSort ? i.firstSort : null}
                width={i.width ? i.width : null}
                minWidth={i.minWidth ? i.minWidth : 100}
                format={i.format ? i.format : null}
                visible={i.pageGridHidden ? false : true}
                {...(i.calculateSortValue && {
                  calculateSortValue: i.calculateSortValue,
                })}
                {...(i.defaultSortIndex && {
                  defaultSortIndex: i.defaultSortIndex,
                })}
                {...(i.sortingMethod && {
                  sortingMethod: i.sortingMethod,
                })}
                {...(i.cellRender && { cellRender: i.cellRender })}
                {...(i.customizeText && { customizeText: i.customizeText })} //todo excel e aktarırken muinin bileşenleri aktarılmadığı için cell render kullanılan fieldlara bu alanın da eklenmesi gerekir
                allowExporting={i.allowExporting ?? true}
                allowSorting={i.allowSorting ?? true}
              >
                {i.dataType === "number" ? (
                  <Format formatter={",##0.00"}></Format>
                ) : null}
              </Column>
            );
          })}
      </DataGrid>
    </div>
  );
};
export default DipToplamGrid;
