import React, { useRef } from "react";
import { Preview, Task } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Form, Field } from "react-final-form";
import {
  ILGILI_MODUL,
  ONCELIK_DURUMU,
  RAPOR_KATEGORI,
  TALEP_DURUMU,
  TALEP_TIPI,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import * as yup from "yup";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig, FileConfig } from "./MusteriTalepConfig";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import PersonelInputForm from "../../components/inputs/custom-form-inputs/PersonelInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import { getTalepDurumuArr } from "../../common/constant-functions/getTalepDurumuArr";
import PdfDialog from "../../components/pdf/PdfDialog";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import AnalizSonucuButton from "../../components/buttons/custom/AnalizSonucuButton";
import sozlesmeTarihiBitmisMi from "../../common/constant-functions/sozlesmeTarihiBitmisMi";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import IsKapatmaDialog from "./IsKapatmaDialog";
import IsKapatmaBtn from "../../components/buttons/custom/IsKapatmaBtn";
import { talebeAitDosyaVar } from "../../common/apimessage";
import AlertDialog from "../../components/dialogs/AlertDialog";
import useHandleDelete from "../../hooks/use-handle-delete";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import YorumlariGoruntuleButton from "../../components/buttons/custom/YorumlariGoruntuleButton";
import YorumDialog from "../Home/YorumDialog";
import RecordButton from "../../components/buttons/custom/RecordButton";
import personelInputOnChangeExtra from "../../common/constant-functions/personelInputOnChangeExtra";
import EMailDialog from "../Personel/EmailDialog";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";

const MusteriTalepRecord = React.forwardRef(
  ({ ekranKodu, listGridRef }, ref) => {
    const personelInputRef = useRef();
    const teklifPdfDialogRef = useRef();
    const onayliPdfDialogRef = useRef();
    const analizSonucuDialogRef = useRef();
    const isKapatmaDialogRef = useRef();
    const deleteDialogRef = useRef();
    const yorumDialogRef = useRef();
    const emailDialogRef = useRef();

    const deleteCheck = (res) => {
      if (res.data.message === talebeAitDosyaVar) {
        deleteDialogRef?.current?.openRecord(rowData.id);
        return true;
      } else return false;
    };

    const initialState = {
      raporKategori: RAPOR_KATEGORI.TALEP.Value,
      talepDurumu: TALEP_DURUMU.YENI_TALEP.Value,
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      context,
      setRowDataFnc,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      null,
      deleteCheck
    );

    const handleDeleteWithFiles = useHandleDelete(
      PageConfig.deleteWithFilesRoute,
      setRowDataFnc,
      handleClose,
      listGridRef
    );

    const readOnly =
      !yetki.kaydet ||
      //ekranKodu == EKRAN_KODU.MUSTERI_TALEP &&
      (rowData.talepDurumu != null &&
        rowData.talepDurumu !== TALEP_DURUMU.YENI_TALEP.Value);

    const validationSchema = yup.object().shape({
      talepBasligi: yup.string().required(),
      talepTipi: yup.number().required(),
      ilgiliModul: yup.number().required(),
      oncelik: yup.number().required(),
    });

    const validate = validateFormValues(validationSchema);

    const recordButtonCondition =
      rowData.talepDurumu == null ||
      rowData.talepDurumu === TALEP_DURUMU.YENI_TALEP.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;

    const personelOnChangeExtra = (inputValue, newValue) => {
      personelInputOnChangeExtra(newValue.Value, emailDialogRef);
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Talep"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Talep No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>

                      <FieldWrapper
                        label="Rapor Kategorisi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(RAPOR_KATEGORI)}
                          name="raporKategori"
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="Talep Eden Personel"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="talepEdenPersonelId"
                          rowData={rowData}
                          component={PersonelInputForm}
                          readOnly={readOnly}
                          inputRef={personelInputRef}
                          ekranKodu={ekranKodu}
                          onChangeExtra={personelOnChangeExtra}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Öncelik Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="oncelik"
                          items={ONCELIK_DURUMU}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={readOnly}
                        />
                      </FieldWrapper>

                      {rowData.teklifTutari > 0 && (
                        <FieldWrapper
                          label="Teklif Tutarı"
                          modalLoading={modalLoading}
                        >
                          <Grid container direction="row">
                            <Grid item xs={10}>
                              <Field
                                name="teklifTutari"
                                component={NumberInputForm}
                                readOnly={true}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                name="teklifBirimi"
                                component={TextInputForm}
                                readOnly={true}
                              />
                            </Grid>
                          </Grid>
                        </FieldWrapper>
                      )}

                      <FieldWrapper
                        label="İlgili Modül"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ilgiliModul"
                          items={Object.values(ILGILI_MODUL)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={readOnly}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Talep Tipi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="talepTipi"
                          items={TALEP_TIPI}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={readOnly}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="Talep Konusu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="talepBasligi"
                          component={TextInputForm}
                          readOnly={readOnly}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="Talep Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="talepDurumu"
                          items={getTalepDurumuArr()}
                          // rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Termin Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="terminTarihi"
                          component={DateInputForm}
                          readOnly
                          // readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Özellik 1"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ozellik"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                          maxLength={15}
                        />
                      </FieldWrapper>
                      {rowData.teklifTutari > 0 && (
                        <FieldWrapper
                          label="Kdv Oranı (%)"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="teklifKdvOrani"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                            numberInput={true}
                          />
                        </FieldWrapper>
                      )}
                    </Grid>
                  </DialogContentTop>

                  <Field
                    name="talepDetayi"
                    component={RichTextEditor}
                    readOnly={readOnly}
                    label="Talep Detayı"
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>

                <DialogActionsWrapper
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    <YorumlariGoruntuleButton
                      onClick={(e) => {
                        yorumDialogRef?.current?.openRecord(rowData.id);
                      }}
                      disabled={!rowData.id}
                    />
                    {rowData?.teklifTutari > 0 &&
                      rowData?.teklifVerilisTarihi != null && (
                        <CustomColoredButton
                          color="teal"
                          startIcon={<Preview />}
                          onClick={(e) => {
                            teklifPdfDialogRef?.current?.openRecord(rowData.id);
                          }}
                        >
                          Teklifi Görüntüle
                        </CustomColoredButton>
                      )}
                    {rowData.id > 0 && rowData.onayliTeklifDosyasiVarMi && (
                      <ButtonWrapper
                        color="secondary"
                        startIcon={<Task />}
                        onClick={(e) => {
                          onayliPdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                      >
                        Onaylı Teklifi Görüntüle
                      </ButtonWrapper>
                    )}
                    <EkDosyaButton
                      reqData={{
                        id: rowData.id,
                      }}
                      routes={FileConfig}
                      ekranKodu={ekranKodu}
                      parentRowData={rowData}
                      disabled={!rowData?.id}
                    ></EkDosyaButton>

                    <AnalizSonucuButton
                      onClick={(e) => {
                        analizSonucuDialogRef?.current?.openRecord(rowData?.id);
                      }}
                      disabled={!rowData.id}
                    ></AnalizSonucuButton>
                    <IsKapatmaBtn
                      isKapatmaDialogRef={isKapatmaDialogRef}
                      rowData={rowData}
                      yetki={yetki}
                    ></IsKapatmaBtn>
                  </Grid>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    submitCondition={recordButtonCondition}
                    submitDisabled={sozlesmeTarihiBitmisMi(context)}
                    deleteCondition={recordButtonCondition}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
                <AlertDialog
                  ref={deleteDialogRef}
                  onayBtnClick={(id) => handleDeleteWithFiles(id)}
                  dialogTitle="Talebe ait dosya var."
                  contentText={talebeAitDosyaVar}
                ></AlertDialog>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <PdfDialog
          ref={teklifPdfDialogRef}
          title="Teklif"
          url="Talep/TeklifPdf"
        />
        <PdfDialog
          ref={onayliPdfDialogRef}
          title="Onaylı Teklif"
          url="Talep/TeklifOnayliBelge"
        />
        <AnalizSonucuDialog
          ref={analizSonucuDialogRef}
          ekranKodu={ekranKodu}
          isEditable={false}
          rowData={rowData}
        ></AnalizSonucuDialog>
        <IsKapatmaDialog
          ref={isKapatmaDialogRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          setRowDataFnc={setRowDataFnc}
        ></IsKapatmaDialog>
        <YorumDialog
          ref={yorumDialogRef}
          listGridRef={listGridRef}
        ></YorumDialog>
        <EMailDialog
          ref={emailDialogRef}
          yetki={yetki}
          rowData={rowData}
        ></EMailDialog>
      </>
    );
  }
);

export default MusteriTalepRecord;
