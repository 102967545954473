import React, { useRef } from "react";
import { toast } from "react-toastify";
import DestekSuresiDurumuButton from "../../components/buttons/custom/DestekSuresiDurumuButton";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import DestekSuresiDialog from "../Destek-gecmisi/DestekSuresiDialog";
import PageConfig from "./DestekConfig";
import DestekDetayRecord from "./DestekDetayRecord";
import DestekRecord from "./DestekRecord";

export default function Destek({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const detayRecordRef = useRef();
  const destekSuresiDialogRef = useRef();

  function DestekSuresiDurumuBtn() {
    return (
      <DestekSuresiDurumuButton
        onClick={() => {
          if (filterRef?.current?.filterData?.sirketId > 0) {
            destekSuresiDialogRef?.current?.openRecord(
              filterRef?.current?.filterData?.sirketId
            );
          } else {
            toast.info("Filtreden şirket seçimi yapmalısınız.");
          }
        }}
      />
    );
  }

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Destek"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        detayRecordRef={detayRecordRef}
        isCreatable
        ekranKodu={ekranKodu}
        CustomGridHeaderButton={DestekSuresiDurumuBtn}
      />
      <DestekRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></DestekRecord>
      <DestekDetayRecord
        ref={detayRecordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></DestekDetayRecord>
      <DestekSuresiDialog ref={destekSuresiDialogRef} filterRef={filterRef} />
    </>
  );
}
