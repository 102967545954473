import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function UrunlerInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  urunTipiRef,
  onChangeExtra,
  ...rest
}) {
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "Urunler/UrunlerListForSelect",
          fildsForText: ["urunKodu"],
          fieldForValue: "id",
          reqData: {
            ...{
              urunTipi:
                urunTipiRef?.current?.value === ""
                  ? rowData.urunTip
                  : urunTipiRef?.current?.value,
            },
          },
          fieldLabel: "urunKodu",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
