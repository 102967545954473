import React from "react";

const ScrollableCell = (value) => {
  return (
    <div
      style={{
        overflow: "auto",
        whiteSpace: "normal",
        maxHeight: "45px",
      }}
    >
      {value}
    </div>
  );
};

export default ScrollableCell;
