import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./YeniEklenenOzelliklerConfig";
import IsSonucuDialog from "../Home/IsSonucuDialog";
import GridIsSonucuButton from "../../components/buttons/custom/GridIsSonucuButton";

// export default function YeniEklenenOzellikler({ekranKodu}) {
export default function YeniEklenenOzellikler() {
  const listGridRef = useRef();
  const filterRef = useRef();
  const isSonucuDialogRef = useRef();

  const CustomColumnButton = [GridIsSonucuButton(isSonucuDialogRef)];

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="YeniEklenenOzellikler"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // ekranKodu={ekranKodu}
        isCreatable={false}
        CustomColumnButton={CustomColumnButton}
        editBtnVisible={false}
      />
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog>
    </>
  );
}
