import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageConfig from "./NotConfig";
import NotRecord from "./NotRecord";
import EditablePageGridWithList from "../../components/grid/EditablePageGridWithList";

function Not({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <EditablePageGridWithList
        gridId="Not"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isModalCreatable={true}
        ekranKodu={ekranKodu}
        recordRef={recordRef}
        editBtnVisible={true}
      />
      <NotRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></NotRecord>
    </>
  );
}

export default Not;
