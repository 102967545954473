import React, { useState } from "react";
import { HttpAuthorized } from "../common/common";
import { printToast } from "../common/constant-functions/printToast";
import { apiUrl } from "../common/constants";

function useFetchDialog(ref, fetchUrl) {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  const openRecord = async (recId, reqData) => {
    if (recId > 0 || reqData != null) {
      await fetchData(recId, reqData);
    }
    setOpen(true);
  };

  const closeRecord = () => {
    setOpen(false);
    setRowData({});
    setModalLoading(true);
  };

  React.useImperativeHandle(ref, () => ({
    openRecord: openRecord,
    closeRecord: closeRecord,
  }));

  async function fetchData(recId, reqData) {
    HttpAuthorized.request({
      method: "POST",
      url: apiUrl + fetchUrl,
      data: {
        ...(recId > 0 && { id: recId }),
        ...(reqData && { ...reqData }),
      },
    })
      .then((res) => {
        setRowData(res?.data?.data || {});
        setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }

  return {
    open,
    openRecord,
    closeRecord,
    modalLoading,
    rowData,
    setRowData,
  };
}

export default useFetchDialog;
