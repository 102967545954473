import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Save } from "@mui/icons-material";

export default function SaveButton({ onClick, disabled }) {
  return (
    <ButtonWrapper
      color="success"
      startIcon={<Save />}
      onClick={onClick}
      disabled={disabled}
    >
      Kaydet
    </ButtonWrapper>
  );
}
