import React, { useState } from "react";
import { AppContext } from "../common/AppContext";
import { HttpAuthorized } from "../common/common";
import { loadingVisible } from "../common/constant-functions/loadingVisible";
import { printToast } from "../common/constant-functions/printToast";
import validateFormValues from "../common/constant-functions/validateFormValues";
import { apiUrl } from "../common/constants";
import { PageConfig } from "../pages/Sektor/SektorConfig";

function useSektorRecord(ref, sektorInputRef, ekranKodu) {
  const [open, setOpen] = useState(false);
  const [recId, setRecId] = useState(0);
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  React.useImperativeHandle(ref, () => ({
    setRecId: setRecId,
    recId: recId,
    openRecord: openRecord,
  }));

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setRecId(null);
    setRowData({});
    setModalLoading(true);
  };

  async function fetchData(recId) {
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.getByIdRoute}`,
      data: {
        id: recId,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData(res.data.data);
        setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }

  async function insertOrUpdate(values) {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${
        values.id ? PageConfig.updateRoute : PageConfig.insertRoute
      }`,
      data: {
        ...values,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData(res.data.data);
        sektorInputRef?.current?.changeValue({
          Value: res.data.data?.id,
          Text: res.data.data?.sektor,
        });
        handleClose();

        console.log("res gelen", res.data.data);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  async function handleDelete() {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.deleteRoute}`,
      data: { id: rowData.id },
    })
      .then((res) => {
        printToast(res.data);

        if (res.data.success) {
          setRowData({});
          sektorInputRef?.current?.changeValue({
            Value: null,
            Text: null,
          });
          handleClose();
        }
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  const handleSubmit = async (values) => {
    await insertOrUpdate(values);
  };

  function openRecord(recId) {
    if (recId > 0) {
      setRecId(recId);
      fetchData(recId);
    } else {
      setRecId(null);
      setModalLoading(false);
    }
    handleClickOpen();
  }

  const context = React.useContext(AppContext);

  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  return {
    rowData,
    setRowData,
    recId,
    setRecId,
    open,
    handleClickOpen,
    handleClose,
    modalLoading,
    setModalLoading,
    handleDelete,
    handleSubmit,
    validateFormValues,
    yetki,
  };
}

export default useSektorRecord;
