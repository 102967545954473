import * as yup from "yup";
import { setIn } from "final-form";

yup.setLocale({
  mixed: {
    required: "Bu alan boş geçilemez",
    notType: "Bu alan boş geçilemez",
  },
  string: {
    email: "Lütfen geçerli bir email girin.",
    notType: "Bu alan boş geçilemez",
  },
});

const validateFormValues = (schema) => async (values) => {
  if (typeof schema === "function") {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    let errors = {};
    errors = err?.inner?.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export default validateFormValues;
