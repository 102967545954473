import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import UrunGrubuRecord from "../../../pages/UrunGrubu/UrunGrubuRecord";

export default function UrunGrubuInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  fieldLabelRef,
  ...rest
}) {
  const urunGrubuDialogRef = useRef();
  return (
    <>
    <AutocomplateInputForm
      readOnly={readOnly}
      rowData={rowData}
      insertButtonRef={urunGrubuDialogRef}
      remoteDataAdaptor={{
        fetchRoute: "Urunler/UrunGrubuListForSelect",
        fildsForText: ["urunGrubu"],
        fieldForValue: "id",
        reqData: {},
        fieldLabel: fieldLabelRef
      }}
      ref={inputRef}
      input={input}
      {...rest}
    />
     <UrunGrubuRecord
        ref={urunGrubuDialogRef}
        ekranKodu={ekranKodu}
        urunGrubuInputRef={inputRef}
      ></UrunGrubuRecord>
    </>
  );
}
