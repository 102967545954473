import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import BootstrapDialogTitle from "./BootsrapDialogTitle";
import DraggablePaper from "./DraggablePaper";

function ScrollDialog({
  children,
  modalTitle,
  open,
  handleClose,
  maxWidth,
  fullWidth = true,
  canFullScreen = true,
  ...rest
}) {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <Dialog
      fullScreen={canFullScreen ? fullScreen : false}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionProps={{ timeout: 0 }}
      open={open}
      onClose={handleClose}
      PaperComponent={DraggablePaper}
      PaperProps={{ fullScreen: fullScreen }}
      {...rest}
    >
      <BootstrapDialogTitle
        onClose={handleClose}
        setFullScreen={setFullScreen}
        canFullScreen={canFullScreen}
      >
        {modalTitle}
      </BootstrapDialogTitle>
      {children}
    </Dialog>
  );
}
export default ScrollDialog;
