import Type from "../../entities/FiyatListeDetay";

const PageConfig = {
  title: "FiyatListeDetay",
  ekranKodu: "FiyatListeMaster_Res",
  type: Type,
  listRoute: "FiyatListeDetay/List",
  getByIdRoute: "FiyatListeDetay/GetById",
  insertRoute: "FiyatListeDetay/Insert",
   updateRoute: "FiyatListeDetay/Update",
   deleteRoute: "FiyatListeDetay/Delete",
};

export {
  PageConfig
};