import { Grid } from "@mui/material";
import React, { useContext, useRef } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import useSubmit from "../../hooks/use-submit";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import { AppContext } from "../../common/AppContext";
import DescriptionIcon from "@mui/icons-material/Description";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import DosyaYukle from "../../components/file/DosyaYukle";
import DosyaYukleButton from "../../components/buttons/custom/DosyaYukleButton";
import { BayiAnlasmaBilgileriConfig } from "./BayilerConfig";
import PdfDialog from "../../components/pdf/PdfDialog";

const BayiAnlasmaBilgileriDialog = React.forwardRef(({ listGridRef }, ref) => {
  const { open, closeRecord, modalLoading, rowData, setRowData } =
    useFetchDialog(ref, BayiAnlasmaBilgileriConfig.getByIdRoute);

  const handleSubmit = useSubmit(
    { updateRoute: BayiAnlasmaBilgileriConfig.updateRoute },
    setRowData,
    listGridRef
  );

  const pdfDialogRef = useRef();
  const dosyaYukleRef = useRef();

  const context = useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find(
      (x) => x.ekranKodu === BayiAnlasmaBilgileriConfig.ekranKodu
    ) ?? {};

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Anlaşma Bilgileri"
        maxWidth="sm"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12}>
                    <FieldWrapper
                      label="Destek Sözleşmesi Başlangıç Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="destekSozBaslangic"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Aylık Standart Süre (dk)"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aylikStandartSure"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                        numberInput={true}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Destek Sözleşmesi Bitiş Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="destekSozBitis"
                        component={DateInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    {context.user.yetkiGrubuTipi ===
                      YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && (
                      <FieldWrapper
                        label="Aylık Anlaşılan Standart Ücret"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aylikAnlasilanStandartUcret"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    )}
                  </Grid>
                </DialogContentTop>
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <>
                    {rowData.sozlesmeVarMi && (
                      <CustomColoredButton
                        startIcon={<DescriptionIcon />}
                        onClick={(e) => {
                          pdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                        color="grape"
                      >
                        Sözleşme
                      </CustomColoredButton>
                    )}

                    <DosyaYukleButton
                      onClick={() => {
                        dosyaYukleRef?.current?.openModal();
                      }}
                      label="Sözleşme Yükle"
                    />
                  </>
                </Grid>
                <Grid>
                  <SaveButton
                    onClick={(e) => {
                      form.submit();
                    }}
                  />
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                </Grid>
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <DosyaYukle
        route={BayiAnlasmaBilgileriConfig.contractUploadRoute}
        reqExtraData={[{ name: "id", value: rowData?.id }]}
        ref={dosyaYukleRef}
        rowData={rowData}
        setRowData={setRowData}
      ></DosyaYukle>

      <PdfDialog
        ref={pdfDialogRef}
        title="Sözleşme"
        url={BayiAnlasmaBilgileriConfig.contractGetByIdRoute}
      />
    </>
  );
});

export default BayiAnlasmaBilgileriDialog;
