import React, { useState } from "react";

function useBasicDialog(ref) {
  const [open, setOpen] = useState(false);
  const openRecord = () => setOpen(true);
  const closeRecord = () => setOpen(false);

  React.useImperativeHandle(ref, () => ({
    openRecord: openRecord,
    closeRecord: closeRecord,
  }));

  return {
    open,
    openRecord,
    closeRecord,
  };
}

export default useBasicDialog;
