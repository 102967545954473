import React, { useRef } from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./PotansiyelMusteriConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import DateInputForm from "../../components/inputs/form-inputs/DateInputForm";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import { AppContext } from "../../common/AppContext";
import UlkeInputForm from "../../components/inputs/custom-form-inputs/UlkeInputForm";
import SehirInputForm from "../../components/inputs/custom-form-inputs/SehirInputForm";
import IlceInputForm from "../../components/inputs/custom-form-inputs/IlceInputForm";
import MahalleInputForm from "../../components/inputs/custom-form-inputs/MahalleInputForm";
import SektorInputForm from "../../components/inputs/custom-form-inputs/SektorInputForm";
import { CALISAN_SAY_ARALIGI, PARA_BIRIMI } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { Grid } from "@mui/material";
import { Padding } from "@mui/icons-material";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const PotansiyelMusteriRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, gorusmeRef }, ref) => {
    const context = React.useContext(AppContext);

    const initialState = {
      aktif: true,
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
    };

    const ulkeInputRef = useRef();
    const sehirInputRef = useRef();
    const ilceInputRef = useRef();
    const mahalleInputRef = useRef();

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

    const validationSchema = yup.object().shape({
      vergiNo: yup
        .string()
        .required()
        .max(11, "Vergi No alanı 11 karakterden büyük olamaz!"),
      musteriAdi: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Potansiyel Müşteri"
          maxWidth="md"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    <CustomFieldWrapper
                      label="Potansiyel M. No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Kayıt Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kayitTarihi"
                        disabled
                        component={DateInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Müşteri Adı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="musteriAdi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Vergi No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="vergiNo"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                        disabled={rowData?.id}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Ülke"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="ulkeid"
                        component={UlkeInputForm}
                        // ref={ulkeInputRef}
                        inputRef={ulkeInputRef}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Şehir"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="sehirid"
                        component={SehirInputForm}
                        inputRef={sehirInputRef}
                        ulkeInputRef={ulkeInputRef}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="İlçe"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="ilceid"
                        component={IlceInputForm}
                        inputRef={ilceInputRef}
                        sehirInputRef={sehirInputRef}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Mahalle"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="mahalleid"
                        component={MahalleInputForm}
                        inputRef={mahalleInputRef}
                        ilceInputRef={ilceInputRef}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Adres"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="adres"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Web Sitesi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="webSitesi"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Açıklama"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aciklama"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Sektör"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="sektor"
                        component={SektorInputForm}
                        readOnly={!yetki.kaydet}
                        rowData={rowData}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Çalışan Sayısı Aralığı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(CALISAN_SAY_ARALIGI)}
                        rowData={rowData}
                        component={AutocomplateInputForm}
                        name="calisanSayisiAraligi"
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <Grid
                      container
                      direction="row"
                      spacing={0}
                      style={{ Padding: 0 }}
                    >
                      <Grid item xs={8}>
                        <CustomFieldWrapper
                          label="Tahmini Ciro"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="tahminiCiro"
                            component={NumberInputForm}
                            readOnly={!yetki.kaydet}
                            rowData={rowData}
                          />
                        </CustomFieldWrapper>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFieldWrapper
                          label="Birim"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="tahminiCiroBirimi"
                            items={Object.values(PARA_BIRIMI)}
                            rowData={rowData}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                      </Grid>
                    </Grid>
                    <CustomFieldWrapper
                      label="Aktif"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aktif"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet && !gorusmeRef}
                      />
                    </CustomFieldWrapper>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                    yetkisizseAc={typeof gorusmeRef !== "undefined"}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default PotansiyelMusteriRecord;
