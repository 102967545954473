import { toast } from "react-toastify";

const printToast = (resWrapper) => {
  if (
    (!resWrapper.success && resWrapper.message) ||
    (!resWrapper.Success && resWrapper.Message)
  ) {
    return toast.error(resWrapper.message ?? resWrapper.Message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else if (resWrapper.success && resWrapper.message) {
    return toast.success(resWrapper.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};

export { printToast };
