import React from "react";

const formStyle = {
  overflow: "hidden",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100vh",
};

const FormComponent = ({ onSubmit, children, style }) => {
  return (
    <form
      onSubmit={onSubmit}
      style={{ ...formStyle, ...style }}
      autoComplete="off"
    >
      {children}
    </form>
  );
};

export default FormComponent;
