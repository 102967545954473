import React from "react";
import { Upload } from "@mui/icons-material";
import CustomColoredButton from "./CustomColoredButton";

export default function DosyaYukleButton({ label, onClick, icon }) {
  return (
    <CustomColoredButton
      color="purple"
      sx={{ mr: 1 }}
      startIcon={icon ?? <Upload />}
      onClick={onClick}
    >
      {label ?? "Dosya Yükle"}
    </CustomColoredButton>
  );
}
