import Type from "../../entities/TeklifDetay";
import TypeDipToplam from "../../entities/TeklifDetayDipToplam";

const PageConfig = {
  title: "TeklifDetay",
  ekranKodu: "TeklifDetay_Res",
  type: Type,
  listRoute: "TeklifDetay/List",
  getByIdRoute: "TeklifDetay/GetById",
  insertRoute: "TeklifDetay/Insert",
  updateRoute: "TeklifDetay/Update",
  deleteRoute: "TeklifDetay/Delete",
  typeDipToplam: TypeDipToplam,
};

export { PageConfig };
