import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
// import SirketRecord from "./SirketRecord";
import { PageConfig } from "./BayilerConfig";
import BayilerRecord from "./BayilerRecord";

function Bayiler({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Bayiler"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
      />
      <BayilerRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></BayilerRecord>
    </>
  );
}

export default Bayiler;
