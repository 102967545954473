import { Alert, DialogContent, Grid } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import PersonelConfig from "../Personel/PersonelConfig";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import * as yup from "yup";
import validateFormValues from "../../common/constant-functions/validateFormValues";
import useDialog from "../../hooks/use-dialog";

const EMailDialog = React.forwardRef(({ yetki, rowData }, ref) => {
  const { open, closeRecord, id } = useDialog(ref);

  const handleSubmit = useSubmit({
    updateRoute: PersonelConfig.EMailUpdateRoute,
  });
  const validationSchema = yup.object().shape({
    talepEdenPersonelEMail: yup.string().email().required(),
  });

  const validate = validateFormValues(validationSchema);
  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Talep Eden Personel"
      maxWidth="xs"
      fullWidth={true}
    >
      <Form
        onSubmit={(values) =>
          handleSubmit({
            id: id,
            talepEdenPersonelEMail: values.talepEdenPersonelEMail,
          })
        }
        initialValues={rowData}
        validate={validate}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <form onSubmit={handleSubmit}>
            <Alert severity="warning">
              Talep eden personele ait mail bilgisi bulunmamaktadır. Lütfen
              ekleyiniz.
            </Alert>
            <DialogContent sx={{ padding: 2 }} id="dialog-content">
              <Grid container direction={"column"}>
                <FieldWrapper label="Email" textWidth="15%" width="85%">
                  <Field
                    type="email"
                    name="talepEdenPersonelEMail"
                    component={TextInputForm}
                    readOnly={!yetki.kaydet}
                  />
                </FieldWrapper>
              </Grid>
            </DialogContent>
            <DialogActionsWrapper>
              <SaveButton
                onClick={(e) => {
                  form.submit();
                  closeRecord();
                }}
                disabled={!yetki.kaydet}
              />
              <CancelButton
                onClick={() => {
                  form.reset();
                }}
                disabled={submitting || pristine}
              />
            </DialogActionsWrapper>
          </form>
        )}
      />
    </ScrollDialog>
  );
});

export default EMailDialog;
