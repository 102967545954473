import React from "react";
import ScrollDialog from "../dialogs/ScrollDialog";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import FileViewer from "./FileViewer";

const FileViewDialog = React.forwardRef(
  ({ ekranKodu, routes, reqData, parentRowData }, ref) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, routes.filesInfoRoute);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Dosyalar"
          maxWidth="md"
          canFullScreen={false}
        >
          <FileViewer
            ekranKodu={ekranKodu}
            routes={routes}
            reqData={reqData}
            parentRowData={parentRowData}
            rowData={rowData}
            setRowData={setRowData}
            modalLoading={modalLoading}
            // ref={ref}
          ></FileViewer>
        </ScrollDialog>
      </>
    );
  }
);
export default FileViewDialog;
