import React, { useRef } from "react";
import MainTab from "../../components/tab/MainTab";
import { ctheme } from "../../theme/theme";
import { Field } from "react-final-form";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import HizmetForm from "./HizmetForm";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./SirketToplantiConfig";

export default function SirketTabs({
  rowData,
  ekranKodu,
  yetki,
  modalLoading,
  tabRef,
}) {
  const tabLabels = ["Bağlantı Bilgileri", "Toplantılar", "Hizmet Formları"];
  const listGridRef = useRef();

  const BaglantiBilgileriTab = (
    <Field
      name="baglantiBilgileri"
      component={RichTextEditor}
      readOnly={!yetki.kaydet}
      modalLoading={modalLoading}
      //label={"Bağlantı bilgileri"}
    />
  );

  const ToplantiTab = (
    <PageGrid
      gridId="SirketPageToplanti"
      PageConfig={PageConfig}
      listGridRef={listGridRef}
      isCreatable={false}
      ekranKodu={ekranKodu}
      height="43vh"
      reqData={{ sirketId: rowData.id }}
      columnBtnVisible={false}
      exportButtonVisible={false}
    />
  );

  return (
    <MainTab
      ref={tabRef}
      labels={tabLabels}
      appBarColor={ctheme.colors.gridHeaderColor}
      sx={{ flexBasis: "100vh", flexGrow: 1 }}
    >
      {BaglantiBilgileriTab}
      {rowData.id > 0 && ToplantiTab}
      {rowData.id > 0 && (
        <HizmetForm ekranKodu={ekranKodu} parentRowData={rowData}></HizmetForm>
      )}
    </MainTab>
  );
}
