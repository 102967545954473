import Type from "../../entities/SirketToplanti";

const PageConfig = {
  title: "Toplantılar",
  ekranKodu: "Toplanti_Res",
  type: Type,
  listRoute: "Toplanti/List",
};

export default PageConfig;
