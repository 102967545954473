import {
  DATE_FORMAT,
  GORUSME_DURUMU,
  GORUSME_TIPI,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { DATE_WIDTH, ID_WIDTH } from "../common/entityconstants";
import dayjs from "dayjs";
import PotansiyelMusteriInputForm from "../components/inputs/custom-form-inputs/PotansiyelMusteriInputForm";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";

const entity = [
  {
    name: "id",
    label: "Görüşme No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "potFirmaidStr",
    label: "Firma Adı",
    dataType: "text",
    dataField: "potFirmaidStr",
    visible: ["grid"],
  },
  {
    name: "durum",
    label: "Görüşme Durumu",
    dataType: "select",
    dataField: "durum",
    visible: ["grid"],
    selectItems: Object.values(GORUSME_DURUMU),
  },
  {
    name: "tipi",
    label: "Görüşme Tipi",
    dataType: "select",
    dataField: "tipi",
    visible: ["grid"],
    selectItems: Object.values(GORUSME_TIPI),
  },
  {
    name: "teklifid",
    label: "Teklif No",
    dataType: "text",
    dataField: "teklifid",
    visible: ["grid"],
  },
  {
    name: "kayitEdenStr",
    label: "Kayıt Eden",
    dataType: "text",
    dataField: "kayitEdenStr",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "bayiidStr",
    label: "Bayi Kod",
    dataType: "text",
    dataField: "bayiidStr",
    visible: ["grid"],
  },
  {
    name: "musteriTemsilcisiStr",
    label: "MüşteriTemsilcisi",
    dataType: "text",
    dataField: "musteriTemsilcisiStr",
    visible: ["grid"],
  },
  {
    name: "referansVerenStr",
    label: "Referans Veren",
    dataType: "text",
    dataField: "referansVerenStr",
    visible: ["grid"],
  },

  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "id",
    label: "Görüşme No",
    dataType: "number",
    dataField: "id",
    visible: ["filter"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "potFirmaid",
    label: "Firma",
    dataType: "custom",
    dataField: "potFirmaid",
    visible: ["filter"],
    customComponent: PotansiyelMusteriInputForm,
  },
  {
    name: "tarih1",
    label: "Görüşme Tarihi-1",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
    defaultValue: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    name: "tarih2",
    label: "Görüşme Tarihi-2",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
    defaultValue: dayjs().add(1, "month").format("YYYY-MM-DD"),
  },
  {
    name: "durum",
    label: "Görüşme Durumu",
    dataType: "select",
    dataField: "durum",
    visible: ["filter"],
    items: Object.values(GORUSME_DURUMU),
  },
  {
    name: "teklifid",
    label: "Teklif No",
    dataType: "number",
    dataField: "teklifid",
    visible: ["filter"],
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
