import { Grid } from "@mui/material";
import React from "react";

export default function DialogContentTop({ children }) {
  return (
    <Grid container direction="row" columnSpacing={2} sx={{ mb: 1 }}>
      {children}
    </Grid>
  );
}
