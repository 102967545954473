import Type from "../../entities/YazilimsalDestek";

const PageConfig = {
  title: "Yazılımsal Destek Geçmişi",
  ekranKodu: "Destek_Gecmisi_Res",
  type: Type,
  listRoute: "Destek/YazilimsalDestekList",
};

export default PageConfig;
