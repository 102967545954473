import { EKRAN_KODU } from "../../common/constants";
import Type from "../../entities/Sirket";

const PageConfig = {
  title: "Şirketler",
  ekranKodu: EKRAN_KODU.SIRKET.value,
  type: Type,
  listRoute: "Sirket/List",
  insertRoute: "Sirket/Insert",
  getByIdRoute: "Sirket/GetById",
  updateRoute: "Sirket/Update",
  deleteRoute: "Sirket/Delete",
};

const BaglantiBilgileriFileConfig = {
  ekranKodu: EKRAN_KODU.SIRKET.value,
  allFilesDownloadRoute: "Sirket/DosyalariIndir",
  fileDownloadRoute: "Sirket/DosyaIndir",
  filesInfoRoute: "Sirket/DosyaBilgileri",
  fileUploadRoute: "Sirket/DosyaYukle",
  deleteFilesRoute: "Sirket/DeleteFiles",
};

const HizmetFormFileConfig = {
  ekranKodu: EKRAN_KODU.SIRKET.value,
  allFilesDownloadRoute: "HizmetForm/DosyalariIndir",
  fileDownloadRoute: "HizmetForm/DosyaIndir",
  filesInfoRoute: "HizmetForm/DosyaBilgileri",
  fileUploadRoute: "HizmetForm/DosyaYukle",
  deleteFilesRoute: "HizmetForm/DeleteFiles",
};

const PaketBilgileriConfig = {
  ekranKodu: EKRAN_KODU.SIRKET.value,
  getByIdRoute: "PaketBilgileri/GetById",
  updateRoute: "PaketBilgileri/Update",
};

const AnlasmaBilgileriConfig = {
  ekranKodu: EKRAN_KODU.ANLASMA_BILGILERI.value,
  getByIdRoute: "Sirket/AnlasmaBilgileriGetById",
  updateRoute: "Sirket/AnlasmaBilgileriUpdate",
  contractUploadRoute: "Sirket/ContractUpload",
  contractGetByIdRoute: "Sirket/ContractGetById",
};

const OtherConfig = {
  lisansKoduVerConfig: "Sirket/LisansKoduVer",
};

export {
  PageConfig,
  BaglantiBilgileriFileConfig,
  HizmetFormFileConfig,
  PaketBilgileriConfig,
  AnlasmaBilgileriConfig,
  OtherConfig,
};
