import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Yetki No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "asc",
    width: ID_WIDTH,
  },
  {
    name: "yetkiGrubuAdi",
    label: "Yetki Grubu Adı",
    dataType: "text",
    dataField: "yetkiGrubuAdi",
    visible: ["grid"],
  },
];

export default entity;
