import Type from "../../entities/UrunGrubu";

const PageConfig = {
  title: "UrunGrubu",
  ekranKodu: "UrunGrubu_Res",
  type: Type,
  listRoute: "UrunGrubu/List",
  getByIdRoute: "UrunGrubu/GetById",
  insertRoute: "UrunGrubu/Insert",
  updateRoute: "UrunGrubu/Update",
  deleteRoute: "UrunGrubu/Delete",
};

export {
  PageConfig
};