import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./KullaniciConfig";
import KullaniciRecord from "./KullaniciRecord";

const Kullanici = ({ ekranKodu }) => {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Kullanici"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
      />
      <KullaniciRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></KullaniciRecord>
    </>
  );
};

export default Kullanici;
