import React, { useEffect, useState } from "react";
import PageConfig from "../../Kullanici/KullaniciConfig";
import * as yup from "yup";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import FieldWrapper from "../../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../../components/inputs/form-inputs/TextInputForm";
import { Save } from "@mui/icons-material";
import AutocomplateInputForm from "../../../components/inputs/form-inputs/AutocomplateInputForm";
import YetkiGrubuInputForm from "../../../components/inputs/custom-form-inputs/YetkiGrubuInputForm";
import validateFormValues from "../../../common/constant-functions/validateFormValues";
import { AppContext } from "../../../common/AppContext";
import useFetch from "../../../hooks/use-fetch";
import useSubmit from "../../../hooks/use-submit";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width="75%"
      textWidth="25%"
    >
      {children}
    </FieldWrapper>
  );
};

function KullaniciBilgisiGuncelleme({ ekranKodu }) {
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  const fetchData = useFetch(
    PageConfig.getByIdRoute,
    setRowData,
    setModalLoading
  );

  const handleSubmit = useSubmit(PageConfig, setRowData);

  useEffect(() => {
    fetchData(context.user.id);
  }, []);

  const validationSchema = yup.object().shape({
    sirketId: yup.number().required(),
    kullaniciAdi: yup.string().required(),
    // sifre: yup.string().required(),
    isim: yup.string().required(),
    soyisim: yup.string().required(),
    yetkiGrubuId: yup.number().required(),
    eMail: yup.string().email().nullable(),
  });
  const validate = validateFormValues(validationSchema);

  const context = React.useContext(AppContext);

  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  return (
    <Paper
      sx={{
        m: 1,
        minWidth: "500px",
      }}
    >
      <Typography align="center">KULLANICI BİLGİLERİM</Typography>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={rowData}
        validate={validate}
        autoComplete={false}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid sx={{ p: 2 }}>
              <CustomFieldWrapper
                label="Kullanıcı No"
                modalLoading={modalLoading}
              >
                <Field name="id" component={TextInputForm} readOnly={true} />
              </CustomFieldWrapper>

              <CustomFieldWrapper
                label="Şirket Kodu"
                modalLoading={modalLoading}
              >
                <Field
                  name="sirketId"
                  rowData={rowData}
                  remoteDataAdaptor={{
                    fetchRoute: "Sirket/ListForSelect",
                    fildsForText: ["sirket_Kod"],
                    fieldForValue: "id",
                    reqData: {},
                    fieldLabel: "sirket_Kod",
                  }}
                  component={AutocomplateInputForm}
                  readOnly={true}
                />
              </CustomFieldWrapper>

              <CustomFieldWrapper
                label="Kullanıcı Adı"
                modalLoading={modalLoading}
              >
                <Field
                  name="kullaniciAdi"
                  component={TextInputForm}
                  readOnly={true}
                />
              </CustomFieldWrapper>

              {/* <CustomFieldWrapper label="Şifre" modalLoading={modalLoading}>
                  <Field
                    name="sifre"
                    component={PasswordInputForm}
                    readOnly={!yetki.kaydet}
                  />
                </CustomFieldWrapper> */}

              <CustomFieldWrapper label="Email" modalLoading={modalLoading}>
                <Field
                  type="email"
                  name="eMail"
                  component={TextInputForm}
                  readOnly={!yetki.kaydet}
                />
              </CustomFieldWrapper>

              <CustomFieldWrapper
                label="Telefon No"
                modalLoading={modalLoading}
              >
                <Field
                  type="tel"
                  name="telefonNo"
                  component={TextInputForm}
                  readOnly={!yetki.kaydet}
                />
              </CustomFieldWrapper>

              <CustomFieldWrapper label="İsim" modalLoading={modalLoading}>
                <Field
                  name="isim"
                  component={TextInputForm}
                  readOnly={!yetki.kaydet}
                />
              </CustomFieldWrapper>

              <CustomFieldWrapper label="Soyisim" modalLoading={modalLoading}>
                <Field
                  name="soyisim"
                  component={TextInputForm}
                  readOnly={!yetki.kaydet}
                />
              </CustomFieldWrapper>

              <CustomFieldWrapper
                label="Yetki Grubu"
                modalLoading={modalLoading}
              >
                <Field
                  name="yetkiGrubuId"
                  rowData={rowData}
                  component={YetkiGrubuInputForm}
                  ekranKodu={ekranKodu}
                  readOnly={true}
                />
              </CustomFieldWrapper>
              <Button
                sx={{ width: "100%", mt: 3, p: 1 }}
                variant="contained"
                startIcon={<Save />}
                size="small"
                onClick={(e) => {
                  form.submit();
                }}
              >
                Güncelle
              </Button>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
}
export default KullaniciBilgisiGuncelleme;
