import { DATE_FORMAT } from "../common/constants";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import {
  DATE_WIDTH,
  ID_WIDTH,
  LONG_DATE_WIDTH,
} from "../common/entityconstants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: [],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "tamamlandi",
    label: "Tamamlandı",
    dataType: "boolean",
    dataField: "tamamlandi",
    visible: ["grid"],
    width: "100px",
    allowEditing: true,
  },
  {
    name: "aciklama",
    label: "Aciklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: "650px",
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    firstSort: "desc",
    width: DATE_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "ilgiliKisiler",
    label: "İlgili Kişiler",
    dataType: "text",
    dataField: "ilgiliKisiler",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
  },
  //// Filtre //// Filtre//// Filtre//// Filtre//// Filtre//// Filtre//// Filtre//// Filtre//// Filtre
  {
    name: "sirketId",
    label: "Şirket Kod",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
];
export default entity;

// public List<NotIlgiliKisiDto?>? IlgiliKisiler { get; set; }
