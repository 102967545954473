import React from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";

const TalepDetayiDialog = React.forwardRef(({ ekranKodu }, ref) => {
  const { open, closeRecord, modalLoading, rowData } = useFetchDialog(
    ref,
    "Talep/TalepDetayiGetById"
  );

  const handleSubmit = () => {};

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Talep Detayı"
      maxWidth="md"
      fullWidth={true}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={rowData}
        //validate={validate}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <FormComponent onSubmit={handleSubmit}>
            <DialogContentWrapper>
              <Field
                name="talepDetayi"
                component={RichTextEditor}
                readOnly={true}
                modalLoading={modalLoading}
              />
            </DialogContentWrapper>
          </FormComponent>
        )}
      />
    </ScrollDialog>
  );
});

export default TalepDetayiDialog;
