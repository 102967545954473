import React, { useCallback, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  Button,
  Scrolling,
} from "devextreme-react/data-grid";
import SmallButton from "../buttons/SmallButton";
import { Add } from "@mui/icons-material";
import DipToplamGrid from "./DipToplamGrid";

const EditGrid = ({
  entities,
  openRecord,
  height,
  editGridRef,
  dataSource,
  ekleButtonGizli,
  dipToplamDataSource,
  dipToplamEntities,
}) => {
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: "black",
        backgroundColor: color,
        height: 3,
      }}
    />
  );

  return (
    <div id="data-grid-demo">
      <DataGrid
        dataSource={dataSource}
        keyExpr={entities.find((x) => x.isPrimaryKey).name}
        showBorders={true}
        height={height}
        ref={editGridRef}
        columnResizingMode={"widget"}
        toolbar={{
          items: [
            // { widget: 'dxButton', location: 'after', options: { icon: 'refresh' } },
          ],
        }}
        allowColumnResizing={true}
        // showRowLines={true}
        columnAutoWidth={true}
      >
        <Scrolling columnRenderingMode="virtual" />
        <Paging enabled={true} />
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          newRowPosition="last"

          //   selectTextOnEditStart={selectTextOnEditStart}
          //   startEditAction={startEditAction}
        />

        {entities
          .filter((type) => {
            let gridVisible = type.visible?.includes("grid");
            if (gridVisible) return true;
            else return false;
          })
          .map((i, index) => {
            return (
              <Column
                key={i.name}
                dataType={i.dataType}
                caption={i.label}
                dataField={i.dataField}
                name={i.name}
                visibleIndex={index}
                allowEditing={false}
                alignment={
                  i.alignment
                    ? i.alignment
                    : i.dataType === "number"
                    ? "right"
                    : i.dataType === "boolean"
                    ? "center"
                    : "left"
                }
                defaultSortOrder={i.firstSort ? i.firstSort : null}
                width={i.width ? i.width : null}
                minWidth={i.minWidth ? i.minWidth : 100}
                format={i.format ? i.format : null}
                visible={i.pageGridHidden ? false : true}
                {...(i.calculateSortValue && {
                  calculateSortValue: i.calculateSortValue,
                })}
                {...(i.defaultSortIndex && {
                  defaultSortIndex: i.defaultSortIndex,
                })}
                {...(i.sortingMethod && {
                  sortingMethod: i.sortingMethod,
                })}
                {...(i.cellRender && { cellRender: i.cellRender })}
                {...(i.customizeText && { customizeText: i.customizeText })} //todo excel e aktarırken muinin bileşenleri aktarılmadığı için cell render kullanılan fieldlara bu alanın da eklenmesi gerekir
                allowExporting={i.allowExporting ?? true}
                allowSorting={i.allowSorting ?? true}
              >
                {i.dataType === "select" ? (
                  <Lookup
                    key={i.dataField + "_lookup"}
                    dataSource={i.selectItems}
                    displayExpr="Text"
                    valueExpr="Value"
                  ></Lookup>
                ) : null}
              </Column>
            );
          })}

        <Column
          type="buttons"
          visibleIndex={0}
          fixed={true}
          fixedPosition={0}
          alignment={"center"}
          visible={true}
          // headerCellRender={(e) => {
          //   return externalColumnButton(e);
          // }}
        >
          <Button
            hint={"Düzenle"}
            icon={"edit"}
            onClick={(e) => {
              openRecord(e.row.key);
            }}
            visible={true}
          ></Button>
        </Column>
      </DataGrid>
      {dipToplamDataSource && (
        <>
          <ColoredLine />
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {ekleButtonGizli ? null : (
              <SmallButton
                style={{ height: "30px" }}
                startIcon={<Add />}
                onClick={() => {
                  openRecord(null);
                }}
              >
                Ekle
              </SmallButton>
            )}
            <DipToplamGrid
              style={{ flex: "5", alignItems: "flex-start" }}
              entities={dipToplamEntities}
              dataSource={dipToplamDataSource}
              height={"8vh"}
            ></DipToplamGrid>
          </div>
        </>
      )}
      {dipToplamDataSource || ekleButtonGizli ? null : (
        <SmallButton
          startIcon={<Add />}
          onClick={() => {
            openRecord(null);
          }}
        >
          Ekle
        </SmallButton>
      )}
    </div>
  );
};
export default EditGrid;
