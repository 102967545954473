import { YETKI_GRUBU_TIPI } from "../constants";

const sozlesmeTarihiBitmisMi = (context) => {
  return context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value
    ? context?.user?.destekSozBitis == null ||
        new Date(context?.user?.destekSozBitis).setHours(0, 0, 0, 0) <
          new Date().setHours(0, 0, 0, 0)
    : false;
};

export default sozlesmeTarihiBitmisMi;
