import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Close } from "@mui/icons-material";

export default function CancelButton({onClick,disabled}) {
  return (
    <ButtonWrapper
      color="warning"
      startIcon={<Close />}
      onClick={onClick}
      disabled={disabled}
    >
      Vazgeç
    </ButtonWrapper>
  );
}
