import Type from "../../entities/GenelDestek";

const PageConfig = {
  title: "Genel Destek Geçmişleri",
  ekranKodu: "Destek_Gecmisi_Res",
  type: Type,
  listRoute: "Destek/GenelDestekList",
};

export default PageConfig;
