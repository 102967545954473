import { Popover } from "@mui/material";
import React, { useRef, useState } from "react";

export default function PopoverViaButton({ PButton, children }) {
  const [open, setOpen] = useState(false);
  const ref1 = useRef();
  return (
    <>
      <div
        ref={ref1}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <PButton />
      </div>
      <Popover
        anchorEl={open ? ref1?.current : null}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => setOpen(false)}
      >
        {children}
      </Popover>
    </>
  );
}
