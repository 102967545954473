import React, { useRef, useEffect } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import SirketRecord from "./SirketRecord";
import { PageConfig } from "./SirketConfig";

function Sirket({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Sirket"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
      />
      <SirketRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></SirketRecord>
    </>
  );
}

export default Sirket;
