import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

function PasswordInputForm({ input, meta, label, defaultValue, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <TextField
      {...input}
      sx={{ width: "100%" }}
      label={label}
      type={showPassword ? "text" : "password"}
      variant="standard"
      defaultValue={defaultValue}
      onChange={(event, value) => input.onChange(event.target.value)}
      autoComplete="off"
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility}>
              {showPassword ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
        readOnly: rest.readOnly,
      }}
      {...rest}
    />
  );
}

export default PasswordInputForm;
