import { AY, DATE_FORMAT, DESTEK_ISLEM_TURU } from "../common/constants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";
import dayjs from "dayjs";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  ACIKLAMA_WIDTH,
  DATE_WIDTH,
  ID_WIDTH,
} from "../common/entityconstants";
import KullaniciInputForm from "../components/inputs/custom-form-inputs/KullaniciInputForm";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "tarih",
    label: "Destek Tarihi",
    dataType: "date",
    dataField: "tarih",
    visible: ["grid"],
    format: DATE_FORMAT,
    firstSort: "desc",
    width: DATE_WIDTH,
  },
  {
    name: "aciklama",
    label: "Detay",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: ACIKLAMA_WIDTH,
  },
  {
    name: "destekSuresi",
    label: "Süre (Dakika) ",
    dataType: "text",
    dataField: "destekSuresi",
    visible: ["grid"],
  },
  {
    name: "destekVerenIdStr",
    label: "Hizmet Sağlayan",
    dataType: "text",
    dataField: "destekVerenIdStr",
    visible: ["grid"],
  },
  {
    name: "destekAlanIdStr",
    label: "Hizmet Alan",
    dataType: "text",
    dataField: "destekAlanIdStr",
    visible: ["grid"],
  },
  {
    name: "islemTuru",
    label: "İşlem Türü",
    dataType: "select",
    dataField: "islemTuru",
    visible: ["grid"],
    selectItems: Object.values(DESTEK_ISLEM_TURU),
  },
  // {
  //   name: "sirket_Kod",
  //   label: "Şirket Kodu",
  //   dataType: "text",
  //   dataField: "sirket_Kod",
  //   visible: ["grid"],
  // },
  //todo------------------------filtre-----------------------------------
  {
    name: "sirketId",
    label: "Şirket Kod",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
  {
    name: "ay",
    label: "Ay",
    dataType: "select",
    dataField: "ay",
    visible: ["filter"],
    items: AY,
    defaultValue: dayjs().month() + 1, // dayjs().month() + 1 bulunduğumuz ayın ay değerini int olarak alır 3 gibi
  },
  {
    name: "destekVerenId",
    label: "Hizmet Sağlayan",
    dataType: "custom",
    dataField: "destekVerenId",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
  },
  // {
  //   name: "destekVerenIdStr",
  //   label: "Hizmet Sağlayan",
  //   dataType: "text",
  //   dataField: "destekVerenIdStr",
  //   visible: ["filter"],
  // },
  {
    name: "islemTuru",
    label: "İşlem Türü",
    dataType: "select",
    dataField: "islemTuru",
    visible: ["filter"],
    items: Object.values(DESTEK_ISLEM_TURU),
  },
  {
    name: "sorumlular",
    label: "Sorumlular",
    dataType: "text",
    dataField: "sorumlular",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
    allowSorting: false,
  },
];

export default entity;
