import { Box } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { HttpAuthorized } from "../../common/common";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";
import { printToast } from "../../common/constant-functions/printToast";

function b64toBlob(b64Data, contentType) {
  if (typeof b64Data != "string") {
    return new Blob([], { type: contentType });
  }
  var byteCharacters = atob(b64Data);

  var byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    var slice = byteCharacters.slice(offset, offset + 512),
      byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });

  return blob;
}

const fetchData = async (url, requestData, setPdfUrl, setError) => {
  loadingVisible(true);

  await HttpAuthorized.post(url, requestData)
    .then((res) => {
      printToast(res.data);
      setPdfUrl(
        URL.createObjectURL(b64toBlob(res.data.data, "application/pdf")) +
          "#toolbar=1&navpanes=0"
      );
    })
    .catch(function (error) {
      printToast(error.response.data);
      setError(error.response.data.Message);
    })
    .finally(() => loadingVisible(false));
};

const reportHeight = "calc(100vh - 250px)";

function PageReport({ base64, url, requestData }) {
  const [pdfUrl, setPdfUrl] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (url) {
      fetchData(url, requestData, setPdfUrl, setError);
    }
    if (base64) {
      loadingVisible(true);

      setPdfUrl(
        URL.createObjectURL(b64toBlob(base64, "application/pdf")) +
          "#toolbar=1&navpanes=0"
      );
      loadingVisible(false);
    }
  }, []);

  return error ? (
    <Box
      style={{
        height: reportHeight || "auto",
        padding: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <strong>Pdf Gösterilemedi</strong>
      </div>
      <Box style={{ margin: 5 }}></Box>
      <div>{error}</div>
    </Box>
  ) : (
    <iframe
      title="pdf"
      src={pdfUrl}
      style={{
        display: "block",
        width: "100%",
        border: "none",
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
      }}
    />
  );
}

export default PageReport;
