import React from "react";
import useDialog from "../../hooks/use-dialog";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialog = React.forwardRef(
  (
    {
      dialogTitle,
      contentText,
      onayBtnText,
      redBtnText,
      onayBtnClick,
      dialogActionsVisible = true,
    },
    ref
  ) => {
    const { open, closeRecord, id } = useDialog(ref);

    return (
      <Dialog
        open={open}
        onClose={closeRecord}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle ?? "Kaydedilmemiş değişiklikleriniz var!"}
        </DialogTitle>
        <DialogContent id="dialog-content">
          <DialogContentText id="alert-dialog-description">
            {contentText ?? "Kaydetmek ister misiniz?"}
          </DialogContentText>
        </DialogContent>
        {dialogActionsVisible && (
          <DialogActions>
            <Button
              onClick={() => {
                onayBtnClick(id);
                closeRecord();
              }}
            >
              {onayBtnText ?? "Evet"}
            </Button>
            <Button onClick={closeRecord} autoFocus>
              {redBtnText ?? "Hayır"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);

export default AlertDialog;
