// import Type from "../../entities/Refe";

const PageConfig = {
  title: "ReferansVeren",
  ekranKodu: "ReferansVeren_Res",
  // type: Type,
  // listRoute: "ReferansVeren/List",
  getByIdRoute: "ReferansVeren/GetById",
  insertRoute: "ReferansVeren/Insert",
  updateRoute: "ReferansVeren/Update",
  deleteRoute: "ReferansVeren/Delete",
};

export { PageConfig };
