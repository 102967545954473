import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { routesArr } from "../../router/router";
import { Alert, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../common/AppContext";
import { ctheme } from "../../theme/theme";
import dayjs from "dayjs";
import MyAppBar from "./AppBar";
import { DRAWER_WIDTH } from "../../common/constants";
import DrawerList from "./DrawerList";
import sozlesmeTarihiBitmisMi from "../../common/constant-functions/sozlesmeTarihiBitmisMi";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  backgroundColor: ctheme.colors.gridHeaderColor,
}));

export default function PersistentDrawerLeft({ children }) {
  const [open, setOpen] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const context = React.useContext(AppContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { pathname } = useLocation();

  React.useEffect(() => {
    const currentRoute = routesArr.find(
      (x) =>
        x.path === pathname ||
        (x.children && x.children.find((c) => c.path === pathname))
    );
    if (currentRoute?.hasSidebar) {
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
    }
  }, [pathname]);

  return showDrawer ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MyAppBar handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
          ...(!open && { display: "none" }),
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img width="80%" src="/erplogorenkli.png" alt="Logo" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Grid
          sx={{
            p: 1,
          }}
        >
          <DrawerList />
        </Grid>
      </Drawer>
      <Grid
        sx={{
          width: open ? `calc(100% - ${DRAWER_WIDTH}px)` : "100%",
          padding: "5px",
        }}
        open={open}
      >
        <DrawerHeader />
        {children}
        {sozlesmeTarihiBitmisMi(context) && (
          <Alert
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 9999,
            }}
            variant="filled"
            severity="error"
          >
            {context?.user?.destekSozBitis == null
              ? "Geçerli bir bakım antlaşmanız bulunmamaktadır. Temsilciniz ile görüşün."
              : "Bakım anlaşmanız " +
                dayjs(context?.user?.destekSozBitis)?.format("DD.MM.YYYY") +
                " tarihinde sonlanmıştır. Yenilemek için temsilciniz ile görüşün."}
          </Alert>
        )}
      </Grid>
    </Box>
  ) : (
    children
  );
}
