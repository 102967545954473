import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import useBasicDialog from "../../hooks/use-basic-dialog";
import { AppContext } from "../../common/AppContext";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";

const TopluIskontoDialog = React.forwardRef(({ customHandleSubmit }, ref) => {
  const { open, closeRecord } = useBasicDialog(ref);

  const afterSubmitFnc = () => {
    closeRecord();
    // masterDialogClose();
  };

  const handleSubmit = (data) => {
    customHandleSubmit(data.iskontoOrani);
    afterSubmitFnc();
  };

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="İskonto Oranı Girişi"
      maxWidth="sm"
      canFullScreen={false}
      fullWidth={true}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        //validate={validate}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <FormComponent onSubmit={handleSubmit} style={{ height: "100px" }}>
            <DialogContentWrapper>
              <Field name="iskontoOrani" component={NumberInputForm} />
            </DialogContentWrapper>
            <DialogActionsWrapper>
              <SaveButton
                onClick={(e) => {
                  form.submit().then(() => {
                    closeRecord();
                  });
                }}
              />
              <CancelButton
                onClick={() => {
                  form.reset();
                }}
                disabled={submitting || pristine}
              />
            </DialogActionsWrapper>
          </FormComponent>
        )}
      />
    </ScrollDialog>
  );
});

export default TopluIskontoDialog;
