import React, { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PdfDialog from "../../components/pdf/PdfDialog";
import PageConfig from "./HomeConfig";
import HomeRecord from "./HomeRecord";
import GridTeklifPdfButton from "../../components/buttons/custom/GridTeklifPdfButton";
import { AppContext } from "../../common/AppContext";
import GridIsSonucuButton from "../../components/buttons/custom/GridIsSonucuButton";
import IsSonucuDialog from "./IsSonucuDialog";
import GridTeklifPdfButtonBayi from "../../components/buttons/custom/GridTeklifPdfButtonBayi";

export default function Home({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const pdfDialogRef = useRef();
  const pdfDialogRefBayi = useRef();
  const isSonucuDialogRef = useRef();

  const context = React.useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  const CustomColumnButton = [
    GridTeklifPdfButton(pdfDialogRef, yetki?.teklifGoruntule),
    GridTeklifPdfButtonBayi(pdfDialogRefBayi, yetki?.teklifGoruntule),
    GridIsSonucuButton(isSonucuDialogRef),
  ];

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="FirmaTalep"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
        CustomColumnButton={CustomColumnButton}
      />
      <HomeRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></HomeRecord>
      <PdfDialog
        ref={pdfDialogRef}
        title="Teklif"
        url="Talep/TeklifPdf"
      ></PdfDialog>
      <PdfDialog
        ref={pdfDialogRefBayi}
        title="Teklif"
        url="Talep/TeklifPdfBayi"
      ></PdfDialog>
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog>
    </>
  );
}
