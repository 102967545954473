import Type from "../../entities/KontrolEdilecekIsler";

const PageConfig = {
  title: "Kontrol Edilecek İşler",
  ekranKodu: "KontrolEdilecekIsler_Res",
  type: Type,
  listRoute: "KontrolEdilecekIsler/List",
  //   getByIdRoute: "Talep/GetById",
  //   insertRoute: "Talep/Insert",
  //   updateRoute: "Talep/Update",
};
export default PageConfig;
