import { Tooltip } from "@mui/material";
import { HttpAuthorized } from "../common/common";
import {
  getTalepDurumuArr,
  getTeklifDurumuArr,
} from "../common/constant-functions/getTalepDurumuArr";
import {
  DATE_FORMAT,
  ILGILI_MODUL,
  ISIN_DURUMU_FILTER,
  ONAY_RED_DURUMU,
  ONCELIK_DURUMU,
  TALEP_DURUMU,
  TALEP_DURUMU_FILTER,
  TALEP_TIPI,
  TUTAR_FORMAT,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  BASLIK_WIDTH,
  DATE_WIDTH,
  ID_WIDTH,
  KDV_WIDTH,
  LONG_DATE_WIDTH,
  OKUNMAYAN_YORUM_WIDTH,
  ONCELIK_DURUMU_WIDTH,
} from "../common/entityconstants";
import KullaniciInputForm from "../components/inputs/custom-form-inputs/KullaniciInputForm";
import dayjs from "dayjs";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "okunmayanYorumSayisi",
    label: "Okunmayan Yorum",
    dataType: "number",
    dataField: "okunmayanYorumSayisi",
    visible: ["grid"],
    // cellRender: (e) => <MailBadge badgeContent={e?.value} />,
    alignment: "center",
    allowExporting: false,
    width: OKUNMAYAN_YORUM_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Şirket Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "talepDurumu",
    label: "Talep Durumu",
    dataType: "select",
    dataField: "talepDurumu",
    visible: ["grid"],
    cellRender: (e) => {
      let options = getTalepDurumuArr();
      let option = options.find((x) => x.Value === e.value);
      return (
        <Tooltip
          onOpen={(event) => {
            HttpAuthorized.request({
              method: "POST",
              url: "Talep/AnalizSonucuGetById",
              data: { id: e.data.id },
            })
              .then((res) => {
                event.target.title = res?.data?.data?.analizSonucu
                  ? "Analiz Sonucu: " +
                    parseHtmlString(res?.data?.data?.analizSonucu)
                  : "Analiz sonucu bulunamadı...";
              })
              .catch((error) => {});
          }}
        >
          <div style={{ color: option.Color }}>{option?.Text}</div>
        </Tooltip>
      );
    },
  },
  {
    name: "teklifDurumu",
    label: "Teklif Durumu",
    dataType: "select",
    dataField: "teklifDurumu",
    visible: ["grid"],
    cellRender: (e) => {
      let options = getTeklifDurumuArr();
      let option = options.find((x) => x.Value === e.value);
      if (option) {
        return <div style={{ color: option.Color }}>{option?.Text}</div>;
      } else {
        return null;
      }
    },
  },
  {
    name: "talepBasligi",
    label: "Talep Başlığı",
    dataType: "text",
    dataField: "talepBasligi",
    visible: ["grid"],
    width: BASLIK_WIDTH,
    cellRender: (e) => {
      return (
        <Tooltip
          onOpen={(event) => {
            HttpAuthorized.request({
              method: "POST",
              url: "Talep/TalepDetayiGetById",
              data: { id: e.data.id },
            })
              .then((res) => {
                event.target.title = res?.data?.data?.talepDetayi
                  ? "Talep Detayı: " +
                    parseHtmlString(res?.data?.data?.talepDetayi)
                  : "Talep detayı bulunamadı...";
              })
              .catch((error) => {});
          }}
        >
          <div>{e.value}</div>
        </Tooltip>
      );
    },
  },
  {
    name: "talepTipi",
    label: "Talep Tipi",
    dataType: "select",
    dataField: "talepTipi",
    visible: ["grid"],
    selectItems: TALEP_TIPI,
  },
  {
    name: "kayitEdenKullaniciIdStr",
    label: "Kayıt Eden Kullanıcı",
    dataType: "text",
    dataField: "kayitEdenKullaniciIdStr",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
    firstSort: "desc",
  },
  {
    name: "oncelik",
    label: "Öncelik Durumu",
    dataType: "select",
    dataField: "oncelik",
    visible: ["grid"],
    selectItems: ONCELIK_DURUMU,
    width: ONCELIK_DURUMU_WIDTH,
  },
  {
    name: "temsilciAnaliziVarMi",
    label: "Temsilci Analizi",
    dataType: "boolean",
    dataField: "temsilciAnaliziVarMi",
    visible: ["grid"],
    width: "120px",
    allowEditing: true,
  },
  {
    name: "temsilciIdStr",
    label: "Temsilci",
    dataType: "text",
    dataField: "temsilciIdStr",
    visible: ["grid"],
  },
  {
    name: "talepEdenPersonelIdStr",
    label: "Talep Eden Personel",
    dataType: "text",
    dataField: "talepEdenPersonelIdStr",
    visible: ["grid"],
  },
  {
    name: "ilgiliModul",
    label: "İlgili Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["grid"],
    selectItems: Object.values(ILGILI_MODUL),
  },
  {
    name: "ozellik",
    label: "Özellik 1",
    dataType: "text",
    dataField: "ozellik",
    visible: ["grid"],
  },
  {
    name: "onayRed",
    label: "Onay/Red",
    dataType: "select",
    dataField: "onayRed",
    visible: ["grid"],
    selectItems: Object.values(ONAY_RED_DURUMU),
  },
  {
    name: "teklifTutari",
    label: "Teklif Tutarı",
    dataType: "number",
    dataField: "teklifTutari",
    visible: ["grid"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
    ],
    format: TUTAR_FORMAT,
  },
  {
    name: "teklifKdvOrani",
    label: "Teklif Kdv Oranı (%)",
    dataType: "number",
    dataField: "teklifKdvOrani",
    visible: ["grid"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
    ],
    format: TUTAR_FORMAT,
    width: KDV_WIDTH,
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "terminTarihi",
    label: "Termin Tarihi",
    dataType: "date",
    dataField: "terminTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "yonlendirmeTarihi",
    label: "Yönlendirme Tarihi",
    dataType: "date",
    dataField: "yonlendirmeTarihi",
    visible: ["grid"],
    width: "125px",
  },
  {
    name: "sorumlular",
    label: "Sorumlular",
    dataType: "text",
    dataField: "sorumlular",
    visible: ["grid"],
    // cellRender: (e) => SorumluChip(e?.value),
    customizeText: (cellData) => sorumluJoin(cellData?.value),
    allowSorting: false,
  },
  {
    name: "isinDurumuMaster",
    label: "İşin Durumu",
    dataType: "select",
    dataField: "isinDurumuMaster",
    visible: ["grid"],
    selectItems: Object.values(ISIN_DURUMU_FILTER),
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: "300px",
  },
  {
    name: "bayiAdi",
    label: "Bayi Adı",
    dataType: "text",
    dataField: "bayiAdi",
    visible: ["grid"],
  },

  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "tarih1",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
    defaultValue: dayjs().subtract(2, "month").format("YYYY-MM-DD"),
  },
  {
    name: "tarih2",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  {
    name: "talepDurumu",
    label: "Talep Durumu",
    dataType: "select",
    dataField: "talepDurumu",
    visible: ["filter"],
    items: TALEP_DURUMU_FILTER,
  },
  {
    name: "sirketId",
    label: "Şirket",
    dataType: "custom",
    dataField: "sirketId",
    visible: ["filter"],
    customComponent: SirketInputForm,
    customComponentParams: {},
  },
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["filter"],
  },
  {
    name: "ilgiliModul",
    label: "Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["filter"],
    items: Object.values(ILGILI_MODUL),
  },
  {
    name: "talepDurumDetay",
    label: "Talep Durum Detay",
    dataType: "select",
    dataField: "talepDurumDetay",
    visible: ["filter"],
    items: Object.values(TALEP_DURUMU),
  },
  {
    name: "temsilciId",
    label: "Temsilci",
    dataType: "custom",
    dataField: "temsilciId",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
  },
  {
    name: "temsilciAnaliziVarMi",
    label: "Temsilci Analizi",
    dataType: "select",
    dataField: "temsilciAnaliziVarMi",
    visible: ["filter"],
    items: [
      {
        Value: true,
        Text: "Var",
      },
      {
        Value: false,
        Text: "Yok",
      },
    ],
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },

  // {
  //   name: "talepBasligi",
  //   label: "Metinsel",
  //   dataType: "text",
  //   dataField: "talepBasligi",
  //   visible: ["filter"],
  // },
  // {
  //   name: "aa",
  //   label: "Seçimli",
  //   dataType: "select",
  //   dataField: "aa",
  //   visible: ["filter"],
  //   items: [
  //     {
  //       value: 1,
  //       label: "option1",
  //     },
  //     {
  //       value: 2,
  //       label: "option2",
  //     },
  //     {
  //       value: 3,
  //       label: "option3",
  //     },
  //   ],
  // },
  // {
  //   name: "tarih",
  //   label: "Tarihsel",
  //   dataType: "date",
  //   dataField: "tarih",
  //   visible: ["filter"],
  // },
  // {
  //   name: "number",
  //   label: "Sayısal",
  //   dataType: "number",
  //   dataField: "number",
  //   visible: ["filter"],
  // },
];

export default entity;
