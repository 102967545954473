import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { HttpAuthorized } from "../../../common/common";
import { apiUrl } from "../../../common/constants";
import { CircularProgress, IconButton } from "@mui/material";
import { Add, Clear, Edit } from "@mui/icons-material";
import { styled } from "@mui/system";

const StyledIconButton = styled(IconButton)`
  margin: 0;
  padding: 0;
  color: ${({ color }) => color};
`;
const AutocomplateInputForm = React.forwardRef(
  (
    {
      input,
      meta,
      items,
      label,
      remoteDataAdaptor,
      rowData,
      insertButtonRef,
      readOnly,
      clearable = true,
      onChangeExtra,
      setCustomChipLabels,
      onlyUpdateBtn = false,
      disabledOptionValues,
      onChangeFilter,
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);

    // console.log("auto complate - rowData", rowData);
    // console.log("auto complate - ref", ref);

    const changeValue = (newValue) => {
      if (newValue) {
        let option = options.find((x) => x.Value === newValue.Value);
        if (!option) {
          setOptions([...options, { ...newValue }]);
        } else if (option.Text !== newValue.Text) {
          let optionsTemp = [...options];

          setOptions([
            ...optionsTemp?.filter((x) => x.Value !== newValue.Value),
            { ...newValue },
          ]);
        }
        input.onChange(newValue.Value);
      } else {
        input.onChange(null);
      }
    };

    React.useImperativeHandle(ref, () => ({
      changeValue: changeValue,
      value: input.value,
      label: options.find((x) => x.Value === input.value)?.Text ?? "",
    }));

    const getFieldText = (data, getFromRowData) => {
      let label = "";
      if (!getFromRowData) {
        remoteDataAdaptor.fildsForText?.forEach((fName, index) => {
          label += data[fName];
          if (index !== remoteDataAdaptor?.fildsForText?.length - 1) {
            label += " - ";
          }
        });
        return {
          Text: label,
          Value: data[remoteDataAdaptor.fieldForValue],
        };
      } else {
        return {
          Text: remoteDataAdaptor.fieldLabel
            ? data[remoteDataAdaptor.fieldLabel]
            : input.name == "sirketId"
            ? data["sirket_Kod"]
            : data[input.name + "Str"],
          Value: data[input.name],
        };
      }
    };

    const setDefaultValue = () => {
      let arr = [];
      if (rowData && rowData[input.name] && remoteDataAdaptor) {
        arr.push(getFieldText(rowData, true));
      }
      return arr;
    };

    const [options, setOptions] = useState(
      remoteDataAdaptor ? setDefaultValue() : items
    );

    function fetchData() {
      setLoading(true);

      if (remoteDataAdaptor.fetchRoute) {
        HttpAuthorized.request({
          method: "POST",
          url: `${apiUrl}${remoteDataAdaptor.fetchRoute}`,
          data: remoteDataAdaptor.reqData,
        })
          .then((res) => {
            setOptions(
              res?.data?.data?.map((data) => getFieldText(data)) ?? []
            );
          })
          .catch((error) => {
            setOptions(
              options.filter(
                (x) =>
                  x.Value === rowData[input.name] || x.Value === input.value
              )
            );
            console.log(error);
          })
          .finally(() => setLoading(false));
      }
    }

    function getOptionText(option) {
      if (option?.Text) {
        return option.Text;
      }
      if (option === 0 || option === false || (option && option !== "")) {
        let opt = options?.find((item) => item.Value === option);
        return opt?.Text;
      }
      return option;
    }

    // console.log(textColorStyle);

    return (
      <div>
        <Autocomplete
          {...input}
          id="auto-complate-input"
          value={input.value === "" ? null : input.value}
          loading={loading}
          loadingText="Yükleniyor..."
          autoComplete={false}
          readOnly={readOnly}
          disableClearable={true}
          sx={{ width: "100%" }}
          noOptionsText="Seçenek bulunamadı"
          getOptionLabel={getOptionText}
          options={options}
          isOptionEqualToValue={(option, value) => {
            // if (option === "") return;
            return option.Value === value;
          }}
          onChange={(e, newValue, reason) => {
            // if (reason === "clear") {
            //   onChangeExtra && onChangeExtra(input.value, null);
            //   return input.onChange(newValue);
            // }

            onChangeExtra && onChangeExtra(input.value, newValue);
            input.onChange(newValue.Value);
            onChangeFilter && onChangeFilter();
          }}
          onOpen={() => remoteDataAdaptor && fetchData()}
          {...(disabledOptionValues && {
            getOptionDisabled: (option) =>
              disabledOptionValues.includes(option.Value),
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              readOnly={readOnly}
              sx={{ width: "100%" }}
              label={label}
              variant="standard"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {insertButtonRef && (
                      <>
                        {!onlyUpdateBtn && (
                          <StyledIconButton
                            color="success"
                            onClick={() => {
                              insertButtonRef?.current?.openRecord();
                            }}
                            disabled={readOnly}
                          >
                            <Add fontSize="small"></Add>
                          </StyledIconButton>
                        )}
                        {input.value && (
                          <StyledIconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              insertButtonRef?.current?.openRecord(input.value);
                            }}
                            disabled={readOnly}
                          >
                            <Edit fontSize="inherit"></Edit>
                          </StyledIconButton>
                        )}
                      </>
                    )}
                    {clearable && (input.value === 0 || input.value) && (
                      <StyledIconButton
                        size="small"
                        color="error"
                        onClick={() => {
                          onChangeExtra && onChangeExtra(input.value, null);
                          input.onChange(null);
                          onChangeFilter && onChangeFilter();
                        }}
                        disabled={readOnly}
                      >
                        <Clear fontSize="inherit"></Clear>
                      </StyledIconButton>
                    )}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          // disableEndAdornment={true}
          {...rest}
        />
      </div>
    );
  }
);

export default AutocomplateInputForm;
