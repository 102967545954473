import React from "react";
import * as yup from "yup";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import FieldWrapper from "../../../components/inputs/form-inputs/FieldWrapper";
import { Field, Form } from "react-final-form";
import PasswordInputForm from "../../../components/inputs/form-inputs/PasswordInputForm";
import { Key } from "@mui/icons-material";
import validateFormValues from "../../../common/constant-functions/validateFormValues";
import useSubmit from "../../../hooks/use-submit";

const PageConfig = {
  insertRoute: "Auth/UpdatePassword",
};

const SifreGuncelleme = () => {
  const handleSubmit = useSubmit(PageConfig);

  const validationSchema = yup.object().shape({
    eskiSifre: yup.string().required(),
    yeniSifre: yup.string().required(),
    yeniSifreTekrar: yup
      .string()
      .oneOf([yup.ref("yeniSifre")], "Parolalar uyuşmuyor.")
      .required(),
  });

  const passwordValidate = validateFormValues(validationSchema);

  return (
    <Paper
      sx={{
        m: 1,
        minWidth: "300px",
        maxWidth: "300px",
        //   height: "100%",
      }}
    >
      <Typography align="center">ŞİFRE GÜNCELLEME</Typography>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        validate={passwordValidate}
        autoComplete={false}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid sx={{ p: 2 }}>
              <FieldWrapper label="Eski Şifre" width="100%">
                <Field name="eskiSifre" component={PasswordInputForm} />
              </FieldWrapper>
              <FieldWrapper label="Yeni Şifre" width="100%">
                <Field name="yeniSifre" component={PasswordInputForm} />
              </FieldWrapper>
              <FieldWrapper label="Yeni Şifre Tekrar" width="100%">
                <Field name="yeniSifreTekrar" component={PasswordInputForm} />
              </FieldWrapper>
              <Button
                sx={{ width: "100%", mt: 3, p: 1 }}
                variant="contained"
                startIcon={<Key />}
                size="small"
                onClick={async (e) => {
                  await form.submit().then(() => form.restart());
                }}
              >
                Şifreyi Güncelle
              </Button>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

export default SifreGuncelleme;
