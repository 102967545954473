import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "listeKodu",
    label: "Liste Kodu",
    dataType: "text",
    dataField: "listeKodu",
    visible: ["grid"],
  },
  {
    name: "listeAdi",
    label: "Liste Adı",
    dataType: "text",
    dataField: "listeAdi",
    visible: ["grid"],
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
  },
  {
    name: "bitisTarihi",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "bitisTarihi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "listeKodu",
    label: "Liste Kodu",
    dataType: "text",
    dataField: "listeKodu",
    visible: ["filter"],
  },
  {
    name: "listeAdi",
    label: "Liste Adı",
    dataType: "text",
    dataField: "listeAdi",
    visible: ["filter"],
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["filter"],
  },
  {
    name: "bitisTarihi",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "bitisTarihi",
    visible: ["filter"],
  }
 
];

export default entity;
